import { FilterObjType, TaskSelectType } from "./useSelectFilter"
import { TabTitleType } from "./useTabTitle"
import { useDefinePage } from "./useDefinePage"
import { useUser } from "../User/useUser"

export const useTasksItems = (filter: FilterObjType, title: TabTitleType) => {
  const { selectValues, onFilterChange, selectedFilter } = filter
  const { name, lastName } = useUser()

  const { isAutoClosePage, isMigrationPage } = useDefinePage(title)

  const noUsersResponsible = ` ${lastName} ${name}`
  const getValue = (values: TaskSelectType[]) => {
    let value = { value: "", label: "" }
    values.forEach((item) => {
      if (item.value === selectedFilter) {
        value = { value: item.value.toString(), label: item.label }
      }
      return false
    })
    return value
  }

  return {
    noUsersResponsible,
    getValue,
    onFilterChange,
    selectValues,
    isAutoClosePage,
    isMigrationPage
  }
}
