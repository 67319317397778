import React, { FC, memo } from "react"
import { BreadCrumbsType } from "../../../_types"
import BreadCrumbsWithData from "./BreadCrumbsWithData"
import BreadCrumbsWithoutData from "./BreadCrumbsWithoutData"

type PropsType = {
  breadCrumbs?: BreadCrumbsType[]
  lastItem?: string
}
const BreadCrumbs: FC<PropsType> = memo(({ breadCrumbs, lastItem }) => (
  <div className="breadCrumbs">
    <ul className="breadCrumbs__list">
      {breadCrumbs && <BreadCrumbsWithData breadCrumbs={breadCrumbs} />}
      {!breadCrumbs && <BreadCrumbsWithoutData />}
      <li className="breadCrumbs__item">
        <span className="breadCrumbs__link breadCrumbs__link--last">{lastItem || ""}</span>
      </li>
    </ul>
  </div>
))
export default BreadCrumbs
