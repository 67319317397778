import React, { FC } from "react"
import { TabTitleType } from "../../../../hooks/Tasks/useTabTitle"

interface IContentTop {
  title: TabTitleType
}

export const ContentTop: FC<IContentTop> = ({ title, children }) => (
  <>
    <h2 className="title tasksContent__title">{title.label}</h2>
    {children}
  </>
)
