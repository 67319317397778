import { InferActionsType } from "../store"
import { wikiAPI } from "../../api/api"
import {
  BreadCrumbsType,
  CategoryType,
  SectionMenuType,
  SelectedArticleType,
  SelectedSectionType,
  TopMenuType
} from "../../_types"
import { toastPromise } from "../../helpers/toastPromise"
import { toastError } from "../../helpers/toastError"

const SET_CATEGORIES = "SET_CATEGORIES"
const SET_TOP_MENU_ITEMS = "SET_TOP_MENU_ITEMS"
const SET_SELECTED = "SET_SELECTED"
const SET_SELECTED_SECTION = "SET_SELECTED_SECTION"
const SET_SELECT = "SET_SELECT"
const SET_ARTICLE = "SET_ARTICLE"
const SET_BREAD_CRUMB = "SET_BREAD_CRUMB"

// TYPES==================
export type InitialSectionsStateType = {
  sections: CategoryType[][]
  topMenuItems: TopMenuType[]
  selectedItem: TopMenuType[]
  selectedSection: SelectedSectionType
  sectionMenu: SectionMenuType[]
  selectedArticle: SelectedArticleType
}
// ===========================================
export const initialState: InitialSectionsStateType = {
  sections: [],
  topMenuItems: [],
  selectedItem: [],
  selectedSection: {
    categoryName: "",
    selectedSectionItems: [],
    breadCrumbs: []
  },
  sectionMenu: [],
  selectedArticle: {
    favorite: false,
    id: 1,
    links: [],
    name: "",
    section_id: 0,
    parent_section_id: 0,
    navigation: [],
    props: {
      datePublic: "",
      userPublic: "",
      dateChange: "",
      userChange: ""
    },
    relatedItems: [],
    files: [],
    text: "",
    voiting: [],
    editLink: ""
  }
}
const wikiReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        sections: [...action.sections]
      }
    case SET_TOP_MENU_ITEMS:
      return {
        ...state,
        topMenuItems: [...action.items]
      }
    case SET_SELECTED:
      return {
        ...state,
        selectedItem: action.selectedItem
      }
    case SET_SELECT:
      return {
        ...state,
        sectionMenu: action.item
      }
    case SET_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: {
          selectedSectionItems: [...action.selectedSectionItems],
          categoryName: action.categoryName,
          breadCrumbs: [...action.breadCrumbs]
        }
      }
    case SET_ARTICLE:
      return {
        ...state,
        selectedArticle: { ...action.article }
      }
    case SET_BREAD_CRUMB:
      return {
        ...state,
        selectedSection: {
          ...state.selectedSection,
          breadCrumbs: action.breadCrumbs
        }
      }

    default:
      return state
  }
}
type ActionsType = InferActionsType<typeof actions>
export const actions = {
  setCategories: (sections: CategoryType[][]) => ({ type: SET_CATEGORIES, sections } as const),
  setTopMenuItems: (items: TopMenuType[]) => ({ type: SET_TOP_MENU_ITEMS, items } as const),
  setSelected: (selectedItem: TopMenuType[]) => ({ type: SET_SELECTED, selectedItem } as const),
  setArticle: (article: SelectedArticleType) => ({ type: SET_ARTICLE, article } as const),
  setSelect: (item: SectionMenuType[]) => ({ type: SET_SELECT, item } as const),
  setSelectedSection: (selectedSectionItems: any, categoryName: string, breadCrumbs: BreadCrumbsType[]) =>
    ({
      type: SET_SELECTED_SECTION,
      selectedSectionItems,
      categoryName,
      breadCrumbs
    } as const),
  setBreadCrumbs: (breadCrumbs: BreadCrumbsType[]) =>
    ({
      type: SET_BREAD_CRUMB,
      breadCrumbs
    } as const)
}

export const getSectionsThunk = () => async (dispatch: any) => {
  const { sections } = await wikiAPI.getSections()
  dispatch(actions.setCategories(sections))
}
export const getMenuItemsThunk = () => async (dispatch: any) => {
  const { items } = await wikiAPI.getTopMenu()
  await dispatch(actions.setTopMenuItems(items))
}
export const setSelectedThunk = (name: string) => async (dispatch: any, getState: any) => {
  try {
    const items = getState().wiki.topMenuItems
    const selectedItem = items.filter((item: TopMenuType) => item.name === name)
    await dispatch(actions.setSelected(selectedItem))
  } catch (error) {
    console.warn(error)
  }
}
export const getSelectedSectionThunk = (sectionId: string) => async (dispatch: any, getState: any) => {
  const { selectedSection } = await wikiAPI.getSelectedSection(sectionId)
  const { breadCrumbs } = await wikiAPI.getBreadCrumbs(sectionId)
  const { sectionMenu } = await wikiAPI.getSelectSectionMenu(sectionId)
  if (getState().wiki.sections.length === 0) {
    await dispatch(getSectionsThunk())
  }
  getState().wiki.sections.find((item: CategoryType[]) =>
    item.forEach(async (category) => {
      if (category.section_id === sectionId) {
        await dispatch(actions.setSelect(sectionMenu))
        await dispatch(actions.setSelectedSection(selectedSection, category.name, breadCrumbs))
      }
      return false
    })
  )
}
export const getSelectedItemThunk = (itemId: string) => async (dispatch: any) => {
  const { article } = await wikiAPI.getSelectedItem(itemId)
  /* eslint-disable-next-line */
  const { parent_section_id } = article
  const select = await wikiAPI.getSelectSectionMenu(parent_section_id)
  const breadcrumbs = await wikiAPI.getBreadCrumbs(article.section_id)
  await dispatch(actions.setArticle(article))
  await dispatch(actions.setSelect(select.sectionMenu))
  await dispatch(actions.setBreadCrumbs(breadcrumbs.breadCrumbs))
}

export const addFavoriteThunk = (fav: boolean, id: number, parentName: string) => async (dispatch: any) => {
  try {
    await toastPromise(wikiAPI.addFavorite(fav, id), dispatch, "Сохранено")
    await dispatch(getMenuItemsThunk())
    await dispatch(setSelectedThunk(parentName))
  } catch (error) {
    console.warn(error)
    toastError()
  }
}

export default wikiReducer
