import { useDispatch } from "react-redux"
import { useState } from "react"
import { useParams } from "react-router-dom"
import {
  getFavoritesAccesses,
  getSelectValues,
  getSelectedAccessItemOrSearch
} from "../../../redux/reducers/accessesReducer"
import { checkHttpError } from "../../../helpers/checkHttpError"

export const useAccessDetail = () => {
  const [load, setLoad] = useState(true)
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const fetchData = async (favorite?: boolean, isLoad = true) => {
    try {
      await setLoad(isLoad)
      await dispatch(getSelectedAccessItemOrSearch(params.id, "", favorite))
      await dispatch(getSelectValues())
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    } finally {
      await setLoad(false)
    }
  }
  return { fetchData, load, id: params.id }
}
