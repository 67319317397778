import React, { FC } from "react"
import Content from "./Content/Content"
import ArticleFiles from "./ArticleFiles"
import RelatedItems from "./RelatedItems"
import Links from "./Links"
import PostInfo from "./PostInfo"
import Voiting from "./Voiting/Voiting"
import { SelectedArticleType } from "../../../_types"
import Favorite from "../../../components/other/Favorite"
import Structure from "./Structure/Structure"
import { EditIcon } from "./EditIcon"

type DetailType = {
  selectedArticle: SelectedArticleType
  detailFavoriteClick: (fav: boolean, id: number) => void
}
const Detail: FC<DetailType> = ({ detailFavoriteClick, selectedArticle }) => {
  const { files, id, links, name, navigation, favorite, props, relatedItems, text, voiting, editLink } = selectedArticle

  const isEmpty = (arr: any[]) => arr.length > 0

  return (
    <div className="post">
      <h2 className="title post__title">
        {name} <EditIcon href={editLink} />
      </h2>
      <div className="post__container">
        {isEmpty(navigation) && <Structure content={navigation} />}
        {text && <Content content={text} />}
        <div className="post__bottom">
          <div className="post__bottomRow">
            {isEmpty(files) && <ArticleFiles items={files} />}
            {isEmpty(relatedItems) && <RelatedItems items={relatedItems} />}
            {isEmpty(links) && <Links items={links} />}
          </div>
          <div className="post__bottomRow">
            {props && <PostInfo info={props} />}
            <div className="post__bottomActions">
              <Favorite detailFavoriteClick={detailFavoriteClick} isFavorite={favorite} dataId={id} />
              {voiting && <Voiting articleID={id} items={voiting} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Detail
