import React from "react"

const ResetSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19.5" stroke="#8592B6" />
      <path
        d="M13.5 20.125C13.5 21.46 13.8959 22.7651 14.6376 23.8751C15.3793 24.9851 16.4335 25.8503 17.6669 26.3612C18.9003 26.8721 20.2575 27.0058 21.5669 26.7453C22.8762 26.4848 24.079 25.842 25.023 24.898C25.967 23.954 26.6098 22.7512 26.8703 21.4419C27.1307 20.1325 26.9971 18.7753 26.4862 17.5419C25.9753 16.3085 25.1101 15.2543 24.0001 14.5126C22.8901 13.7709 21.585 13.375 20.25 13.375L16.7625 13.375L18.7875 11.35L18 10.5625L14.625 13.9375L18 17.3125L18.7875 16.525L16.7625 14.5L20.25 14.5C21.3625 14.5 22.4501 14.8299 23.3751 15.448C24.3001 16.0661 25.0211 16.9446 25.4468 17.9724C25.8726 19.0002 25.984 20.1312 25.7669 21.2224C25.5499 22.3135 25.0141 23.3158 24.2275 24.1025C23.4408 24.8891 22.4385 25.4249 21.3474 25.6419C20.2562 25.859 19.1252 25.7476 18.0974 25.3218C17.0696 24.8961 16.1911 24.1751 15.573 23.2501C14.9549 22.3251 14.625 21.2375 14.625 20.125L13.5 20.125Z"
        fill="#fff"
      />
    </svg>
  )
}

export default ResetSvg
