import Cookies from "js-cookie"
import { InferActionsType } from "../store"
import { settingsAPI, userAPI } from "../../api/api"
import { getMenuThunk } from "./menuRudecer"
import { checkHttpError } from "../../helpers/checkHttpError"
import { AUTH_PAGE, BACK_URL_LS_KEY } from "../../api/constants"

const SET_USER_LINK = "SET_USER_LINK"
const SET_USER_INFO = "SET_USER_INFO"
const SET_USER_THEME = "SET_USER_THEME"
const ERROR = "ERROR"

export type UserInfoType = {
  name: string
  secondName: string
  lastName: string
  email: string
  login: string
  userProfileLink: string
}
export type InitialAuthStateType = {
  user: UserInfoType
  userLink: string
  DARKTHEME: boolean
  ERROR: boolean
}
export const initialState: InitialAuthStateType = {
  userLink: "",
  DARKTHEME: false,
  user: {
    name: "",
    secondName: "",
    lastName: "",
    email: "",
    login: "",
    userProfileLink: ""
  },
  ERROR: false
}
const authReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case SET_USER_LINK:
      return {
        ...state,
        userLink: action.link
      }
    case SET_USER_INFO:
      return {
        ...state,
        user: { ...action.userInfo }
      }
    case SET_USER_THEME:
      return {
        ...state,
        DARKTHEME: action.darkTheme
      }
    case ERROR:
      return {
        ...state,
        ERROR: true
      }
    default:
      return state
  }
}
type ActionsType = InferActionsType<typeof actions>
export const actions = {
  setUserLink: (link: string) => ({ type: SET_USER_LINK, link } as const),
  setUserInfo: (userInfo: UserInfoType) => ({ type: SET_USER_INFO, userInfo } as const),
  setUserTheme: (darkTheme: boolean) => ({ type: SET_USER_THEME, darkTheme } as const),
  SET_ERROR: () => ({ type: ERROR } as const)
}

export const SET_ERROR = () => async (dispatch: any) => {
  await dispatch(actions.SET_ERROR())
}
// ==========
export const getUserInfoThunk = () => async (dispatch: any) => {
  localStorage.setItem(BACK_URL_LS_KEY, JSON.stringify(window.location.pathname))
  const { userInfo, status } = await userAPI.getUserInfo()
  if (status === 401 || status === 403) {
    dispatch(setUserLink())
  } else if (status === 200) {
    localStorage.removeItem(BACK_URL_LS_KEY)
    dispatch(setUserInfo(userInfo))
    dispatch(setUserTheme())
    await dispatch(getMenuThunk())
  }
}
export const setUserLink = () => async (dispatch: any) => {
  const { link } = await userAPI.getAuthLink()
  await dispatch(actions.setUserLink(link))
}
const setUserInfo = (userInfo: UserInfoType) => async (dispatch: any) => {
  await dispatch(actions.setUserInfo(userInfo))
}
const setUserTheme = () => async (dispatch: any) => {
  const cookieTheme = Cookies.get("darktheme")
  if (!cookieTheme) {
    const theme = await settingsAPI.getUserTheme()
    Cookies.set("darktheme", theme.darkTheme.toString())
    await dispatch(actions.setUserTheme(theme.darkTheme))
  } else {
    dispatch(actions.setUserTheme(JSON.parse(cookieTheme)))
  }
}
// ========
export const changeUserThemeThunk = (newTheme: boolean) => async (dispatch: any) => {
  try {
    const { darkTheme, status } = await settingsAPI.changeUserTheme(newTheme)
    await checkHttpError(status, dispatch)
    Cookies.set("darktheme", darkTheme.toString())
    dispatch(actions.setUserTheme(darkTheme))
  } catch (error) {
    console.warn(error)
  }
}

export const logoutThunk = async () => {
  try {
    await userAPI.logout()
    window.location.href = AUTH_PAGE
  } catch (error) {
    console.warn(error)
  }
}

export default authReducer
