import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { getSelectedItemThunk } from "../../../redux/reducers/wikiReducer"
import { useTitle } from "../../useTitle"
import { AppStateType } from "../../../redux/store"
import { checkHttpError } from "../../../helpers/checkHttpError"

export const useWikiDetail = () => {
  const { selectedArticle } = useSelector((state: AppStateType) => state.wiki)
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { setTitle } = useTitle()

  const [load, setLoad] = useState(true)
  const fetchData = async () => {
    try {
      await setLoad(true)
      await dispatch(getSelectedItemThunk(id))
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    } finally {
      await setLoad(false)
    }
  }
  if (!load) {
    setTitle(selectedArticle.name)
  }
  return { fetchData, load, selectedArticle, id }
}
