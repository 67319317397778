import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { AppStateType } from "../../redux/store"
import { AUTH_PAGE } from "../../api/constants"

type PropsType = {
  onLogoClickHandler: () => void
}
const Logo: FC<PropsType> = ({ onLogoClickHandler }) => {
  const { name } = useSelector((state: AppStateType) => state.authPage.user)
  return (
    <div className="header__logo">
      <NavLink onClick={onLogoClickHandler} to={name ? "/" : AUTH_PAGE} className="header__link">
        <svg
          version="1.1"
          id="Слой_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 456.1 73"
          enableBackground="new 0 0 456.1 73"
        >
          <g>
            <path
              fill="#FFFFFF"
              d="M243.9,24.6c-3.1-2.7-7.1-4.1-12.1-4.2c-5.1-0.1-9.4,1.2-13.1,3.9c-3.7,2.6-5.6,6.1-5.8,10.3
		c-0.4,5.8,3.1,10,10.5,12.5l8.1,2.4c5.8,1.9,8.4,5.1,7.9,9.4c-0.4,2.9-1.9,5.2-4.5,6.9c-2.6,1.7-5.8,2.6-9.6,2.6
		c-3.8-0.1-6.8-1-8.9-2.9c-2.2-1.8-3.2-4.4-3.2-7.6l-5.6,0c-0.2,3,0.5,5.6,1.9,7.9c1.5,2.3,3.6,4.1,6.3,5.3c2.8,1.3,5.8,1.9,9.2,2
		l1.9,0c5.2-0.3,9.4-1.8,12.6-4.4c3.3-2.6,5-5.9,5.3-10c0.4-6-2.9-10.3-10-13l-3.1-1.1l-5.9-1.7c-5.4-1.6-7.9-4.5-7.4-8.5
		c0.3-2.8,1.7-5,4.1-6.6c2.4-1.7,5.4-2.5,9-2.5c3.4,0.1,6.2,1,8.3,3c2.1,1.9,3,4.4,2.8,7.4h5.5C248.5,30.9,247,27.3,243.9,24.6z"
            />
            <path
              fill="#FFFFFF"
              d="M274.6,8.3H269l-2.3,13h-9l-0.8,4.6h9.1l-5.6,33.8c-0.4,4.3,0.1,7.5,1.6,9.7c1.5,2.2,3.9,3.4,7.2,3.4
		c1.9,0.1,3.8-0.2,5.6-0.7l0.2-4.6c-2.1,0.3-3.6,0.4-4.5,0.3c-3.1-0.1-4.7-1.8-4.7-5.3l0.1-2.9l5.6-33.9h10.2l0.8-4.6h-10.2
		L274.6,8.3z"
            />
            <path
              fill="#FFFFFF"
              d="M320,58.1c-0.9,3.1-3,5.5-6.2,7.4c-3.2,1.9-6.7,2.8-10.4,2.6c-2.6-0.1-4.7-0.9-6.2-2.5
		c-1.5-1.6-2.4-3.8-2.6-6.7c-0.2-1.7-0.1-3.3,0-5l5.4-32.6h-5.5l-5.4,32.5c-0.7,6,0.2,10.6,2.5,14c2.3,3.4,5.9,5.1,10.7,5.2
		c7.1,0.2,12.7-2.3,16.7-7.3l-1.4,6.4h5.4l8.8-50.8h-5.6L320,58.1z"
            />
            <path
              fill="#FFFFFF"
              d="M376.4,28.5c-1.4-2.7-3.4-4.7-5.9-6c-2.5-1.3-5.2-2-8.2-2.1c-3.9-0.1-7.5,0.9-10.7,2.9
		c-3.2,2-5.9,5.1-8,9.2c-2.2,4.1-3.6,8.8-4.4,14.1l-0.1,1c-0.2,1.8-0.3,3.7-0.2,5.6c0.1,6,1.5,10.8,4.2,14.3
		c2.7,3.5,6.4,5.4,11.1,5.5c6.5,0.2,12-2.4,16.4-7.7l-1.3,6.8h5.2l12.5-72h-5.5L376.4,28.5z M371.3,59c-1.8,2.8-4,5.1-6.7,6.7
		c-2.6,1.7-5.7,2.5-9.1,2.4c-3.4-0.1-6.1-1.4-8-3.9c-1.9-2.5-2.9-5.8-3-10.1c-0.1-2.4,0-4.6,0.3-6.7l0.1-1c0.9-6.7,2.9-12,6.1-15.8
		c3.2-3.8,7.1-5.6,11.9-5.5c3.2,0.1,5.9,1,8,2.7c2.1,1.7,3.6,4.1,4.5,7.1L371.3,59z"
            />
            <path
              fill="#FFFFFF"
              d="M404.3,2.8c-2.5,0.5-3.9,1.8-4,3.8c-0.1,2.2,1.1,3.4,3.6,3.8c1.2-0.3,2.1-0.6,2.7-1.2
		c0.6-0.5,1-1.4,1.4-2.6c-0.2-1.3-0.5-2.1-1-2.7C406.4,3.4,405.5,3,404.3,2.8z"
            />
            <polygon fill="#FFFFFF" points="389.8,72 395.4,72 404.3,21.3 398.7,21.3 	" />
            <path
              fill="#FFFFFF"
              d="M454.6,33c-1.4-3.9-3.5-6.9-6.5-9.1s-6.5-3.4-10.7-3.4c-4.3-0.1-8.2,0.9-11.8,3c-3.6,2.1-6.7,5.2-9.1,9.3
		c-2.4,4.1-3.9,8.6-4.5,13.5l-0.1,1.6l-0.1,2.7c-0.1,6.7,1.6,12.1,5,16.2c3.4,4.1,8,6.2,13.7,6.3c6.6,0.2,12.3-2.2,17-7
		s7.6-11.3,8.5-19.3l0.1-0.9C456.4,41.1,455.9,36.8,454.6,33z M450.4,45.8l-0.1,1.5c-0.5,3.9-1.7,7.5-3.5,10.8
		c-1.8,3.3-4.1,5.8-6.9,7.6c-2.8,1.8-5.9,2.6-9.3,2.6c-3.1-0.1-5.7-1-7.8-2.8c-2.1-1.8-3.6-4.2-4.6-7.4c-1-3.1-1.2-6.6-0.8-10.3
		l0.1-0.8c0.8-6.7,3-12,6.7-16c3.6-4,8-5.9,13-5.8c4.6,0.1,8.1,2.1,10.5,5.9C450.1,34.9,451,39.8,450.4,45.8z"
            />
            <rect x="129.9" y="6.3" fill="#FFFFFF" width="14.2" height="11" />
            <path
              fill="#FFFFFF"
              d="M170.8,11.7h-14.2V23h-9V32h9v28.9c0,0.3,0,0.7,0,1h0c0,6,4.9,11,11,11h13.5V61.7h-6.7
		c-2,0-3.7-1.6-3.6-3.7c0,0,0,0,0,0V32h10.9V23h-10.9V11.7z"
            />
            <rect x="129.9" y="23" fill="#FFFFFF" width="14.2" height="50" />
            <path
              fill="#FFFFFF"
              d="M105.5,28.5V23H92.1v50h14.2V58.6v-8.2V50c0.1-8.8,7.2-15.9,15.9-15.9h4.2V22.9h-4.2
		C115.9,22.9,110.1,24.9,105.5,28.5z"
            />
            <path
              fill="#FFFFFF"
              d="M24.4,35.5c-2.7-2.3-6.3-3.6-10.1-3.6s-7.4,1.3-10.1,3.6C1.5,37.8,0,40.9,0,44.1h28.6
		C28.6,40.9,27.1,37.8,24.4,35.5z"
            />
            <path
              fill="#FFFFFF"
              d="M85.1,22.9H68.3L49.7,39.6V6.2H35.5v23.3c0.5,0.5,1,1.1,1.4,1.6c2.2,2.8,3.5,5.4,4.5,8.9
		c1,3.5,1.2,7.3,0.9,11.2l-15.5,0l0,0H0.1c0.2,3.3,1.7,6.3,4.2,8.7c2.7,2.5,6.3,3.9,10.1,3.9c3.8,0,7.4-1.4,10.1-3.9
		c0.8-0.7,1.4-1.5,2-2.4l14.9,0c0,0-0.5,3-2.4,5.8c-1.4,2.1-2.4,3.2-3.5,4.2v5.4l0,0.1l11.8,0h2.3l0-18.4l5.7-5.2l15,23.5h18.1
		L65.7,40.3L85.1,22.9z"
            />
            <circle fill="#FFFFFF" cx="194.1" cy="67.2" r="5.7" />
          </g>
        </svg>
      </NavLink>
    </div>
  )
}

export default Logo
