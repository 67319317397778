export const filterByUserStatus = (
  item: any,
  status: any,
  page: { isAutoClosePage?: boolean; isMigrationPage?: boolean } = {
    isMigrationPage: false,
    isAutoClosePage: false
  }
) =>
  page.isMigrationPage
    ? item.projectId === status
    : page.isAutoClosePage
    ? item.creatorId === status
    : item.responsibleId === status
