import React, { FC, useEffect } from "react"
import Parser from "html-react-parser"
import { hotToast } from "../../../../helpers/hotToast"

const copyClick = ({ target: { parentElement } }: any) => {
  const text = parentElement.querySelector("code").innerText
  navigator.clipboard.writeText(text)
  hotToast("Скопировано!")
}

const Content: FC<{ content: string }> = ({ content }) => {
  useEffect(() => {
    const codes = document.querySelectorAll(".js-code")

    if (codes.length > 0) {
      codes.forEach((item) => {
        item.addEventListener("click", copyClick)
      })
    }
  }, [])

  return <div className="post__content post__contentText">{Parser(content)}</div>
}

export default Content
