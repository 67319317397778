import React, { FC } from "react"
import { LinksAndFilesType } from "../../../_types"

type PropsType = {
  items: LinksAndFilesType[]
}
const ArticleFiles: FC<PropsType> = ({ items }) => (
  <div className="post__bottomCol post__tags">
    <h5>Файлы</h5>
    {items.map((item, index) => (
      <a className="post__tagsItem" target="_blank" key={index} href={item.href} rel="noreferrer">
        {item.name}
      </a>
    ))}
  </div>
)

export default ArticleFiles
