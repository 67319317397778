import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AppStateType } from "../../../redux/store"
import { SectionMenuType } from "../../../_types"

export const useArticles = () => {
  const history = useHistory()
  const { sectionMenu } = useSelector((state: AppStateType) => state.wiki)
  const { selectedSectionItems, breadCrumbs } = useSelector((state: AppStateType) => state.wiki.selectedSection)
  const changeHandler = (e: SectionMenuType) => {
    history.push(e.href)
  }
  const getValue = (menu: SectionMenuType[]) => {
    let value = { value: "", label: "" }
    menu.forEach((item) => {
      if (item.isSelected) {
        value = { value: item.value, label: item.label }
      }
      return false
    })
    return value
  }
  const notEmpty = Object.keys(selectedSectionItems).length > 0
  return {
    changeHandler,
    getValue,
    notEmpty,
    sectionMenu,
    selectedSectionItems,
    breadCrumbs
  }
}
