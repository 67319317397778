import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { MenuType } from "../../redux/reducers/menuRudecer"
import MykritlabMenuReactItem from "./components/MykritlabMenuReactItem"
import { AppStateType } from "../../redux/store"
import MykritlabMenuItem from "./components/MykritlabMenuItem"
import { useTitle } from "../../hooks/useTitle"

const MyKritLabMenu = () => {
  const menu = useSelector((state: AppStateType): MenuType[] => state.data.menu)
  const { setTitle } = useTitle()
  setTitle("Лаборатория разработки krit.studio")

  return (
    <div className="menuSectionListWrap">
      <div className="menuSectionList">
        <div className="menuSectionList__slider js-menu-slider">
          {menu.map((item: MenuType, key: number) => {
            if (item.react) {
              return (
                <Fragment key={key}>
                  <MykritlabMenuReactItem item={item} />
                </Fragment>
              )
            }
            return (
              <Fragment key={key}>
                <MykritlabMenuItem item={item} />
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MyKritLabMenu
