import React from "react"

const LikeIconNew = ({ fill = "none" }) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0259 5.67182L13.0274 5.66779C13.2454 5.10781 13.375 4.53803 13.375 3.97122C13.375 3.55172 13.2974 3.15397 13.1546 2.78391C13.1236 2.70558 13.0956 2.63946 13.0656 2.5767L13.0619 2.56888L13.0583 2.56096C13.0337 2.50554 13.0039 2.44636 12.9715 2.3862C12.9174 2.29253 12.8585 2.1971 12.7981 2.10352C12.7389 2.01862 12.6738 1.93442 12.6007 1.84562L12.5984 1.84282L12.5984 1.84281C12.5734 1.81212 12.543 1.77855 12.4981 1.72947C12.346 1.56774 12.1836 1.42073 12.0111 1.29152C11.9264 1.22801 11.8371 1.16807 11.743 1.11178C11.2278 0.803757 10.6216 0.625 9.97213 0.625C9.81358 0.625 9.66258 0.633452 9.52142 0.652673C9.52106 0.652723 9.52069 0.652772 9.52033 0.652822L9.4203 0.666882L9.33333 0.0479616L9.42029 0.666882C9.3767 0.673008 9.32936 0.682733 9.25742 0.697896L9.23658 0.702288L9.2245 0.703985L9.21589 0.706246L9.19521 0.712006L9.18533 0.714783L9.17536 0.717233C9.02924 0.753162 8.90154 0.789226 8.78525 0.833498L8.77604 0.837004L8.77602 0.836931C8.27392 1.01909 7.82122 1.31875 7.45965 1.7067L7.00244 2.19728L6.54522 1.7067C5.91356 1.02894 5.01663 0.629796 4.03274 0.629796C2.14449 0.629796 0.625 2.13908 0.625 3.97122C0.625 4.87536 1.03797 5.85955 1.60268 6.76606C2.1598 7.6604 2.82079 8.40999 3.2217 8.82967L3.22179 8.82977C3.80266 9.43809 4.53053 10.0929 5.24686 10.5904C5.99478 11.1097 6.61462 11.375 7.00731 11.375C7.39035 11.375 7.99828 11.1373 8.73532 10.6591C9.44447 10.199 10.1787 9.57886 10.7889 8.95808L13.0259 5.67182ZM13.0259 5.67182L13.0233 5.67855C12.9989 5.7427 12.9678 5.82408 12.9396 5.91347C12.9332 5.92819 12.9264 5.94354 12.9188 5.96074L12.9181 5.96234C12.9074 5.98659 12.8949 6.01476 12.8821 6.04474M13.0259 5.67182L12.8821 6.04474M12.8821 6.04474C12.3256 7.25589 11.429 8.30529 10.789 8.958L12.8821 6.04474Z"
        stroke="white"
        strokeWidth="1.25"
      />
    </svg>
  )
}

export default LikeIconNew
