import React, { FC } from "react"
import SwitchTheme from "./SwitchTheme"

type PropsType = {
  isAuth: boolean
}

const year = new Date().getFullYear()

const Footer: FC<PropsType> = ({ isAuth }) => (
  <footer className="footer ">
    <div className="footer__info wrapper">
      {!isAuth ? <SwitchTheme /> : <div />}
      <span className="footer__copyright">© 2009 — {year} Информация является конфиденциальной</span>
    </div>
  </footer>
)
export default Footer
