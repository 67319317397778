import React from "react"

const ViewIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7961 14.8813C15.7308 14.8093 15.6589 14.7439 15.587 14.6719C14.5091 13.5921 13.4311 12.5124 12.3465 11.4392C12.2616 11.3606 12.1375 11.3214 12.046 11.2756C14.5417 8.11482 13.9799 3.9594 11.2293 1.61012C8.57684 -0.660639 4.6046 -0.516671 2.04356 1.95695C-0.465221 4.37822 -0.700419 8.37004 1.5209 11.1512C3.76181 13.952 7.98884 14.6588 11.1706 12.1459C12.1571 13.134 13.1371 14.1156 14.1105 15.0907C14.3588 15.3394 14.594 15.5946 14.8553 15.8302C15.1232 16.0723 15.587 16.0592 15.8092 15.8302C16.064 15.5749 16.0574 15.1758 15.7961 14.8813ZM6.82592 12.3291C3.79448 12.3226 1.3445 9.8686 1.3445 6.83875C1.3445 3.81544 3.80754 1.34836 6.82592 1.33527C9.85083 1.32218 12.3269 3.80889 12.3204 6.85184C12.3073 9.88824 9.85083 12.3357 6.82592 12.3291Z"
        fill="white"
      />
      <path
        d="M9.0093 6.18407C8.51931 6.13826 8.01624 6.17098 7.48705 6.17098C7.48705 5.62129 7.48705 5.11086 7.48705 4.60043C7.48705 4.32558 7.38252 4.10963 7.14079 3.97875C6.70306 3.73008 6.1804 4.0311 6.16733 4.55462C6.14773 5.08468 6.1608 5.61474 6.1608 6.17098C6.0236 6.17098 5.9256 6.17098 5.8276 6.17098C5.38987 6.17098 4.95867 6.15789 4.52094 6.17752C4.14855 6.19061 3.88068 6.47855 3.87415 6.83192C3.86762 7.18529 4.11588 7.46014 4.49481 7.4994C4.64508 7.51249 4.80187 7.51249 4.95214 7.51249C5.3376 7.51249 5.7296 7.51249 6.15426 7.51249C6.15426 7.66955 6.15426 7.78734 6.15426 7.90513C6.15426 8.3174 6.1412 8.73621 6.1608 9.14848C6.1804 9.57384 6.52013 9.84214 6.93825 9.7767C7.25839 9.72435 7.46745 9.46914 7.47398 9.10268C7.48052 8.5857 7.47398 8.06873 7.47398 7.51904C7.66345 7.51904 7.80065 7.51904 7.94438 7.51904C8.34944 7.51904 8.74797 7.53867 9.15304 7.4994C9.66916 7.4536 9.95663 6.87773 9.6365 6.49163C9.50583 6.32149 9.23143 6.2037 9.0093 6.18407Z"
        fill="white"
      />
    </svg>
  )
}

export default ViewIcon
