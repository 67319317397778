import React from "react"
import { NavLink } from "react-router-dom"
import { BreadCrumbArrow } from "./BreadCrumbArrow"

const BreadCrumbsWithoutData = () => (
  <>
    <li className="breadCrumbs__item">
      <NavLink to="/" title="Главная" className="breadCrumbs__link">
        Главная
      </NavLink>
    </li>
    <BreadCrumbArrow />
    <li className="breadCrumbs__item">
      <NavLink to="/access-manager" title="Доступы" className="breadCrumbs__link">
        Доступы
      </NavLink>
    </li>
    <BreadCrumbArrow />
  </>
)
export default BreadCrumbsWithoutData
