import React from "react"
import { useCopyRowClick } from "../../../../../hooks/useCopyRowClick"

export const CopyIcon = () => {
  const { copyRowClick } = useCopyRowClick()
  return (
    <svg
      onClick={(e) => copyRowClick(e)}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7582 5.32895V17.3417H1.74524V5.32895H13.7582Z" stroke="#FFF" fill="transparent" strokeWidth="1.5" />
      <path
        d="M18.3536 13.513H17.0407C16.6835 13.513 16.3941 13.2233 16.3941 12.8664C16.3941 12.5092 16.6835 12.2198 17.0407 12.2198H17.707V1.29347H6.78038V2.01284C6.78038 2.37003 6.49097 2.65944 6.13378 2.65944C5.77659 2.65944 5.48718 2.36975 5.48718 2.01284V0.646598C5.48718 0.289683 5.77659 0 6.13378 0H18.3536C18.7108 0 19.0002 0.289683 19.0002 0.646598V12.8664C19.0002 13.2233 18.7108 13.513 18.3536 13.513Z"
        fill="#B6C2E2"
        stroke="#FFF"
      />
    </svg>
  )
}
