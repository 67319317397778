import React from "react"

export const AccessDetailListHeader = () => (
  <div className="accessDetail__item accessDetail__item--head">
    <div
      className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--type"
      data-title="Вид доступа"
    />
    <div className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--host" data-title="Хост" />
    <div
      className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--login"
      data-title="Логин"
    />
    <div
      className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--pass"
      data-title="Пароль"
    />
    <div
      className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--notice"
      data-title="Прим."
      data-title-sm="Примечания"
    />
    <div className="accessDetail__itemCol accessDetail__itemCol--head accessDetail__itemCol--actions" />
  </div>
)
