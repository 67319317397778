import React, { FC } from "react"
import { TasksUsers } from "./ContentTop/TasksUsers"
import { isNotEmpty } from "../../../helpers/isNotEmpty"
import { ProjectsType, TaskType, UserType } from "../../../_types"
import { useTabTitle } from "../../../hooks/Tasks/useTabTitle"
import { useContent } from "../../../hooks/Tasks/useContent"
import { EmptyRequest } from "../../../components/Empty/EmptyRequest"
import { EmptyTaskListIcon } from "./EmptyTaskListIcon"
import { TasksItems } from "./TasksItems"
import { useDefinePage } from "../../../hooks/Tasks/useDefinePage"
import { ContentTop } from "./ContentTop/ContentTop"
import { TasksProjects } from "./ContentTop/TasksProjects"

type PropsType = {
  tasks: TaskType[]
  selected: string
  users: UserType[]
  projects: ProjectsType[]
}
export const Content: FC<PropsType> = ({ tasks, selected, projects, users }) => {
  const title = useTabTitle(selected)
  const { isMigrationPage } = useDefinePage(title)
  const {
    selectedTasks,
    getTotalCount,
    selectedUser,
    onUserClick,
    selectedProject,
    getProjectsTotalCount,
    onProjectClick,
    filter
  } = useContent(tasks, users, projects, title)
  const hasUsers = Boolean(users.length)
  const hasTasks = Boolean(tasks.length)

  const renderTop = (items: UserType[] | ProjectsType[]) => {
    if (!isNotEmpty(items)) return null
    if (items === users && getTotalCount(users) > 0)
      return (
        <ContentTop title={title}>
          <TasksUsers
            items={items}
            isOpen
            count={getTotalCount(items)}
            onUserClick={onUserClick}
            totalCount={getTotalCount(items)}
          />
        </ContentTop>
      )
    if (items === projects) {
      const itemsCounted = items.map((item) => {
        const count = tasks.filter((task) => task.projectId === item.id).length
        return { ...item, count }
      })
      return (
        <ContentTop title={title}>
          <TasksProjects
            items={itemsCounted}
            onProjectClick={onProjectClick}
            totalCount={getProjectsTotalCount(tasks)}
          />
        </ContentTop>
      )
    }
    return null
  }
  if (!hasTasks)
    return (
      <div className="wrapper mt30">
        <EmptyRequest title="Задач не найдено" icon={<EmptyTaskListIcon />} text="Здесь скоро что-нибудь появится" />
      </div>
    )
  return (
    <div className="wrapper">
      <div className="tasksContent">
        {renderTop(isMigrationPage ? projects : users)}
        <TasksItems
          items={selectedTasks}
          filter={filter}
          responsible={selectedUser.name || "Все"}
          selectedUserId={selectedUser.id}
          hasUsers={hasUsers}
          title={title}
          selectedProject={selectedProject}
        />
      </div>
    </div>
  )
}
