import React, { FC } from "react"

type PropsType = {
  text: string
  title: string
  icon: any
  className?: string
}
export const EmptyRequest: FC<PropsType> = ({ text, title, icon, className }) => (
  <div className="wikiListBlock__listEmpty fullWidth">
    <div className={className ? `wikiListBlock__listEmptyWrap ${className}` : "wikiListBlock__listEmptyWrap"}>
      <div className="wikiListBlock__listEmptyIcon">{icon}</div>
      <div className="wikiListBlock__listEmptyText">
        <div className="wikiListBlock__listEmptyTitle">{title}</div>
        {text}
      </div>
    </div>
  </div>
)
