/* eslint-disable no-console */
import { InferActionsType } from "../store"
import { accessesAPI } from "../../api/api"
import { AccessesItemType, SelectValueType, SelectedAccessDataType, SelectedAccessItemType } from "../../_types"
import { AddOrEditFormType } from "../../hooks/Accesses/AccessDetail/useAccessForm"
import { toastPromise } from "../../helpers/toastPromise"
import { checkHttpError } from "../../helpers/checkHttpError"

const SET_ACCESSES_ITEMS = "SET_ACCESSES_ITEMS"
const SET_SELECTED_ACCESS_ITEM = "SET_SELECTED_ACCESS_ITEM"
const SET_SELECTED_ACCESS = "SET_SELECTED_ACCESS"
const SET_FAVORITES_ACCESSES = "SET_FAVORITES_ACCESSES"
const SET_SELECT_VALUES = "SET_SELECT_VALUES"
const SET_CONTRAGENT_EXPORT = "SET_CONTRAGENT_EXPORT"

export type InitialAuthStateType = {
  items: AccessesItemType[]
  totalPages: number
  currentPage: number
  selectedAccessItem: {
    items: SelectedAccessItemType[]
    data: SelectedAccessDataType
  }
  favoritesAccesses: SelectedAccessItemType[]
  selectedAccess: SelectedAccessItemType
  contragentID: number
  selectValues: SelectValueType[]
  filePath: string
}
const initialState: InitialAuthStateType = {
  items: [],
  totalPages: 0,
  currentPage: 1,
  selectedAccessItem: {
    items: [],
    data: {
      creator: "",
      date: "",
      href: "",
      id: "",
      name: "",
      site: "",
      isMine: 0
    }
  },
  favoritesAccesses: [],
  selectedAccess: {
    canChange: false,
    database: "",
    host: "",
    id: 0,
    like: false,
    login: "",
    notice: "",
    password: "",
    port: "",
    type: "",
    files: [],
    requested: false,
    available: false,
    options: {
      copy: {
        availability: true
      },
      access: {
        availability: true,
        link: ""
      },
      favorite: {
        availability: true
      },
      delete: {
        availability: true
      }
    }
  },
  contragentID: 0,
  selectValues: [],
  filePath: ""
}

const accessesReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case SET_ACCESSES_ITEMS:
      return {
        ...state,
        items: [...action.items],
        totalPages: action.totalPages,
        currentPage: action.currentPage
      }
    case SET_SELECTED_ACCESS_ITEM:
      return {
        ...state,
        selectedAccessItem: {
          items: [...action.items],
          data: { ...action.data }
        }
      }
    case SET_SELECTED_ACCESS:
      return {
        ...state,
        selectedAccess: action.item,
        contragentID: action.contragentID
      }
    case SET_FAVORITES_ACCESSES:
      return {
        ...state,
        favoritesAccesses: action.items
      }
    case SET_SELECT_VALUES:
      return {
        ...state,
        selectValues: action.values
      }
    case SET_CONTRAGENT_EXPORT:
      return {
        ...state,
        filePath: action.filePath
      }
    default:
      return state
  }
}
type ActionsType = InferActionsType<typeof actions>
const actions = {
  setContrAgentItems: (items: AccessesItemType[], totalPages: number, currentPage: number) =>
    ({
      type: SET_ACCESSES_ITEMS,
      items,
      totalPages,
      currentPage
    } as const),
  setSelectedAccessItem: (items: SelectedAccessItemType[], data: SelectedAccessDataType) =>
    ({
      type: SET_SELECTED_ACCESS_ITEM,
      items,
      data
    } as const),
  setSelectedAccess: (item: SelectedAccessItemType, contragentID: number) =>
    ({
      type: SET_SELECTED_ACCESS,
      item,
      contragentID
    } as const),
  setFavoritesAccesses: (items: SelectedAccessItemType[]) =>
    ({
      type: SET_FAVORITES_ACCESSES,
      items
    } as const),
  setSelectValues: (values: SelectValueType[]) =>
    ({
      type: SET_SELECT_VALUES,
      values
    } as const),
  setContragentExport: (filePath: any) =>
    ({
      type: SET_CONTRAGENT_EXPORT,
      filePath
    } as const)
}
export const getAccessesItemsThunk =
  (myProject = false, accessesContragents = false, page = 0) =>
  async (dispatch: any) => {
    const { items, currentPage, totalPages } = await accessesAPI.getAccessesItems(myProject, accessesContragents, page)
    await dispatch(actions.setContrAgentItems(items, totalPages, currentPage))
  }
export const changePageThunk = (page: number, value?: string) => async (dispatch: any) => {
  try {
    const { items, currentPage, totalPages, status } = await accessesAPI.changePage(page, value)
    await checkHttpError(status, dispatch)
    /* eslint-disable-next-line */
    const url = new URL(String(window.location))
    url.searchParams.set("page", String(page))
    window.history.pushState({}, "", url)

    dispatch(actions.setContrAgentItems(items, totalPages, currentPage))
  } catch (error) {
    console.error(error)
  }
}
export const searchThunk = (value: string) => async (dispatch: any) => {
  const { items, currentPage, totalPages } = await accessesAPI.contrAgentSearch(value)
  dispatch(actions.setContrAgentItems(items, totalPages, currentPage))
}
export const getSelectedAccessItemOrSearch =
  (id: string, value?: string, isFavorite?: boolean) => async (dispatch: any) => {
    const { items, info } = await accessesAPI.getSelectedAccessItemOrSearch(id, value, isFavorite)
    dispatch(actions.setSelectedAccessItem(items, info))
  }
export const getSelectedAccess = (id: number, contragentID: string) => async (dispatch: any) => {
  try {
    const { access, status } = await accessesAPI.getAccessDetailInfo(id)
    await checkHttpError(status, dispatch)
    await dispatch(actions.setSelectedAccess(access, Number(contragentID)))
  } catch (error) {
    await dispatch(actions.setSelectedAccess(initialState.selectedAccess, Number(contragentID)))
    console.error(error)
  }
}
export const removeAccessThunk =
  (searchValue: string | undefined, isFavorite: boolean) => async (dispatch: any, getState: any) => {
    try {
      const { id } = getState().accesses.selectedAccess
      const { contragentID } = getState().accesses
      await toastPromise(accessesAPI.deleteAccess(id, contragentID), dispatch, "Удалено")
      dispatch(getSelectedAccessItemOrSearch(contragentID, searchValue, isFavorite))
      dispatch(getFavoritesAccesses(contragentID))
    } catch (error) {
      console.error(error)
    }
  }
export const addAccessThunk =
  (form: AddOrEditFormType, id: string, searchValue: string | undefined, files: any, isFavorite: boolean) =>
  async (dispatch: any) => {
    try {
      await toastPromise(accessesAPI.addAccess(form, id, files), dispatch, "Добавлено")
      dispatch(getSelectedAccessItemOrSearch(id, searchValue, isFavorite))
      dispatch(getFavoritesAccesses(id))
    } catch (error) {
      console.error(error)
    }
  }
export const saveAccessEditsThunk =
  (
    form: AddOrEditFormType,
    id: string,
    contragentID: string,
    searchValue: string | undefined,
    delServerfiles?: number[],
    isFavorite?: boolean
  ) =>
  async (dispatch: any) => {
    try {
      await toastPromise(
        accessesAPI.saveAccessEdits(form, contragentID, Number(id), delServerfiles),
        dispatch,
        "Сохранено"
      )
      dispatch(getSelectedAccessItemOrSearch(contragentID, searchValue, isFavorite))
      dispatch(getFavoritesAccesses(contragentID))
    } catch (error) {
      console.error(error)
    }
  }

export const likeEventThunk =
  (id: number, contragentID: string, itemLike: boolean, searchValue: string | undefined, isFavorite: boolean) =>
  async (dispatch: any) => {
    try {
      await toastPromise(accessesAPI.likeEvent(id, contragentID, itemLike), dispatch, "Сохранено")
      dispatch(getSelectedAccessItemOrSearch(contragentID, searchValue, isFavorite))
      dispatch(getFavoritesAccesses(contragentID))
    } catch (error) {
      console.error(error)
    }
  }

export const getFavoritesAccesses = (id: string) => async (dispatch: any) => {
  const { items } = await accessesAPI.getFavoritesAccesses(id)
  dispatch(actions.setFavoritesAccesses(items))
}
export const getSelectValues = () => async (dispatch: any) => {
  const { values } = await accessesAPI.getSelectValues()
  dispatch(actions.setSelectValues(values))
}

export const getContragentExportThunk = (id: string) => async (dispatch: any) => {
  const result = await accessesAPI.getContragentExport(id)
  dispatch(actions.setContragentExport(result.filePath))
  return result
}

export default accessesReducer
