import React from "react"

const ChristSvg = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27766 7.00106L13.7287 1.55C14.0819 1.19681 14.0819 0.62234 13.7287 0.269149C13.3755 -0.0840425 12.8011 -0.0840425 12.4479 0.269149L6.99681 5.72021L1.54574 0.264894C1.19255 -0.0882979 0.618085 -0.0882979 0.264894 0.264894C-0.0882979 0.618085 -0.0882979 1.19255 0.264894 1.54574L5.71596 6.99681L0.264894 12.4521C-0.0882979 12.8053 -0.0882979 13.3798 0.264894 13.733C0.443617 13.9117 0.673404 13.9968 0.907447 13.9968C1.14149 13.9968 1.37128 13.9074 1.55 13.733L7.00106 8.28191L12.4521 13.733C12.6309 13.9117 12.8606 13.9968 13.0947 13.9968C13.3287 13.9968 13.5585 13.9074 13.7372 13.733C14.0904 13.3798 14.0904 12.8053 13.7372 12.4521L8.27766 7.00106Z"
        fill="#8592B6"
      />
    </svg>
  )
}

export default ChristSvg
