import { useDispatch } from "react-redux"
import { useState } from "react"
import { getSupportThunk } from "../../redux/reducers/tasksReducer"

export const useSupport = () => {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(true)
  const fetchData = async (date?: string) => {
    try {
      await setLoad(true)
      await dispatch(getSupportThunk(date))
    } catch (error) {
      console.warn(error)
    } finally {
      setLoad(false)
    }
  }
  return { fetchData, load }
}
