import React from "react"

export const AuthSVG = () => (
  <div className="login__icon">
    <svg width="252" height="244" viewBox="0 0 252 244" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M98.007 59.0156C71.451 69.7997 46.1082 54.1627 20.0239 70.2041C-3.90345 84.965 -5.11667 118.733 15.5755 145.424C41.3901 178.652 83.044 160.387 114.722 181.483C139.863 198.266 161.768 242.886 196.75 228.529C246.896 207.905 242.987 121.699 219.059 81.2579C209.286 64.812 183.943 25.1128 165.408 18.5075C124.496 4.08367 125.507 47.8944 98.007 59.0156Z"
        fill="#3262E0"
      />
      <path
        d="M170.26 178.653L150.175 198.739C149.096 199.817 149.096 201.502 150.175 202.581C151.253 203.659 152.938 203.659 154.017 202.581L174.17 182.428"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.482 166.049L210.635 145.896C211.713 144.817 211.713 143.132 210.635 142.054C209.557 140.976 207.871 140.976 206.793 142.054L186.64 162.207"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.867 168.745L12.1377 10.0154C10.4527 8.33037 10.4527 5.70173 12.1377 4.0167C13.8228 2.33167 16.4514 2.33167 18.1364 4.0167L176.866 162.746"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M248.716 240.459C245.683 243.492 240.763 243.492 237.73 240.459L168.442 171.104L179.428 160.117L248.784 229.473C251.749 232.573 251.749 237.426 248.716 240.459Z"
        fill="#283149"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.18 160.137L168.5 176.817L176.125 184.443L192.806 167.762L185.18 160.137Z"
        fill="#283149"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.574 168.978L177.276 183.275L188.476 194.475L202.774 180.178L191.574 168.978Z"
        fill="#283149"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.598 222.599L237.393 234.394C238.876 235.877 241.369 235.877 242.852 234.394L245.818 231.428"
        fill="#283149"
      />
      <path
        d="M225.598 222.599L237.393 234.394C238.876 235.877 241.369 235.877 242.852 234.394L245.818 231.428"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.091 225.091L233.82 219.362"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.247 221.79L230.518 227.519"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M238.606 224.148C238.606 224.148 232.607 230.147 232.877 229.878L238.606 224.148Z" fill="#283149" />
      <path
        d="M238.606 224.148C238.606 224.148 232.607 230.147 232.877 229.878"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M240.963 226.508L235.234 232.237"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.39 228.934L237.661 234.663"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.827 192.402L205.646 200.221C206.117 200.693 206.117 201.501 205.646 202.041C205.174 202.512 204.365 202.512 203.826 202.041L196.007 194.222"
        fill="white"
      />
      <path
        d="M197.827 192.402L205.646 200.221C206.117 200.693 206.117 201.501 205.646 202.041C205.174 202.512 204.365 202.512 203.826 202.041L196.007 194.222"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.613 187.617L210.431 195.436C210.903 195.908 210.903 196.716 210.431 197.256C209.96 197.727 209.151 197.727 208.611 197.256L200.793 189.437"
        fill="white"
      />
      <path
        d="M202.613 187.617L210.431 195.436C210.903 195.908 210.903 196.716 210.431 197.256C209.96 197.727 209.151 197.727 208.611 197.256L200.793 189.437"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.782 196.446L204.701 185.527"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M226.677 211.746L217.982 203.051C216.162 201.232 213.264 201.232 211.444 203.051C209.624 204.871 209.624 207.769 211.444 209.589L220.072 218.217"
        fill="white"
      />
      <path
        d="M226.677 211.746L217.982 203.051C216.162 201.232 213.264 201.232 211.444 203.051C209.624 204.871 209.624 207.769 211.444 209.589L220.072 218.217"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M231.301 206.397L214.62 223.078L219.863 228.321L236.543 211.64L231.301 206.397Z"
        fill="#283149"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.216 209.455C216.519 209.455 217.575 208.399 217.575 207.096C217.575 205.793 216.519 204.737 215.216 204.737C213.913 204.737 212.857 205.793 212.857 207.096C212.857 208.399 213.913 209.455 215.216 209.455Z"
        fill="#283149"
      />
      <path
        d="M75.4946 160.993L234.224 2.26377C235.909 0.578743 238.538 0.578743 240.223 2.26377C241.908 3.9488 241.908 6.57744 240.223 8.26247L81.4933 166.992"
        fill="white"
      />
      <path
        d="M75.4946 160.993L234.224 2.26377C235.909 0.578743 238.538 0.578743 240.223 2.26377C241.908 3.9488 241.908 6.57744 240.223 8.26247L81.4933 166.992"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71339 238.841C0.680336 235.808 0.680336 230.888 3.71339 227.855L73.0691 158.566L84.0555 169.553L14.6998 238.841C11.6667 241.874 6.74644 241.874 3.71339 238.841Z"
        fill="white"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.6872 177.627L65.0065 160.946L59.7639 166.189L76.4447 182.87L81.6872 177.627Z"
        fill="white"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.8072 221.443L21.1265 204.763L15.8839 210.005L32.5647 226.686L37.8072 221.443Z" fill="#283149" />
      <path
        d="M35.7287 211.746C37.0316 211.746 38.0878 210.69 38.0878 209.387C38.0878 208.084 37.0316 207.028 35.7287 207.028C34.4259 207.028 33.3697 208.084 33.3697 209.387C33.3697 210.69 34.4259 211.746 35.7287 211.746Z"
        fill="#283149"
      />
      <path
        d="M26.8327 210.466L34.8534 202.446C36.1341 201.165 38.2235 201.165 39.5041 202.446C40.7847 203.726 42.8742 203.726 44.1548 202.446L56.6914 189.976C57.972 188.696 57.972 186.606 56.6914 185.326C55.4108 184.045 55.4108 181.956 56.6914 180.675L59.657 177.709C60.9377 176.429 63.0271 176.429 64.3077 177.709L68.1496 181.551"
        fill="white"
      />
      <path
        d="M26.8327 210.466L34.8534 202.446C36.1341 201.165 38.2235 201.165 39.5041 202.446C40.7847 203.726 42.8742 203.726 44.1548 202.446L56.6914 189.976C57.972 188.696 57.972 186.606 56.6914 185.326C55.4108 184.045 55.4108 181.956 56.6914 180.675L59.657 177.709C60.9377 176.429 63.0271 176.429 64.3077 177.709L68.1496 181.551"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5739 215.723L9.77874 227.518C8.29592 229.001 8.29592 231.494 9.77874 232.977L12.7444 235.943"
        fill="white"
      />
      <path
        d="M21.5739 215.723L9.77874 227.518C8.29592 229.001 8.29592 231.494 9.77874 232.977L12.7444 235.943"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.081 218.217L24.8101 223.946"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4506 226.372L16.7215 220.643"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.0242 228.731C20.0242 228.731 14.0255 222.732 14.2951 223.002L20.0242 228.731Z" fill="white" />
      <path
        d="M20.0242 228.731C20.0242 228.731 14.0255 222.732 14.2951 223.002"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6647 231.09L11.9356 225.361"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3062 233.517L9.57715 227.788"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.2392 166.183L64.9819 171.44"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.4394 172.384L71.1821 177.641"
        stroke="#283149"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.34 86.3133L111.285 69.0586"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M121.395 82.2692C121.058 82.2692 121.395 51.2646 121.395 51.2646V82.2692Z" fill="#283149" />
      <path
        d="M121.395 82.2692C121.058 82.2692 121.395 51.2646 121.395 51.2646"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.798 82.2696L132.516 71.1484"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.084 107.006L172.282 100.131"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.769 112.6H166.89"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M97.4671 107.005C96.456 106.871 77.5837 101.815 77.5837 101.815L97.4671 107.005Z" fill="#283149" />
      <path
        d="M97.4671 107.005C96.456 106.871 77.5837 101.815 77.5837 101.815"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.4684 113.611L84.6622 116.712"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
