import React, { FC } from "react"
import classNames from "classnames"

type SupportTabsType = {
  title: string
  tabContentShow: string
  setTabContentShow: any
}

type ShowContent = {
  tabContentShow: string
  setTabContentShow: any
}

export const SupportTabs: FC<ShowContent> = ({ setTabContentShow, tabContentShow }) => {
  return (
    <div className="support-tabs wrapper">
      <ul className="wikiListMenu__list">
        <SupportTabItem title={"календарь"} setTabContentShow={setTabContentShow} tabContentShow={tabContentShow} />
        <SupportTabItem title={"график"} setTabContentShow={setTabContentShow} tabContentShow={tabContentShow} />
        <SupportTabItem title={"справка"} setTabContentShow={setTabContentShow} tabContentShow={tabContentShow} />
      </ul>
    </div>
  )
}

const SupportTabItem: FC<SupportTabsType> = ({ title, setTabContentShow, tabContentShow }) => {
  return (
    <li className="wikiListMenu__item" id={title}>
      <button
        type="button"
        className={classNames({
          wikiListMenu__link: true,
          active: title === tabContentShow
        })}
        onClick={() => setTabContentShow(title)}
      >
        <span className="wikiListMenu__linkText">{title}</span>
      </button>
    </li>
  )
}
