import React, { FC } from "react"
import { SupportInfoBlock } from "../../_types"
import { TasksItem } from "../TasksPage/components/TasksItems"
import { isNotEmpty } from "../../helpers/isNotEmpty"

export type Types = {
  infoBlock: SupportInfoBlock
}

const TasksItems: FC<Types> = ({ infoBlock }) => {
  return (
    <div className="wrapper margin">
      <div className="tasksItems__list">
        {isNotEmpty(infoBlock.tasks) && infoBlock.tasks.map((item) => <TasksItem key={item.id} item={item} />)}
      </div>
    </div>
  )
}

export default TasksItems
