import React, { FC, useEffect, useState } from "react"
import classNames from "classnames"
import { UserType } from "../../../../_types"
import { AllUsersLabel } from "../AllUsersLabel"
import { slideToggle } from "../../../../components/modules/util"

type PropsType = {
  items: UserType[]
  isOpen: boolean
  count: number
  onUserClick: (id: number) => void
  totalCount: number
}
export const TasksUsers: FC<PropsType> = ({ items, onUserClick, count, totalCount }) => {
  const [actuallyUsers, setActuallyUsers] = useState<UserType[]>(items)
  const [isOpen, setIsOpen] = useState(false)
  const show = items.length > count
  const itemsShow = actuallyUsers.slice(0, count)
  const itemsHidden = actuallyUsers.slice(count)
  const onMoreClick = () => {
    const list = document.querySelector(".js-hidden-users")
    slideToggle(list, 500)
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    setActuallyUsers(items.filter((item) => parseInt(item.taskCount, 10) > 0))
  }, [])
  return (
    <div className="tasksContent__users tasksUsers">
      <div className="tasksUsers__list">
        <div className="tasksUsers__listWrap">
          <AllUsersLabel onItemClick={onUserClick} totalCount={totalCount} />
          {itemsShow.map((item) => (
            <TasksUsersItem onUserClick={onUserClick} item={item} key={item.id} />
          ))}
        </div>
        <div
          className={classNames({
            "js-hidden-users": true,
            "tasksUsers__listWrap--hidden": !isOpen
          })}
        >
          <div className="tasksUsers__listWrap">
            {itemsHidden.map((item) => (
              <TasksUsersItem item={item} key={item.id} onUserClick={onUserClick} />
            ))}
          </div>
        </div>
      </div>
      {show && (
        <button onClick={() => onMoreClick()} type="button" className="tasksUsers__button">
          {isOpen ? "Скрыть" : "Показать всех"}
        </button>
      )}
    </div>
  )
}
const TasksUsersItem: FC<{
  item: UserType
  onUserClick: (id: number) => void
}> = ({ item, onUserClick }) => (
  <label className="tasksUsers__item" htmlFor={item.id.toString()}>
    <input
      id={item.id.toString()}
      type="radio"
      name="users__tasks"
      value={item.id}
      onClick={() => onUserClick(item.id)}
    />
    <div
      className={classNames({
        tasksUsers__itemWrap: true,
        "tasksUsers__itemWrap--all": true
      })}
    >
      <div className="tasksUsers__itemName">
        {item.name}&nbsp;{item.lastName}
      </div>
      <div className="tasksUsers__itemCount">{item.taskCount}</div>
    </div>
  </label>
)
