import { useSelector } from "react-redux"
import { useEffect } from "react"
import { AppStateType } from "../../../redux/store"
import { useTitle } from "../../useTitle"
import { SelectedAccessDataType, SelectedAccessItemType } from "../../../_types"

export const useAccessDetailData = (): DataType => {
  const { items, data } = useSelector((state: AppStateType) => state.accesses.selectedAccessItem)
  const { favoritesAccesses } = useSelector((state: AppStateType) => state.accesses)
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle(data.name)
  }, [setTitle, data.name])

  return { favoritesAccesses, data, items }
}
export type DataType = {
  data: SelectedAccessDataType
  favoritesAccesses: SelectedAccessItemType[]
  items: SelectedAccessItemType[]
}
