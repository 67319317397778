import React from "react"
import { useAccessDetailContext } from "../context/useAccessDetailContext"
import { RenderArrayLinks } from "../../components/AccessesItem"

const AccessDetailCard = () => {
  const headerTitles = [
    { text: "Дата регистрации:", shortTitle: null, isGray: false, type: "date" },
    { text: "Группы:", shortTitle: null, isGray: true, type: "kritlabGroup" },
    { text: "Компании:", shortTitle: null, isGray: true, type: "kritlabCompany" },
    { text: "Контакты:", shortTitle: null, isGray: true, type: "kritlabContact" },
    { text: "Вики:", shortTitle: null, isGray: true, type: "wiki" },
    { text: "Репозитории:", shortTitle: null, isGray: true, type: "gitRepository" },
    { text: "Чаты:", shortTitle: null, isGray: true, type: "telegram" },
    { text: "Сайты:", shortTitle: null, isGray: true, type: "site" }
  ]
  const { data } = useAccessDetailContext()
  return (
    <div className="accessDetail__card">
      {headerTitles.map((row, key) => {
        const value = data[row?.type as any]
        if (value.length) {
          return (
            <div className="accessDetail__cardRow" key={key}>
              <div className="accessDetail__cardCaption">{row.text}</div>
              <div className="accessDetail__cardValue">
                <div className="linksInInfo">{typeof value === "string" ? value : RenderArrayLinks(value, true)}</div>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}
export default AccessDetailCard
