import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import thunkMiddleware from "redux-thunk"
import authReducer from "./reducers/authReducer"
import menuReducer from "./reducers/menuRudecer"
import wikiReducer from "./reducers/wikiReducer"
import accessesReducer from "./reducers/accessesReducer"
import tasksReducer from "./reducers/tasksReducer"
import factSpentReducer from "./reducers/factSpentReducer"

const rootReducer = combineReducers({
  authPage: authReducer,
  data: menuReducer,
  wiki: wikiReducer,
  accesses: accessesReducer,
  tasks: tasksReducer,
  factCoasts: factSpentReducer
})
type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>

type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never
export type InferActionsType<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))
// @ts-ignore
window.store = store
export default store
