import { toast } from "react-hot-toast"
import { checkHttpError } from "./checkHttpError"

export const toastPromise = async (promise: any, dispatch: any, text: string) => {
  await toast
    .promise(
      promise,
      {
        loading: "Загрузка...",
        success: text,
        error: "Ошибка"
      },
      {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff"
        }
      }
    )
    .then(async (response: any) => {
      await checkHttpError(response?.status, dispatch)
    })
}
