import React, { useEffect } from "react"

import BreadCrumbs from "../../components/other/BreadCrumbs/BreadCrumbs"
import Articles from "./components/Articles"
import FallBack from "../../components/other/FallBack"
import { useArticles } from "../../hooks/Wiki/wikiArticles/useArticles"
import { useWikiArticles } from "../../hooks/Wiki/wikiArticles/useWikiArticles"

const MykritlabArticles = () => {
  const { notEmpty, breadCrumbs, selectedSectionItems } = useArticles()
  const { fetchData, categoryName, load, id } = useWikiArticles()
  useEffect(() => {
    fetchData()
  }, [id])

  if (load) {
    return <FallBack />
  }
  return (
    <div>
      <div className="whiteBack">
        <div className="wrapper">
          <div className="d-sm-flex">
            {notEmpty && <BreadCrumbs breadCrumbs={breadCrumbs} lastItem={categoryName} />}
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="articles">
          {notEmpty && <h2 className="title">{categoryName}</h2>}
          {notEmpty && <Articles items={selectedSectionItems} />}
        </div>
      </div>
    </div>
  )
}

export default MykritlabArticles
