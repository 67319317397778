import { useEffect, useState } from "react"
import { TabTitleType } from "./useTabTitle"

export const useDefinePage = (title: TabTitleType) => {
  const [isAutoClosePage, setIsAutoClosePage] = useState(false)
  const [isMigrationPage, setIsMigrationPage] = useState(false)
  useEffect(() => {
    switch (title.code) {
      case 1:
        return setIsMigrationPage(true)
      case 2:
        return setIsAutoClosePage(false)
      case 3:
      case 4:
      case 5:
        return setIsAutoClosePage(true)
      default:
        return setIsAutoClosePage(false)
    }
  }, [title.code])
  return { isAutoClosePage, isMigrationPage }
}
