import { useDispatch } from "react-redux"
import { useState } from "react"
import { getAccessesItemsThunk } from "../../redux/reducers/accessesReducer"
import { checkHttpError } from "../../helpers/checkHttpError"

export const useAccesses = () => {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(true)
  const fetchData = async (myProject: boolean, access: boolean, page: number) => {
    try {
      await setLoad(true)
      await dispatch(getAccessesItemsThunk(myProject, access, page))
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    } finally {
      await setLoad(false)
    }
  }
  return { fetchData, load }
}
