import React from "react"
import Search from "./Search"
import Logo from "./Logo"
import Menu from "./Menu"
import User from "./User"
import Icon from "./Icon"
import { useHeader } from "../../hooks/useHeader"

const MobileMenu = () => {
  const { menu, onClickHandler, onLogoClickHandler } = useHeader()
  return (
    <div className="header__middleWrap">
      <div className="header__middleTop">
        <Icon clickEvent={onClickHandler} />
        <User />
      </div>
      <Logo onLogoClickHandler={onLogoClickHandler} />
      <Search clickEvent={onClickHandler} />
      <Menu items={menu} />
    </div>
  )
}

export default MobileMenu
