import React, { FC, memo } from "react"
import { NavLink } from "react-router-dom"
import Favorite from "../../../../components/other/Favorite"
import { TopMenuType } from "../../../../_types"
import { isNotEmpty } from "../../../../helpers/isNotEmpty"
// TYPES===================
export type ListType = {
  items: TopMenuType
  handleFavoriteClick?: (fav: boolean, id: number, parentName: string | undefined) => void
}
export type ItemType = {
  item: {
    id: number
    href: string
    name: string
    date: string
    changeDate: string
    favorite: boolean
  }
  handleFavoriteClick?: (fav: boolean, id: number, parentName: string | undefined) => void
  parentName: string
}
// ========================

const MyWikiTopList: FC<ListType> = memo(({ items, handleFavoriteClick }) => (
  <div className="wikiListBlock__col">
    {items.items.map((item) => (
      <MyWikiTopListItem handleFavoriteClick={handleFavoriteClick} parentName={items.name} item={item} key={item.id} />
    ))}
  </div>
))

const MyWikiTopListItem: FC<ItemType> = ({ item, handleFavoriteClick, parentName }) => (
  <div className="wikiListBlockItem">
    <NavLink to={item.href} title={item.name} className="wikiListBlockItem__wrap">
      <div className="wikiListBlockItem__name">
        <div className="wikiListBlockItem__link">{item.name}</div>
      </div>
      <div className="wikiListBlockItem__dates">
        <div className="wikiListBlockItem__date wikiListBlockItem__date--public">
          <span className="wikiListBlockItem__dateCaption wikiListBlockItem__dateCaption--short">Публ.:</span>
          <span className="wikiListBlockItem__dateCaption wikiListBlockItem__dateCaption--full">Публикация:</span>
          {item.date}
        </div>
        {isNotEmpty(item.changeDate) && (
          <div className="wikiListBlockItem__date wikiListBlockItem__date--change">
            <span className="wikiListBlockItem__dateCaption wikiListBlockItem__dateCaption--short">Изм.:</span>
            <span className="wikiListBlockItem__dateCaption wikiListBlockItem__dateCaption--full">Изменено:</span>
            {item.changeDate}
          </div>
        )}
      </div>
    </NavLink>
    <Favorite
      parentName={parentName}
      isFavorite={item.favorite}
      handleFavoriteClick={handleFavoriteClick}
      dataId={item.id}
    />
  </div>
)
export default MyWikiTopList
