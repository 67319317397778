import React, { FC } from "react"
import classNames from "classnames"
import { TopMenuType } from "../../../../_types"

type PropsType = {
  item: TopMenuType
  event: (flag: React.MouseEvent, name: string) => void
  selectedItem: TopMenuType[]
}

const MyWikiTopMenu: FC<PropsType> = ({ selectedItem, item, event }) => (
  <li className="wikiListMenu__item">
    <button
      type="button"
      className={classNames({
        wikiListMenu__link: true,
        active: selectedItem[0] ? selectedItem[0].shortName === item.shortName : false
      })}
      onClick={(e: React.MouseEvent) => event(e, item.name)}
      title={item.name}
    >
      <span className="wikiListMenu__linkText wikiListMenu__linkText--short">{item.shortName}</span>
      <span className="wikiListMenu__linkText wikiListMenu__linkText--full">{item.name}</span>
    </button>
  </li>
)

export default MyWikiTopMenu
