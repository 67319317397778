import { InferActionsType } from "../store"
import { supportAPI, tasksAPI } from "../../api/api"
import {
  DateFilterType,
  ProjectsType,
  ReportsRequestType,
  SupportBurndownType,
  SupportInfoBlock,
  SupportProjectType,
  TaskType,
  UserType
} from "../../_types"

const SET_USERS_AND_TASKS = "SET_USERS_AND_TASKS"
const SUPPORT_PAGE = "SUPPORT_PAGE"

type TabType = {
  users: UserType[]
  tasks: TaskType[]
  projects: ProjectsType[]
}

export type SupportType = {
  tasksToControl: SupportInfoBlock
  tasksEmptyDeadline: SupportInfoBlock
  info: string
  dateFilter: DateFilterType[]
  projects: SupportProjectType[]
  supportBurndown: SupportBurndownType[]
}
export type InitialTasksStateType = {
  migration: TabType
  emptytag: TabType
  emptyplan: TabType
  emptydeadline: TabType
  autoclose: TabType
  fact: TabType
  support: {
    tasksToControl: SupportInfoBlock
    tasksEmptyDeadline: SupportInfoBlock
    info: string
    dateFilter: DateFilterType[]
    projects: SupportProjectType[]
    supportBurndown: SupportBurndownType[]
  }
}
export const initialState: InitialTasksStateType = {
  migration: {
    tasks: [],
    users: [],
    projects: []
  },
  emptytag: {
    tasks: [],
    users: [],
    projects: []
  },
  emptyplan: {
    tasks: [],
    users: [],
    projects: []
  },
  emptydeadline: {
    tasks: [],
    users: [],
    projects: []
  },
  autoclose: {
    tasks: [],
    users: [],
    projects: []
  },
  fact: {
    tasks: [],
    users: [],
    projects: []
  },
  support: {
    tasksToControl: {
      description: "",
      tasks: [],
      title: ""
    },
    tasksEmptyDeadline: {
      description: "",
      tasks: [],
      title: ""
    },
    info: "",
    dateFilter: [],
    projects: [],
    supportBurndown: []
  }
}
const tasksReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case "SET_USERS_AND_TASKS":
      return {
        ...state,
        [action.tab]: {
          users: action.users,
          tasks: action.tasks,
          projects: action.projects,
          wasLoaded: true
        }
      }
    case SUPPORT_PAGE:
      return {
        ...state,
        support: action.data
      }
    default:
      return state
  }
}
type ActionsType = InferActionsType<typeof actions>
export const actions = {
  setUsersAndTasks: (users: UserType[], tasks: TaskType[], projects: ProjectsType[], tab: string) =>
    ({
      type: SET_USERS_AND_TASKS,
      users,
      tasks,
      projects,
      tab
    } as const),
  setSupportMain: (data: SupportType) => ({ type: SUPPORT_PAGE, data } as const)
}

// ==========
export const getUsersAndTasks = (tab: string) => async (dispatch: any) => {
  const { tasks, users, projects } = await tasksAPI.getSelectedTab(tab)
  dispatch(actions.setUsersAndTasks(users, tasks, projects, tab))
}

/**
 * SUPPORT PAGE
 * */

export const getSupportThunk = (date?: string) => async (dispatch: any) => {
  const { result } = await supportAPI.getSupportPage(date)
  dispatch(actions.setSupportMain(result))
}

export const getReportThunk = (projectObj: ReportsRequestType) => async (dispatch: any) => {
  const result = await supportAPI.supportReport(projectObj)
  window.open(result.data.data.filePath)
}

export const getForecastThunk = (projectObj: ReportsRequestType) => async (dispatch: any) => {
  const result = await supportAPI.supportForecast(projectObj)
  window.open(result.data.data.filePath)
}

export default tasksReducer
