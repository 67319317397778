import React, { FC } from "react"
import Parser from "html-react-parser"
import { memo } from "react"

type SupportInfoType = {
  info: string
}

export const SupportInfo: FC<SupportInfoType> = memo(({ info }) => {
  return (
    <div className="tasksContent">
      <div className="wrapper">
        <h2 className="title tasksContent__title">
          Поддержка <br />
          клиентов
        </h2>
        {info && Parser(info)}
      </div>
    </div>
  )
})
