import React, { useLayoutEffect } from "react"
import { Content } from "./components/Content"
import { useTasksPage } from "../../hooks/Tasks/useTasksPage"
import { B24Container } from "../../components/B24Container/B24Container"
import { SupportPage } from "../SupportPage/Support"
import FactLoadCosts from "../FactLoadCosts/FactLoadCosts"

export const Tasks = () => {
  const { tasks, tab, users, isSupportPage, projects, fetchData, load, isFactPage } = useTasksPage()
  useLayoutEffect(() => {
    fetchData()
  }, [tab])
  return (
    <B24Container load={load} tab={tab}>
      {!isSupportPage && !isFactPage && (
        <Content
          selected={tab}
          users={users}
          /* @ts-ignore */
          projects={projects}
          tasks={tasks}
        />
      )}
      {isSupportPage && <SupportPage />}
      {isFactPage && <FactLoadCosts />}
    </B24Container>
  )
}
