import { useEffect } from "react"
import { ProjectsType, TaskType, UserType } from "../../_types"
import { useTasks } from "./useTasks"
import { useUsers } from "./useUsers"
import { useSelectFilter } from "./useSelectFilter"
import { useTitle } from "../useTitle"
import { TabTitleType } from "./useTabTitle"
import { useDefinePage } from "./useDefinePage"
import { filterByStatus } from "../../helpers/filterByStatus"
import { useProjects } from "./useProjects"

export const DEFAULT_FILTER_VALUE = -1
/* eslint-disable */
export const useContent = (tasks: TaskType[], users: UserType[], projects: ProjectsType[], title: TabTitleType) => {
  const { isAutoClosePage, isMigrationPage } = useDefinePage(title)
  const { selectedTasks, setTasks } = useTasks(tasks)
  const { getTotalCount, selectedUser, setUser, setUserOnUserClick } = useUsers(users)
  const { selectedProject, setProject, getProjectsTotalCount, setProjectOnProjectClick } = useProjects(projects)
  const { filter } = useSelectFilter(tasks, setTasks, isAutoClosePage, isMigrationPage)
  const { selectedFilter } = filter

  const onUserClick = (id: number) => {
    if (id === DEFAULT_FILTER_VALUE) {
      if (selectedFilter !== DEFAULT_FILTER_VALUE) {
        setTasks(tasks.filter((task) => filterByStatus(task, selectedFilter)))
      } else {
        setTasks(tasks)
      }
      setUser()
      return
    }
    const data = {
      id,
      setTasks,
      tasks,
      isAutoClosePage,
      selectedFilter
    }
    setUserOnUserClick(data)
  }

  const onProjectClick = (id: number) => {
    if (id === DEFAULT_FILTER_VALUE) {
      if (selectedFilter !== DEFAULT_FILTER_VALUE) {
        setTasks(tasks.filter((task) => filterByStatus(task, selectedFilter)))
      } else {
        setTasks(tasks)
      }
      setProject()
      return
    }
    const data = {
      id,
      tasks,
      isMigrationPage,
      selectedFilter,
      setTasks
    }
    setProjectOnProjectClick(data)
  }

  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle(title.label)
  }, [title])
  return {
    getTotalCount,
    onUserClick,
    getProjectsTotalCount,
    onProjectClick,
    selectedProject,
    selectedTasks,
    selectedUser,
    filter
  }
}
