import React, { FC } from "react"
import classNames from "classnames"
import { NavLink } from "react-router-dom"
import { MenuType } from "../../redux/reducers/menuRudecer"
import { useHeader } from "../../hooks/useHeader"

const MenuItem: FC<{ item: MenuType; id: number }> = ({ item }) => (
  <li className="header__menuItem">
    <a
      href={item.href}
      className={classNames({
        header__menuLink: true
      })}
      rel="noopener noreferrer"
      target="_blank"
    >
      {item.name}
    </a>
  </li>
)
const MenuReactItem: FC<{ item: MenuType }> = ({ item }) => {
  const { onLinkClickHandler } = useHeader()
  return (
    <li className="header__menuItem">
      <NavLink
        to={item.href}
        className={classNames({
          header__menuLink: true
        })}
        activeClassName="current"
        onClick={() => onLinkClickHandler()}
      >
        {item.name}
      </NavLink>
    </li>
  )
}
const Menu: FC<{ items: MenuType[] }> = ({ items }) => (
  <div className="header__menu">
    <ul className="header__menuList">
      {items &&
        items.map((item: MenuType, i: number) => {
          if (item.react) return <MenuReactItem key={i} item={item} />
          if (!item.react) return <MenuItem item={item} id={i} key={i} />
        })}
    </ul>
  </div>
)

export default Menu
