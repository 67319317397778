import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { getUserInfoThunk } from "../../redux/reducers/authReducer"
import { AppStateType } from "../../redux/store"

export const useApp = () => {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(true)
  const { name } = useSelector((state: AppStateType) => state.authPage.user)
  const isNotAuth: boolean = name.length === 0 && !load
  const fetchData = async () => {
    if (name === "") {
      try {
        await setLoad(true)
        await dispatch(getUserInfoThunk())
      } finally {
        await setLoad(false)
      }
    }
  }
  return { fetchData, load, isNotAuth }
}
