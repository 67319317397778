import { hotToast } from "../helpers/hotToast"
import { AccessesInfo } from "../_types"
import { SHORT_NAME } from "../api/api"

export const useCopyRowClick = (item?: AccessesInfo) => {
  const copyRowClick = async (e: any) => {
    e.preventDefault()
    window.getSelection()!.removeAllRanges()
    let copyText
    if (item) {
      copyText = generateAccess(item).trim()
      return navigator.clipboard.writeText(copyText).then(() => hotToast("Скопировано!"))
    }
    if (e.target.closest(".js-copy-row" && !item)) {
      copyText = e.target.closest(".js-copy-row")
    }
    if (e.target.classList.contains("js-copy-element")) {
      copyText = e.target
    }
    copyText && navigator.clipboard.writeText(copyText?.innerText.trim()).then(() => hotToast("Скопировано!"))
  }

  return { copyRowClick }
}
const generateAccess = (item: AccessesInfo) => {
  const files = item.files.map((file) => {
    return `
${file.name}: ${SHORT_NAME}${file.path}`
  })
  const mainInfo = `
${item.type}
Хост: ${item.host}${item.port ? `:${item.port}` : ""}
Логин: ${item.login}
Пароль: ${item.password}`

  if (!files.length && !item.notice) {
    return mainInfo
  }

  if (!item.notice) {
    return `
${mainInfo}

${item.files.length ? "Файлы:" : ""}${item.files.length ? files : ""}`
  }
  return `
${mainInfo}
${
  item.notice &&
  `
Примечание:`
}
${item.notice && item.notice}

${item.files.length ? "Файлы:" : ""}${item.files.length ? files : ""}
`
}
