import { getTasksFact } from "../../redux/reducers/factSpentReducer"
import { useDispatch } from "react-redux"

export const useGetTasks = () => {
  const dispatch = useDispatch()
  const getTasks = async (usersArr: number[], date?: Date) => {
    await dispatch(getTasksFact(usersArr, date))
  }
  return { getTasks }
}
