import React, { FC } from "react"
import { SupportCalendarMainItem } from "./SupportCalendarMainItem"
import { SupportProjectType } from "../../_types"

type SupportCalendarMainType = {
  projects: SupportProjectType[]
  hoursMinutesView: boolean
  currentDate: string
}

export const SupportCalendarMain: FC<SupportCalendarMainType> = ({ projects, hoursMinutesView, currentDate }) => {
  return (
    <div className="wrapper table-wrapper">
      <table className="ui-table calendarTable">
        <thead className="sticky-head">
          <tr>
            <th>Клиент</th>

            <th className="ui-table-underline-blue">Пакет</th>
            <th className="ui-table-underline-blue">Отгружено</th>
            <th className="bg-gray ui-table-round-left">Запланировано</th>
            <th className="bg-gray">Осталось</th>
            <th className="bg-gray">Сверх пакета</th>
            <th className="bg-gray ui-table-round-right">На контроле</th>
          </tr>
        </thead>
        <tbody>
          {projects?.map((project) => {
            return (
              <SupportCalendarMainItem
                currentDate={currentDate}
                hoursMinutesView={hoursMinutesView}
                key={project.name}
                project={project}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
