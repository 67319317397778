import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { getSelectedSectionThunk } from "../../../redux/reducers/wikiReducer"
import { useTitle } from "../../useTitle"
import { AppStateType } from "../../../redux/store"
import { checkHttpError } from "../../../helpers/checkHttpError"

export const useWikiArticles = () => {
  const { categoryName } = useSelector((state: AppStateType) => state.wiki.selectedSection)
  const [load, setLoad] = useState(true)
  const { id } = useParams<{ id: string }>()
  const { setTitle } = useTitle()
  const dispatch = useDispatch()

  const fetchData = async () => {
    try {
      await setLoad(true)
      await dispatch(getSelectedSectionThunk(id))
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    } finally {
      await setLoad(false)
    }
  }
  if (!load) {
    setTitle(categoryName)
  }

  return { fetchData, load, id, categoryName }
}
