import React, { FC } from "react"
import classNames from "classnames"
import Logo from "./Logo"
import User from "./User"
import Icon from "./Icon"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
import { useHeader } from "../../hooks/useHeader"
import { AUTH_PAGE } from "../../api/constants"

type PropsType = {
  isNotAuth: boolean
}
const Header: FC<PropsType> = ({ isNotAuth }) => {
  const { pathname, onLogoClickHandler, onClickHandler, menu } = useHeader()

  if (pathname === AUTH_PAGE) {
    return (
      <div className="header__wrapper">
        <div
          className={classNames({
            "wrapper header__inner": true,
            header__isAuth: pathname === AUTH_PAGE
          })}
        >
          <Logo onLogoClickHandler={onLogoClickHandler} />
          {!isNotAuth && <User />}
        </div>
      </div>
    )
  }
  return (
    <div className="header">
      <div className="header__wrapper header__wrapper--secondary">
        <div className="wrapper">
          <div className="header__left">
            {isNotAuth ? undefined : <Icon clickEvent={() => onClickHandler()} />}
            <Logo onLogoClickHandler={onLogoClickHandler} />
          </div>
          <div className="header__middle js-side-wrapper">
            <MobileMenu />
            <div className="header__close">
              <button
                type="button"
                className="header__closeLink js-close-side"
                onClick={() => onClickHandler()}
                title="Закрыть"
              >
                <svg width="37" height="11" viewBox="0 0 37 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 10L18.5 1.5L35.5 10" stroke="#E7E7F4" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>
            </div>
          </div>
          <div className="header__right">{!isNotAuth && <User />}</div>
        </div>
      </div>
      <div className="header__bottom js-side-wrapper">
        <div className="wrapper">
          <Menu items={menu} />
          <div className="header__close">
            <button
              type="button"
              className="header__closeLink js-close-side"
              onClick={() => onClickHandler()}
              title="Закрыть"
            >
              <svg width="37" height="11" viewBox="0 0 37 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L18.5 1.5L35.5 10" stroke="#E7E7F4" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header
