import React, { FC, useEffect, useState } from "react"
import { SelectedAccessItemType } from "../../../../_types"
import { useWinSCP } from "../../../../hooks/useWinSCP"
import { useAccessDetailContext } from "../context/useAccessDetailContext"
import { useCopyRowClick } from "../../../../hooks/useCopyRowClick"
import AccessDetailItemBurger from "./AccessDetailItemBurger"
import ViewIcon from "./Icons/ViewIcon"
import RequestAccessIcon from "./Icons/RequestAccessIcon"
import classNames from "classnames"
import { accessesAPI } from "../../../../api/api"
import { useSelector } from "react-redux"
import { AppStateType } from "../../../../redux/store"
import { hotToast } from "../../../../helpers/hotToast"
import { Arr } from "../../../../components/ScrollToTopContainer/ArrowTop"

export type PropsType = {
  item: SelectedAccessItemType
}
const AccessDetailListItem: FC<PropsType> = ({ item }) => {
  const {
    selectedAccessItem: {
      data: { id }
    }
  } = useSelector((state: AppStateType) => state.accesses)
  const [isLargeNotice, setLargeNotice] = useState(false)
  const [isOpenFullHeight, setOpenFullHeight] = useState(false)
  const { openWinSCP } = useWinSCP()
  const { copyRowClick } = useCopyRowClick()
  const { openEditModal } = useAccessDetailContext()
  const toggleFullHeight = () => {
    setOpenFullHeight((prevState) => !prevState)
  }
  useEffect(() => {
    if (item.notice?.length > 200) {
      setLargeNotice(true)
    }
  }, [item])
  const fullString = item.notice?.replace(/\n/g, "<br/>")
  const shortString = item.notice?.substring(0, 200).replace(/\n/g, "<br/>")
  const renderSSHItem = (element: SelectedAccessItemType) => (
    <div
      onClick={() => openWinSCP(element.login, element.password, element.host, element.port)}
      className="accessDetail__itemInner ssh"
      role="button"
    >
      {element.type}
    </div>
  )

  const requestAccess = async () => {
    if (!item.requested)
      await accessesAPI
        .requestAccess({ accessId: item.id, contragentID: id })
        .then(() => hotToast("Доступ запрошен", "🤙"))
    else hotToast("Доступ уже был запрошен. Ждите")
  }
  return (
    <div
      className={classNames({
        "accessDetail__item js-copy-row": true,
        accessDetail__largeNotice: isLargeNotice,
        accessDetail__fullHeight: isOpenFullHeight,
        accessDenied: !item.options.access.availability,
        liked: item.like
      })}
      data-id={item.id}
    >
      <div className="accessDetail__itemCol accessDetail__itemCol--type">
        <span className="hideHead">
          Вид <span>доступа</span>
        </span>
        {item.options.access.availability &&
          (item.type === "SSH" ? (
            <div className="d-sm-flex">
              <AccessDetailItemBurger item={item} />
              <div className="accessDetail__itemInner">{renderSSHItem(item)}</div>
            </div>
          ) : (
            <div className="d-sm-flex">
              <AccessDetailItemBurger item={item} />
              <div className="accessDetail__itemInner">{item.type}</div>
            </div>
          ))}
      </div>
      <div className="accessDetail__itemCol accessDetail__itemCol--host">
        <span className="hideHead">Хост</span>
        <div
          className="accessDetail__itemInner js-copy-element"
          role="button"
          tabIndex={0}
          onClick={(e) => copyRowClick(e)}
        >
          {item.host}
          {item.port && `:${item.port}`}
        </div>
      </div>

      <div className="accessDetail__itemCol accessDetail__itemCol--login">
        <span className="hideHead">Логин</span>
        <div
          className="accessDetail__itemInner js-copy-element"
          role="button"
          tabIndex={0}
          onClick={(e) => copyRowClick(e)}
        >
          {item.login}
        </div>
      </div>
      <div className="accessDetail__itemCol accessDetail__itemCol--pass">
        <span className="hideHead">Пароль</span>
        <div
          className="accessDetail__itemInner js-copy-element"
          role="button"
          tabIndex={0}
          onClick={(e) => copyRowClick(e)}
        >
          {item.password}
          <div className="hideText" data-text={item.password} />
        </div>
      </div>
      <div className="accessDetail__itemCol accessDetail__itemCol--notice">
        <span className="hideHead">Примечание</span>
        <div className={classNames("accessDetail__itemInner", "wrapper__comment")}>
          <div className={"accessDetail__notice"}>
            {isLargeNotice && isOpenFullHeight ? (
              <div dangerouslySetInnerHTML={{ __html: fullString }}></div>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: shortString }}
                className={isLargeNotice ? "large__notice" : ""}
              ></div>
            )}
          </div>
          {isLargeNotice && (
            <div
              onClick={toggleFullHeight}
              className={classNames("show__notice", isOpenFullHeight && "arrow__noticeUp")}
            >
              {isOpenFullHeight ? "Скрыть" : "Ещё"}
              <Arr classNames={""} />
            </div>
          )}
        </div>
      </div>
      <div className="accessDetail__itemCol accessDetail__itemCol--actions">
        <div className="accessDetail__itemInner">
          {item.options.access.availability && (
            <button className="accessDetail__icon editIcon" type="button" onClick={() => openEditModal(item.id)}>
              <ViewIcon />
            </button>
          )}
          {!item.options.access.availability && (
            <button className="accessDetail__icon requestIcon tooltip" type="button" onClick={requestAccess}>
              <RequestAccessIcon />
              <span className="tooltip__text">Запросить доступ</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccessDetailListItem
