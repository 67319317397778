import React from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { AppStateType } from "../../redux/store"
import { useTitle } from "../../hooks/useTitle"
import { AuthSVG } from "./components/AuthSVG"

const Authorization = () => {
  const { name } = useSelector((state: AppStateType) => state.authPage.user)
  const { userLink } = useSelector((state: AppStateType) => state.authPage)
  const { setTitle } = useTitle()
  setTitle("Авторизация/Регистрация")

  if (name) {
    return <Redirect to="/" />
  }
  return (
    <div className="login">
      <div className="login__wrap">
        <AuthSVG />
        <div className="login__greeting">
          <div className="login__title title">Авторизация</div>
          <div className="login__text">Отличный день, чтобы потрудиться!</div>
          <a href={userLink || "#link"} className="login__link" target="_self">
            <button type="button" className="login__button">
              <div className="login__buttonIcon">
                <svg width="37" height="13" viewBox="0 0 37 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.5 7H35.5M35.5 7L30.5 1.5M35.5 7L30.5 12"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="login__buttonText">Войти</div>
            </button>
          </a>
          <div className="login__agreement">
            Нажимая эту кнопку, я подтверждаю, что&nbsp;буду трудиться на благо Империи
          </div>
        </div>
      </div>
    </div>
  )
}

export default Authorization
