import React, { FC } from "react"
import { useDispatch } from "react-redux"
import VoitingItem from "./VoitingItem"
import { wikiAPI } from "../../../../api/api"
import { getSelectedItemThunk } from "../../../../redux/reducers/wikiReducer"
import { checkHttpError } from "../../../../helpers/checkHttpError"

export type VotingItemsType = {
  icon: string
  isVoted: boolean
  text: string
  users: string[]
  voteID: number | null
}
export type VotingPropsType = {
  items: VotingItemsType[]
  articleID: number
}
const Voiting: FC<VotingPropsType> = ({ items, articleID }) => {
  const dispatch = useDispatch()
  const setLikeVote = async (id: number) => {
    try {
      await wikiAPI.setVote(id, true)
      dispatch(getSelectedItemThunk(id.toString()))
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    }
  }
  const setDisLikeVote = async (id: number) => {
    await wikiAPI.setVote(id, false)
    dispatch(getSelectedItemThunk(id.toString()))
  }

  /* let isVoted = 0
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].isVoted) {
      isVoted += 1
    }
  } */
  return (
    <div className="post__rating">
      {items.map((item) => {
        if (!item.text) return null
        return (
          <VoitingItem
            item={item}
            setVote={item.voteID === 1 ? (id: number) => setLikeVote(id) : (id: number) => setDisLikeVote(id)}
            articleID={articleID}
            key={item.voteID}
          />
        )
      })}
    </div>
  )
}

export default Voiting
