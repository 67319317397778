import React from "react"

const BurgerIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="13" height="2" fill="#3262E0" />
      <rect y="5" width="13" height="2" fill="#3262E0" />
      <rect y="10" width="13" height="2" fill="#3262E0" />
    </svg>
  )
}

export default BurgerIcon
