import React from "react"
import Loader from "react-loader-spinner"
import classNames from "classnames"
import Cookies from "js-cookie"

const FallBack = () => (
  <div
    className={classNames({
      "loader-container": true,
      dark: JSON.parse(Cookies.get("darktheme") ?? "false")
    })}
  >
    <div className="loader-container__inner">
      <Loader type="ThreeDots" color="#3262E0" height={50} width={50} />
    </div>
  </div>
)

export default FallBack
