import React, { FC } from "react"
import classNames from "classnames"
import { NavLink } from "react-router-dom"
import plural from "plural-ru"
import { CategoryType } from "../../../../_types"

type ItemType = {
  item: CategoryType
  categoryName: string
}
type ListType = {
  category: CategoryType[]
}
const MyWikiBottomList: FC<ListType> = ({ category }) => (
  <div className="wikiCategoryList">
    {category.map((item, index) => (
      <MyWikiBottomItem categoryName={item.name} item={item} key={index} />
    ))}
  </div>
)
const MyWikiBottomItem: FC<ItemType> = ({ item }) => (
  <div
    className={classNames({
      wikiCategoryItem: true,
      "wikiCategoryItem--bordered": item.extranet,
      "wikiCategoryItem--highlighted": item.highlighted
    })}
  >
    <NavLink to={item.href} className="wikiCategoryItem__link">
      <div className="wikiCategoryItem__name">{item.name}</div>
      <div className="wikiCategoryItem__count">
        {`${item.count} ${plural(item.count, "статья", "статьи", "статей")}`}{" "}
      </div>
    </NavLink>
  </div>
)

export default MyWikiBottomList
