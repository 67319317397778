import React from "react"
import { Toaster } from "react-hot-toast"
import css from "./TopScrollContainer.module.css"
import { useScrollHeight } from "../../hooks/useScrollHeight"
import { Arr } from "./ArrowTop"
import Header from "../Header/Header"

export const TopScrollContainer = ({ children, isNotAuth }) => {
  const { scrollHeight, scrollToTop } = useScrollHeight()

  return (
    <>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <Header isNotAuth={isNotAuth} />
      {children}
      <div onClick={() => scrollToTop()} className={scrollHeight > 300 ? css.containerUp : css.hide}>
        <Arr classNames={css.arrowUp} />
      </div>
    </>
  )
}
