import React, { FC } from "react"
import { NavigationItemsType, NavigationType } from "../../../../_types"
/**
 * Отключаю линтер, так как он требует href у ссылки,
 * а эта ссылка работает в роли якоря
 * */
/*eslint-disable*/
type ChildItemPropsType = {
  item: NavigationItemsType
  parent: string
  event: (e: any) => void
}
const StructureChildItem: FC<ChildItemPropsType> = ({ item, event }) => (
  <li className="post__structureChildrenItem">
    <a
      onClick={(e) => event(e)}
      type="button"
      className="post__structureChildrenLink"
      title={item.name}
      data-src={item.href}
    >
      <div dangerouslySetInnerHTML={{ __html: item.name }} />
    </a>
  </li>
)

type ItemPropsType = {
  item: NavigationType
  parent: string
  event: (e: any) => void
}
const StructureItem: FC<ItemPropsType> = ({ item, parent, event }) => {
  const hasChildren = item.items.length > 0
  return (
    <li className="post__structureItem">
      {item.name && (
        <a onClick={(e) => event(e)} className="post__structureParent" type="button" data-src={item.href}>
          <div dangerouslySetInnerHTML={{ __html: item.name }} />
        </a>
      )}
      {hasChildren && (
        <ol className="post__structureChildren">
          {item.items.map((elem, key) => (
            <StructureChildItem item={elem} parent={parent} event={event} key={key} />
          ))}
        </ol>
      )}
    </li>
  )
}

export default StructureItem
