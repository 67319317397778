import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { addAccessThunk, saveAccessEditsThunk } from "../../../redux/reducers/accessesReducer"
import { AppStateType } from "../../../redux/store"

export type AddOrEditFormType = {
  login: string
  password: string
  type: string
  host: string
  notice?: string | ""
  port?: string | ""
  database?: string | ""
  selected?: boolean
}

export const useAccessForm = (searchValue: string | undefined, isFavorite: boolean) => {
  const dispatch = useDispatch()
  const params = useParams<{ id: string }>()
  const { id } = useSelector((state: AppStateType) => state.accesses.selectedAccess)
  const defaultState = {
    login: "",
    password: "",
    type: "",
    database: "",
    port: "",
    notice: "",
    host: "",
    selected: false
  }
  const [form, setForm] = useState<AddOrEditFormType>(defaultState)

  const onChangeHandler = (event: any) => {
    if (event.target !== undefined) {
      setForm({ ...form, [event.target.name]: event.target.value })
    } else {
      setForm({ ...form, type: event.label, selected: true })
    }
  }
  const saveAccessEdits = (accessID: string, delServerfiles?: number[]) => {
    dispatch(saveAccessEditsThunk(form, accessID, params.id, searchValue, delServerfiles, isFavorite))
  }
  const addAccess = (files: any) => {
    dispatch(addAccessThunk(form, params.id, searchValue, files ?? [], isFavorite))
    setForm(defaultState)
  }

  return { onChangeHandler, addAccess, saveAccessEdits, ...form, id, setForm }
}
