import React, { FC } from "react"
import { TaskFactType } from "../../_types"
import FactUserCard from "./FactUserCard"
import { timeFromMins } from "../../helpers/timeFromMins"

type FactLoadMainType = {
  tasks: TaskFactType
  setCheckedUsers: (numbers: number[]) => void
  checkedUsers: number[]
  users: any
}

const FactLoadMain: FC<FactLoadMainType> = ({ tasks, setCheckedUsers, checkedUsers, users }) => {
  const formatedTime = timeFromMins(tasks.totalTime)
  if (!tasks.users?.length)
    return (
      <div className="factLoadCosts__content">
        <p className="empty">Не выбран ни один сотрудник</p>
      </div>
    )
  return (
    <div className="factLoadCosts__content">
      <div className="factLoadCosts__content-allHours">
        Часы выбранных сотрудников <span>{tasks.date.toLocaleDateString()}: </span>
        <span>{formatedTime} ч.</span>
      </div>
      <div className="factLoadCosts__cardsWrapper">
        {tasks.users.map((user) => (
          <FactUserCard
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            date={tasks.date}
            user={user}
            key={Number(user.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default FactLoadMain
