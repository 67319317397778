import React from "react"
import { useSelector } from "react-redux"
import MyWikiBottomList from "./MyWikiBottomList"
import { AppStateType } from "../../../../redux/store"
import { CategoryType } from "../../../../_types"

const MyWikiBottom = () => {
  const sections: CategoryType[][] = useSelector((state: AppStateType) => state.wiki.sections)
  const renderItems = () => sections.map((section, index) => <MyWikiBottomList category={section} key={index} />)
  return (
    <div className="wrapper">
      <div className="wikiCategory">
        <h2 className="title">Категории статей</h2>
        {renderItems()}
      </div>
    </div>
  )
}

export default MyWikiBottom
