export const EmptyTaskListIcon = () => (
  <svg
    style={{ marginRight: 15 }}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-49 141 403 483"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          style={{ fill: "#B6C2E2" }}
          d="M283.523,221.041h-39.706v-17.763c0-5.747-6.269-8.359-12.016-8.359h-30.824
c-7.314-20.898-25.6-31.347-46.498-31.347c-20.668-0.777-39.467,11.896-46.498,31.347H77.678c-5.747,0-11.494,2.612-11.494,8.359
v17.763H26.478c-23.53,0.251-42.78,18.813-43.886,42.318v299.363c0,22.988,20.898,39.706,43.886,39.706h257.045
c22.988,0,43.886-16.718,43.886-39.706V263.359C326.302,239.854,307.053,221.292,283.523,221.041z M87.082,215.816h28.735
c5.016-0.612,9.045-4.428,9.927-9.404c3.094-13.474,14.915-23.146,28.735-23.51c13.692,0.415,25.335,10.118,28.212,23.51
c0.937,5.148,5.232,9.013,10.449,9.404h29.78v41.796H87.082V215.816z M306.51,562.722c0,11.494-11.494,18.808-22.988,18.808
H26.478c-11.494,0-22.988-7.314-22.988-18.808V263.359c1.066-11.964,10.978-21.201,22.988-21.42h39.706v26.645
c0.552,5.854,5.622,10.233,11.494,9.927H231.8c5.98,0.327,11.209-3.992,12.016-9.927v-26.645h39.706
c12.009,0.22,21.922,9.456,22.988,21.42V562.722z"
        />
      </g>
    </g>
    <path
      style={{ fill: "#B6C2E2" }}
      d="M169.142,421.5l66.429-66.429c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
L155,407.357l-66.429-66.429c-3.905-3.905-10.237-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l66.429,66.429l-66.429,66.429
c-3.905,3.905-3.905,10.237,0,14.143C76.381,504.023,78.941,505,81.5,505s5.119-0.977,7.071-2.929L155,435.643l66.429,66.429
c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143L169.142,421.5z"
    />
  </svg>
)
