import React, { FC, useState } from "react"
import classNames from "classnames"
import Select from "react-select"
import { SelectValueType } from "../../../_types"

type TextFiledType = {
  textarea?: boolean
  select?: boolean
  defaultValue?: string
  type?: string
  name?: string
  options?: SelectValueType[]
  selected?: boolean
  typeSelect?: string
  placeholder?: string
  event?: (e: any) => void
}

const TextFiled: FC<TextFiledType> = ({
  type = "text",
  placeholder,
  defaultValue,
  event,
  name = "name",
  children,
  textarea = false,
  select = false,
  options = [],
  typeSelect = undefined,
  selected
}) => {
  const [activePlaceholder, setActivePlaceholder] = useState(select ? typeSelect : Boolean(defaultValue))
  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (!value) setActivePlaceholder(true)
  }
  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (!value) setActivePlaceholder(false)
  }
  return (
    <div
      className={classNames({
        inputHolder: true,
        "-activePlaceholder": activePlaceholder || selected
      })}
    >
      {textarea ? (
        <>
          <textarea
            name={name}
            className="inputHolder__input inputHolder__input--textarea"
            defaultValue={defaultValue}
            onChange={event}
            onFocus={(e) => onFocusHandler(e)}
            onBlur={(e) => onBlurHandler(e)}
          />
          <div className="inputHolder__placeholder">{placeholder}</div>
        </>
      ) : select ? (
        <>
          <Select
            name={name}
            options={options}
            defaultValue={{ label: typeSelect }}
            isSearchable={false}
            className="styledSelect"
            classNamePrefix="styledSelect"
            onChange={event}
          />
          <div className="inputHolder__placeholder">{placeholder}</div>
        </>
      ) : (
        <>
          <input
            type={type}
            name={name}
            className="inputHolder__input"
            defaultValue={defaultValue}
            onChange={event}
            onFocus={(e) => onFocusHandler(e)}
            onBlur={(e) => onBlurHandler(e)}
          />
          <div className="inputHolder__placeholder">{placeholder}</div>
        </>
      )}
    </div>
  )
}

export default TextFiled
