import React, { FC, useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import ArticlesList from "./ArticlesList"
import { slideToggle } from "../../../components/modules/util"
import { SelectedSectionItemsType } from "../../../_types"

const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
  const parent = e.currentTarget.closest(".js-article-elem")
  const list = parent!.querySelector(".js-articles-list")
  slideToggle(list, 500)

  if (parent!.classList.contains("is-open")) {
    parent!.classList.remove("is-open")
  } else {
    parent!.classList.add("is-open")
  }
}
type PropsType = {
  items: SelectedSectionItemsType[]
}
const Articles: FC<PropsType> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false)
  items.sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  useEffect(() => {
    if (items.length === 1) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [items])

  return (
    <div className="articles__block">
      {/*  {itemsEng.length > 0 && (
          <CSSTransition
            in={showList}
            timeout={400}
            classNames="articles__catalog"
            unmountOnExit
            appear
          >
            <div className="articles__catalog articles__catalog--eng">
              {itemsEng.map((items) => (
                <ArticlesList items={items} event={handleClick} key={items.name + Math.random()} />
              ))}
            </div>
          </CSSTransition>
        )} */}
      {items.length > 0 && (
        <CSSTransition in={true} timeout={400} classNames="articles__catalog" unmountOnExit appear>
          <div className="articles__catalog articles__catalog--rus">
            {items.map((item, index) => (
              <ArticlesList isOpen={isOpen} item={item} event={(e) => handleClick(e)} key={index} />
            ))}
          </div>
        </CSSTransition>
      )}
    </div>
  )
}

export default Articles
