import Menu from "../other/Menu"
import TasksMenu from "../other/TasksMenu"
import React, { FC } from "react"
import FallBack from "../other/FallBack"

interface Ib24Container {
  load: boolean
  tab: string
}

export const B24Container: FC<Ib24Container> = ({ children, load, tab }) => {
  return (
    <div className="tasks">
      <Menu tab={tab} items={TasksMenu} />
      {load ? <FallBack /> : children}
    </div>
  )
}
