import React, { FC } from "react" //, useEffect, useState
import ChristSvg from "../../components/svg/ChristSvg"
import classNames from "classnames"
import { UserFactType } from "../../_types"

type FactLoadTabType = {
  allReset: boolean
  user: UserFactType
  defStateAllTabs: () => void
  getTasks: (checkedUsers: number[]) => void
  setCheckedUsers: (numbers: number[]) => void
  checkedUsers: number[]
  showMobileView: boolean
  isMobile: boolean
}

const FactLoadTab: FC<FactLoadTabType> = ({
  allReset,
  user,
  defStateAllTabs,
  getTasks,
  checkedUsers,
  setCheckedUsers,
  showMobileView,
  isMobile
}) => {
  const activeHandlerTab = () => {
    if (checkedUsers.includes(user.id)) return unActiveHandlerTab()
    setCheckedUsers([...checkedUsers, user.id])
    !isMobile && getTasks([...checkedUsers, user.id])
  }

  const unActiveHandlerTab = (e?: any) => {
    const filtered = [...checkedUsers.filter((item) => item !== Number(user.id))]
    setCheckedUsers(filtered)
    !isMobile && getTasks(filtered)
  }

  return (
    <button
      className={classNames({
        "factLoadCosts-tab": true,
        active: checkedUsers.includes(user.id)
      })}
      onClick={activeHandlerTab}
    >
      <span />
      {`${user.lastName} ${user.name}`}
      <div className="factLoadCosts-tab__close" onClick={(e) => unActiveHandlerTab(e)}>
        <ChristSvg />
      </div>
    </button>
  )
}

export default FactLoadTab
