import React, { FC } from "react"

type PropsType = {
  info: {
    datePublic: string
    userPublic: string
    dateChange: string
    userChange: string
  }
}
const PostInfo: FC<PropsType> = ({ info }) => (
  <div className="post__info">
    <div className="post__infoItem">
      <div className="post__infoDate">
        <span className="post__infoDateText post__infoDateText--mob">Публ.:&nbsp;</span>
        <span className="post__infoDateText post__infoDateText--desk">Публикация:&nbsp;</span>
        {info.datePublic}
      </div>
      <div className="post__infoUser">{info.userPublic}</div>
    </div>
    <div className="post__infoItem">
      <div className="post__infoDate">
        <span className="post__infoDateText post__infoDateText--mob">Изм.:&nbsp;</span>
        <span className="post__infoDateText post__infoDateText--desk">Изменено:&nbsp;</span>
        {info.dateChange}
      </div>
      <div className="post__infoUser">{info.userChange}</div>
    </div>
  </div>
)

export default PostInfo
