import React, { useState } from "react"
import RenderModal from "../../../../../components/other/RenderModal"
import { useAccessForm } from "../../../../../hooks/Accesses/AccessDetail/useAccessForm"
import { useSelectValues } from "../../../../../hooks/useSelectValues"
import { useAccessDetailContext } from "../../context/useAccessDetailContext"
import { FileAdd } from "../../../../../components/other/FileAdd"
import TextFiled from "../../../../../local/assets/js/TextFiled"

const AccessDetailFormAdd = () => {
  const { setAddModalIsOpen, closeModals, addModalIsOpen, searchValue } = useAccessDetailContext()
  const { favorite } = useAccessDetailContext()
  const { onChangeHandler, addAccess, type, host, login, password, selected } = useAccessForm(searchValue, favorite)
  const { values } = useSelectValues()

  const [files, setFiles] = useState()

  return (
    <div className="accessDetail__add">
      <button type="button" className="accessDetail__addLink" onClick={() => setAddModalIsOpen(true)}>
        <span>
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="6" width="13" height="1" rx="0.5" fill="white" />
            <rect y="6" width="13" height="1" rx="0.5" fill="white" />
            <rect y="6" width="13" height="1" rx="0.5" fill="white" />
            <rect x="7" width="13" height="0.999999" rx="0.5" transform="rotate(90 7 0)" fill="white" />
            <rect x="7" width="13" height="0.999999" rx="0.5" transform="rotate(90 7 0)" fill="white" />
            <rect x="7" width="13" height="0.999999" rx="0.5" transform="rotate(90 7 0)" fill="white" />
          </svg>
        </span>
        <p className="text-small">Добавить доступ</p>
        <p className="text-big"> Добавить новый доступ</p>
      </button>
      <RenderModal isOpen={addModalIsOpen} closeModals={closeModals}>
        <div className="addNewAccess">
          <div className="addNewAccess__form formBlock" data-form="add">
            <div className="formBlock__title title">Создать новый доступ</div>
            <div className="formBlock__content">
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled name={"host"} defaultValue={host} placeholder={"Хост"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled
                    select
                    name={"type"}
                    typeSelect={type}
                    selected={selected}
                    options={values}
                    placeholder={"Вид доступа"}
                    event={onChangeHandler}
                  />
                </div>
              </div>
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled name={"login"} placeholder={"Логин"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled name={"password"} placeholder={"Пароль"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled name={"database"} placeholder={"База данных"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row">
                <div className="formBlock__rowWrap">
                  <TextFiled name={"port"} placeholder={"Порт"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row formBlock__row--file">
                <FileAdd setFiles={setFiles} />
              </div>
              <div className="formBlock__row formBlock__row--textarea">
                <div className="formBlock__rowWrap">
                  <TextFiled textarea name={"notice"} placeholder={"Примечания"} event={onChangeHandler} />
                </div>
              </div>
              <div className="formBlock__row formBlock__row--btn">
                <div className="formBlock__rowWrap">
                  <button
                    onClick={() => {
                      addAccess(files)
                      setAddModalIsOpen(false)
                    }}
                    className="addNewAccess__btn"
                    type="button"
                    disabled={!(host && login && password && type)}
                  >
                    <span>
                      <svg width="36" height="13" viewBox="0 0 31 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.25 6.0013H29.5833M29.5833 6.0013L25.4167 1.41797M29.5833 6.0013L25.4167 10.168"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                    Создать
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderModal>
    </div>
  )
}

export default AccessDetailFormAdd
