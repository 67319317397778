import React, { FC } from "react"
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"
import { SupportBurndownType } from "../../_types"

type BurnDownType = {
  supportBurndown: SupportBurndownType
}

export const SupportGraf: FC<BurnDownType> = ({ supportBurndown }) => {
  supportBurndown.chart.map((chart) => {
    chart.date = chart.date.split(".")[0]
  })
  return (
    <div className="graf">
      <div className="graf__title">{supportBurndown.name || "График BurnDown"}</div>
      <LineChart
        width={1200}
        height={500}
        data={supportBurndown.chart}
        margin={{
          top: 15,
          right: 15,
          left: 15,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" interval={0} />
        <YAxis type={"number"} />
        <Tooltip />
        <Legend />
        <Line type="monotone" name={"Идеально"} dataKey="ideal" stroke="#82ca9d" dot={false} activeDot={false} />
        <Line type="monotone" name={"План"} dataKey="plan" stroke="#ff7300" activeDot={{ r: 8 }} />
        <Line type="monotone" name={"Отгрузка"} dataKey="fact" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </div>
  )
}
