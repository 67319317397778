import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import { AccessesItemType } from "../../../_types"
import { EditIcon } from "../../MyKritLabWikiPage/MykritlabDetail/EditIcon"
import classNames from "classnames"

type PropsType = {
  item: AccessesItemType
  editLink: string
}
const AccessesItem: FC<PropsType> = ({ item, editLink }) => {
  const items = [
    ["kritlabGroup"],
    ["kritlabCompany", "kritlabContact"],
    ["wiki"],
    ["gitRepository"],
    ["telegram"],
    ["site"]
  ] as Access[][]
  return (
    <div className="accesses__item">
      <div className={classNames("accesses__itemCol", "accesses__itemCol__name")}>
        <div className={"accesses__itemInner"}>
          <NavLink to={item.href} className="accesses__itemName">
            {item.name}
          </NavLink>
        </div>
      </div>
      {items.map((access, key) => {
        const column = access[0]
        return (
          <div className={`accesses__itemCol accesses__itemCol__${column} accesses__itemCol--gray`} key={key}>
            {access.map((string, key) => {
              return (
                <div className="accesses__itemInner" key={key}>
                  {RenderArrayLinks(item[string])}
                </div>
              )
            })}
          </div>
        )
      })}
      <div className="accesses__itemCol accesses__itemCol--gray accesses__editIcon">
        <EditIcon href={editLink} />
      </div>
    </div>
  )
}
export default AccessesItem
export const RenderArrayLinks = (item: Array<{ link: string; name: string } | string>, isDetail?: boolean) => {
  return item.map((group, key: number) => {
    const comma = isDetail && item.length !== ++key ? <span>;&#8194;</span> : ""
    if (typeof group === "string")
      return (
        <div key={key}>
          <a
            href={group.includes("http") ? group : `//${group}`}
            target="_blank"
            rel="noreferrer"
            className="accesses__itemName"
            key={key}
          >
            {group}
            {comma}
          </a>
        </div>
      )

    if (group.link[0] === "/")
      return (
        <div key={key}>
          <NavLink to={group.link} className="accesses__itemName">
            {group.name}
            {comma}
          </NavLink>
        </div>
      )
    return (
      <div key={key}>
        <a href={group.link} target={"_blank"} className="accesses__itemName">
          {group.name}
          {comma}
        </a>
      </div>
    )
  })
}
type Access = "kritlabGroup" | "kritlabCompany" | "kritlabContact" | "wiki" | "gitRepository" | "telegram" | "site"
