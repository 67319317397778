import { setDateFact } from "../../redux/reducers/factSpentReducer"
import { useDispatch } from "react-redux"

export const useSetDate = () => {
  const dispatch = useDispatch()
  const setDate = async (date: Date) => {
    await dispatch(setDateFact(date))
  }
  return { setDate }
}
