import React from "react"

const AccessIcon = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.96886 9.67826C7.9843 9.67826 7.9843 9.67826 7.96886 9.67826C10.409 9.67826 12.3241 7.76779 12.3241 5.31369C12.3241 4.13324 11.8762 3.04598 11.0577 2.22277C10.2546 1.38403 9.15804 0.933594 7.96886 0.933594C5.60592 0.933594 3.65998 2.89066 3.64453 5.2671C3.64453 6.46308 4.07696 7.56587 4.8955 8.40461C5.71403 9.21229 6.81055 9.66273 7.96886 9.67826ZM10.965 5.34476C10.9496 6.99118 9.63681 8.29589 7.99974 8.29589H7.95341C6.3009 8.28036 5.01905 6.94458 5.03449 5.2671C5.04994 3.62068 6.36268 2.3315 7.99974 2.3315H8.03063C9.68314 2.34703 10.965 3.66727 10.965 5.34476Z"
        fill="white"
      />
      <path
        d="M3.7067 14.9899H3.69126C3.01172 15.0054 3.01172 15.6112 3.01172 16.015V17.3198C3.01172 17.7081 3.3206 18.0187 3.7067 18.0187H3.73759C4.12369 18.0187 4.43257 17.7081 4.43257 17.3198V16.5121V16.3568C4.43257 16.248 4.43257 16.1548 4.43257 16.0461C4.43257 15.7199 4.43257 15.3938 4.21635 15.1763C4.0928 15.052 3.92292 14.9899 3.7067 14.9899Z"
        fill="white"
      />
      <path
        d="M12.2935 14.9899H12.278C11.5676 15.0054 11.583 15.7044 11.583 15.9995V17.3198C11.583 17.7081 11.8919 18.0187 12.278 18.0187C12.4788 18.0187 12.6487 17.941 12.7877 17.8013C12.9267 17.6615 13.0039 17.4751 13.0039 17.2887C13.0039 16.8693 13.0039 16.3102 13.0039 15.984C13.0039 15.6733 12.9884 15.3627 12.7877 15.1763C12.6641 15.052 12.4942 14.9899 12.2935 14.9899Z"
        fill="white"
      />
      <path
        d="M11.9073 9.91295C10.6564 9.89741 9.37452 9.89742 8.06178 9.89742C6.77992 9.89742 5.45174 9.89741 4.09266 9.91295C1.88417 9.92848 0.046332 11.7613 0.015444 13.9824C0.015444 14.9765 0 16.0482 0 17.3218C0 17.5082 0.0772201 17.6791 0.200772 17.8189C0.324324 17.9587 0.509652 18.0208 0.694981 18.0208C1.08108 18.0208 1.38996 17.7101 1.38996 17.3218V16.7316C1.38996 15.9395 1.38996 15.1473 1.38996 14.3552C1.38996 12.2739 2.36293 11.2953 4.46332 11.2953C6.85714 11.2953 9.25097 11.2953 11.6448 11.2953C13.6371 11.2953 14.61 12.2894 14.61 14.3086C14.61 15.3182 14.61 16.3278 14.61 17.3218C14.61 17.7101 14.9189 18.0208 15.305 18.0208C15.4903 18.0208 15.6602 17.9431 15.7992 17.8189C15.9228 17.6791 16 17.5082 16 17.3218C16 16.0482 15.9846 14.9299 15.9691 13.9047C15.9228 11.5749 14.2548 9.92848 11.9073 9.91295Z"
        fill="white"
      />
    </svg>
  )
}

export default AccessIcon
