import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { AppStateType } from "../../redux/store"
import { changePageThunk, searchThunk } from "../../redux/reducers/accessesReducer"
export const useAccessesData = () => {
  const { items, currentPage, totalPages } = useSelector((state: AppStateType) => state.accesses)
  const [searchValue, setSearchValue] = useState<string>()
  const searchInputTimer = useRef<any>()
  const dispatch = useDispatch()
  const isEmpty = !items.length
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleClick = (number: number, e: React.MouseEvent) => {
    e.preventDefault()
    dispatch(changePageThunk(number, searchValue))
  }

  useEffect(() => {
    if (searchValue === undefined) {
      return
    }
    clearTimeout(searchInputTimer.current)
    // @ts-ignore
    searchInputTimer.current = setTimeout(() => {
      dispatch(searchThunk(searchValue))
    }, 500)
  }, [searchValue])

  return {
    items,
    isEmpty,
    currentPage,
    totalPages,
    searchValue,
    handleClick,
    handleChange,
    dispatch
  }
}
