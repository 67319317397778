import { TaskFactType, UserFactType } from "../../_types"
import { InferActionsType } from "../store"
import { resourcesFactAPI } from "../../api/api"

const GET_USERS = "GET_USERS"
const GET_TASKS = "GET_TASKS"
const SET_DATE = "SET_DATE"

export const initialState: InitialStateType = {
  users: [],
  tasks: {
    totalTime: 0,
    users: [],
    date: new Date()
  },
  selectedDate: new Date()
}

type InitialStateType = {
  users: UserFactType[]
  tasks: TaskFactType
  selectedDate: Date
}

const factSpentReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.users
      }
    case GET_TASKS:
      return {
        ...state,
        tasks: {
          date: action.tasks.date,
          users: action.tasks.users,
          totalTime: action.tasks.totalTime
        }
      }
    case SET_DATE:
      return {
        ...state,
        selectedDate: action.date
      }
    default:
      return state
  }
}

type ActionsType = InferActionsType<typeof actionsFact>
export const actionsFact = {
  getUsers: (users: UserFactType[]) => ({ type: GET_USERS, users } as const),
  getTasks: (tasks: TaskFactType) => ({ type: GET_TASKS, tasks } as const),
  setDate: (date: Date) => ({ type: SET_DATE, date } as const)
}

export const getUsersFact = () => async (dispatch: any) => {
  const result = await resourcesFactAPI.getFactUsers()
  dispatch(actionsFact.getUsers(result))
}

export const getTasksFact = (usersArray: number[], date?: Date) => async (dispatch: any) => {
  const result = usersArray.length ? await resourcesFactAPI.getFactTasks(usersArray, date) : { totalTime: 0, users: [] }
  dispatch(actionsFact.getTasks(result))
}

export const setDateFact = (date: Date) => async (dispatch: any) => {
  dispatch(actionsFact.setDate(date))
}

export default factSpentReducer
