import React, { FC } from "react"
import { LinksAndFilesType } from "../../../_types"

const Links: FC<{ items: LinksAndFilesType[] }> = ({ items }) => (
  <div className="post__bottomCol post__links">
    <h5>Ссылки на внешние источники</h5>
    {items.map((item) => (
      <div className="post__linksItem" key={item.name + Math.random()}>
        <a href={item.href} target="_blank" rel="noopener noreferrer" className="post__linksLink">
          {item.name}
        </a>
      </div>
    ))}
  </div>
)

export default Links
