import { Redirect, Route, Switch } from "react-router-dom"
import React from "react"
import { useLayoutEffect } from "react"
import MykritlabDetail from "./Pages/MyKritLabWikiPage/MykritlabDetail/MykritlabDetail"
import AccessDetail from "./Pages/MyKritLabAccessesPage/AccessDetail/AccessDetail"
import MykritlabArticles from "./Pages/MykritlabArticlesPage/MykritlabArticles"
import MyKritLabMenu from "./Pages/MyKritLabPage/MyKritLabMenu"
import Accesses from "./Pages/MyKritLabAccessesPage/Accesses"
import WikiMain from "./Pages/MyKritLabWikiPage/WikiMain"
import Authorization from "./Pages/AuthPage/Auth"
import FallBack from "./components/other/FallBack"
import Footer from "./components/Footer/Footer"
import Header from "./components/Header/Header"
import { useTitle } from "./hooks/useTitle"
import { useApp } from "./hooks/App/useApp"
import "./App.css"
import {
  ACCESS_ITEM_DETAIL,
  ACCESS_MANAGER_PAGE,
  AUTH_PAGE,
  ROOT,
  SUPPORT_PAGE,
  TASKS,
  WIKI_ITEM_DETAIL,
  WIKI_PAGE,
  WIKI_SECTIONS_DETAIL
} from "./api/constants"
import { Tasks } from "./Pages/TasksPage/Tasks"
import { TopScrollContainer } from "./components/ScrollToTopContainer/TopScrollContainer"
//import { useBackURL } from './hooks/App/useBackURL'
import { SupportPage } from "./Pages/SupportPage/Support"

export const home = ""

function App() {
  //  useBackURL()
  const { setTitle } = useTitle()
  setTitle("Лаборатория разработки krit.studio")
  const { load, fetchData, isNotAuth } = useApp()

  useLayoutEffect(() => {
    fetchData()
  }, [])

  if (load) {
    return <FallBack />
  }
  if (isNotAuth) {
    return (
      <>
        <Header isNotAuth={isNotAuth} />
        <Route path={AUTH_PAGE} render={() => <Authorization />} />
        <Redirect push to={AUTH_PAGE} />
        <Footer isAuth={isNotAuth} />
      </>
    )
  }

  return (
    <div className="main-wrapper">
      <TopScrollContainer isNotAuth={isNotAuth}>
        <div className="content">
          <Switch>
            <Route path={AUTH_PAGE} render={() => <Authorization />} />
            <Route path={ROOT} exact render={() => <MyKritLabMenu />} />
            <Route path={WIKI_PAGE} exact render={() => <WikiMain />} />
            <Route path={WIKI_SECTIONS_DETAIL} render={() => <MykritlabArticles />} />
            <Route path={WIKI_ITEM_DETAIL} render={() => <MykritlabDetail />} />
            <Route path={ACCESS_MANAGER_PAGE} render={() => <Accesses />} />
            <Route path={ACCESS_ITEM_DETAIL} render={() => <AccessDetail />} />
            <Route path={SUPPORT_PAGE} render={() => <SupportPage />} />
            <Route path={TASKS} render={() => <Tasks />} />
          </Switch>
        </div>
        <Footer isAuth={isNotAuth} />
      </TopScrollContainer>
    </div>
  )
}

export default App
