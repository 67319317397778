import React, { FC } from "react"
import classNames from "classnames"

type PropsType = {
  isFavorite: boolean
  dataId: number
  handleFavoriteClick?: (fav: boolean, id: number, parentName: string | undefined) => void
  detailFavoriteClick?: (fav: boolean, id: number) => void
  parentName?: string
}

const FavoriteBody = () => (
  <>
    <div className="favorite__icon">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.17731 0.655174L10.8516 3.41347C11.0438 3.73009 11.361 3.95533 11.7285 4.03601L14.9305 4.73966C15.9446 4.96244 16.3444 6.16503 15.658 6.92774L13.4909 9.33604C13.2421 9.61243 13.121 9.97688 13.1559 10.3434L13.4605 13.5365C13.557 14.5478 12.5101 15.2909 11.5557 14.8888L8.54197 13.6189C8.19605 13.4732 7.80392 13.4732 7.458 13.6189L4.44425 14.8888C3.48982 15.2909 2.44298 14.5478 2.53945 13.5365L2.84409 10.3434C2.87902 9.97688 2.75791 9.61243 2.50911 9.33604L0.341937 6.92774C-0.344429 6.16503 0.0554764 4.96254 1.06948 4.73966L4.27146 4.03601C4.63895 3.95522 4.95618 3.73009 5.14836 3.41347L6.82266 0.655174C7.35321 -0.218391 8.6471 -0.218391 9.17731 0.655174Z" />
      </svg>
    </div>
    <div className="favorite__text favorite__text--added">
      <div className="favorite__textItem favorite__textItem--other">Статья добавлена в «Избранные»</div>
      <div className="favorite__textItem favorite__textItem--md">В «Избранных»</div>
      <div className="favorite__textItem favorite__textItem--xs">Добавлена в «Избранные»</div>
    </div>
    <div className="favorite__text favorite__text--notadded">
      <div className="favorite__textItem favorite__textItem--other">Добавить статью в «Избранные»</div>
      <div className="favorite__textItem favorite__textItem--md">В «Избранные»</div>
      <div className="favorite__textItem favorite__textItem--xs">Добавить в «Избранные»</div>
    </div>
  </>
)

const Favorite: FC<PropsType> = ({ isFavorite, dataId, handleFavoriteClick, parentName, detailFavoriteClick }) => (
  <button
    type="button"
    className={classNames({
      favorite: true,
      "favorite--added": isFavorite
    })}
    onClick={
      () =>
        /*eslint-disable*/
        handleFavoriteClick
          ? handleFavoriteClick(isFavorite, dataId, parentName)
          : detailFavoriteClick
          ? detailFavoriteClick(isFavorite, dataId)
          : undefined
      /* eslint-enable */
    }
    data-id={dataId}
  >
    <FavoriteBody />
  </button>
)

export default Favorite
