import { toastError } from "./toastError"
import { AUTH_PAGE } from "../api/constants"

export const checkHttpError = async (status: number, dispatch: any) => {
  if (status === 403) {
    window.location.href = AUTH_PAGE
    return
  }
  if (status === 500) {
    toastError()
  }
}
