import React from "react"
import MyWikiTop from "./components/Top/MyWikiTop"
import MyWikiBottom from "./components/Bottom/MyWikiBottom"
import { useTitle } from "../../hooks/useTitle"
import FallBack from "../../components/other/FallBack"
import { useWiki } from "../../hooks/Wiki/useWiki"

const WikiMain = () => {
  const { setTitle } = useTitle()
  setTitle("Wiki krit.studio")
  const { load, showCategoryChangeHandler, showCategory } = useWiki()

  if (load) {
    return <FallBack />
  }
  return (
    <div className="myKritlabWiki">
      <MyWikiTop setShowCategory={showCategoryChangeHandler} showCategory={showCategory} />
      {showCategory && <MyWikiBottom />}
    </div>
  )
}
export default WikiMain
