import React, { FC } from "react"

import Slider from "react-slick"
import classNames from "classnames"
import MyWikiTopMenu from "./MyWikiTopMenu"
import MyWikiTopList from "./MyWikiTopList"
import { TopMenuType } from "../../../../_types"
import { useWikiTop } from "../../../../hooks/Wiki/useWikiTop"
import { TopListEmptyIcon } from "./TopListEmptyIcon"
import { EmptyRequest } from "../../../../components/Empty/EmptyRequest"

type PropsType = {
  setShowCategory: (event?: React.MouseEvent, name?: string) => void
  showCategory: boolean
}

const WikiTopLayout: FC = ({ children }) => (
  <>
    <div className="wikiListBlock__list">
      <div className="wikiListBlock__slider js-slider">{children}</div>
    </div>
  </>
)
const CategoryElement: FC<PropsType> = ({ showCategory, setShowCategory }) => (
  <li className="wikiListMenu__item">
    <button
      type="button"
      className={classNames({
        wikiListMenu__link: true,
        active: showCategory
      })}
      onClick={() => setShowCategory()}
      title="Категории"
    >
      <span className="wikiListMenu__linkText wikiListMenu__linkText--short">Категории</span>
      <span className="wikiListMenu__linkText wikiListMenu__linkText--full">Категории статей</span>
    </button>
  </li>
)

const MyWikiTop: FC<PropsType> = ({ showCategory, setShowCategory }) => {
  const {
    handleFavoriteClick,
    settings,
    topMenuItems,
    selectedItem,
    /* eslint-disable-next-line */
    _slider,
    listName
  } = useWikiTop(setShowCategory)

  const renderItemsList = (items: TopMenuType[]) =>
    items.map((innerItems, index) => (
      <MyWikiTopList
        items={innerItems}
        handleFavoriteClick={(fav, id, parentName) => handleFavoriteClick(fav, id, parentName)}
        key={index}
      />
    ))

  const renderList = () => {
    if (selectedItem[0] && selectedItem[0].items.length > 0) {
      if (_slider) {
        return (
          <WikiTopLayout>
            <Slider {...settings}>{renderItemsList(selectedItem)}</Slider>
          </WikiTopLayout>
        )
      }
      return <WikiTopLayout>{renderItemsList(selectedItem)}</WikiTopLayout>
    }
    return (
      <div className="wikiListBlock__list">
        <EmptyRequest title="Ничего не найдено" text="Здесь скоро что-нибудь появится" icon={<TopListEmptyIcon />} />
      </div>
    )
  }

  const renderMenu = () =>
    topMenuItems.map((item: TopMenuType, index: number) => (
      <MyWikiTopMenu
        item={item}
        selectedItem={selectedItem}
        event={(e: React.MouseEvent, name: string) => setShowCategory(e, name)}
        key={index}
      />
    ))

  return (
    <div className="wikiListTop">
      <div className="wikiListMenu">
        <div className="wrapper">
          <div className="wikiListMenu__wrap">
            <ul className="wikiListMenu__list">
              <CategoryElement setShowCategory={setShowCategory} showCategory={showCategory} />
              {renderMenu()}
            </ul>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="wikiListBlock">
          {!showCategory && (
            <>
              <h2 className="title wikiListBlock__title">{listName}</h2>
              {renderList()}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default MyWikiTop
