import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { searchAPI } from "../api/api"

import { SearchResultType } from "../_types"
import { checkHttpError } from "../helpers/checkHttpError"
import { useKeyboardNavigation } from "./useKeyboardNavigation"

export const useGlobalSearch = () => {
  const [searchResult, setSearchResult] = useState<SearchResultType[]>([])
  const [toggleRequest, setToggleRequest] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")
  const searchInputTimer = useRef<any>()
  const dispatch = useDispatch()
  useEffect(() => {
    clearTimeout(searchInputTimer.current)
    // @ts-ignore
    searchInputTimer.current = setTimeout(async () => {
      try {
        if (inputValue === "") {
          setSearchResult([])
          setActiveEl(0)
          return
        }

        const { result, status } = await searchAPI.search(inputValue)
        await checkHttpError(status, dispatch)
        setSearchResult(result)
      } catch (err) {
        console.warn(err)
      }
    }, 200)
  }, [dispatch, inputValue, toggleRequest])
  const handleFocus = () => {
    setToggleRequest(!toggleRequest)
    setActiveEl(0)
  }
  const handleBlur = (event?: any, isLink?: boolean) => {
    let timeOut = 200
    if (!event.relatedTarget && event.type === "blur") {
      timeOut = 0
    }
    setTimeout(() => {
      setSearchResult([])
      setActiveEl(0)
    }, timeOut)
    if (isLink) {
      setInputValue("")
    }
  }
  const { setActiveEl, activeEl, keyDownHandler } = useKeyboardNavigation(searchResult, handleBlur)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  return {
    handleFocus,
    handleBlur,
    handleChange,
    searchResult,
    inputValue,
    activeEl,
    setActiveEl,
    keyDownHandler
  }
}
