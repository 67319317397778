import React, { FC, useMemo } from "react"
import Select from "react-select"
import { SectionMenuType } from "../../_types"
import { TaskSelectType } from "../../hooks/Tasks/useSelectFilter"

type PropsType = {
  select?: SectionMenuType[] | TaskSelectType[] | any
  onChange: (e: any) => void
  value?: { value: string; label: string }[] | { value: string; label: string }
}

const SelectRender: FC<PropsType> = ({ select, onChange, value }) =>
  useMemo(
    () => (
      <Select
        options={select}
        onChange={onChange}
        value={value}
        isSearchable={false}
        className="styledSelect"
        classNamePrefix="styledSelect"
      />
    ),
    [select, value]
  )
export default SelectRender
