export const ROOT = "/"
export const AUTH_PAGE = "/auth/"
export const WIKI_PAGE = "/wiki/"
export const WIKI_SECTIONS_DETAIL = "/wiki/sections/:id/"
export const WIKI_ITEM_DETAIL = "/wiki/item/:id/"
export const ACCESS_MANAGER_PAGE = "/access-manager/"
export const ACCESS_ITEM_DETAIL = "/access/item/:id/"
export const TASKS = "/b24/:tab/"
export const BACK_URL_LS_KEY = "backurl"
export const SUPPORT_PAGE = "/support/"
