import React, { useEffect, useState } from "react"
import AccessDetailCard from "./components/AccessDetailCard"
import AccessDetailList from "./components/AccessDetailList"
import AccessDetailFormAdd from "./components/Forms/AccessDetailFormAdd"
import AccessDetailForm from "./components/Forms/AccessDetailForm"
import AccessDetailFormDelete from "./components/Forms/AccessDetailFormDelete"
import BreadCrumbs from "../../../components/other/BreadCrumbs/BreadCrumbs"
import FallBack from "../../../components/other/FallBack"
import AccessDetailFormEdit from "./components/Forms/AccessDetailFormEdit"
import { useFormEvents } from "../../../hooks/Accesses/AccessDetail/useFormEvents"
import { useAccessDetail } from "../../../hooks/Accesses/AccessDetail/useAccessDetail"
import { AccessDetailContext } from "./context/useAccessDetailContext"
import { useAccessDetailData } from "../../../hooks/Accesses/AccessDetail/useAccessDetailData"
import Checkbox from "../../../components/other/Checkbox"
import { accessesAPI } from "../../../api/api"
import InfoButton from "../components/InfoButton"
import { EditIcon } from "../../MyKritLabWikiPage/MykritlabDetail/EditIcon"
import { getItem } from "../../../helpers/localStorageBool"

/** export type ContextType = eventsType & DataType */
const AccessDetail = () => {
  const currentID = window.location.pathname.slice(-6, -1)
  const events = useFormEvents()
  const { favoritesAccesses, items, data } = useAccessDetailData()
  const isFavorite = getItem(`myFavoriteAccess__${currentID}`)
  const [favorite, setFavorite] = useState<boolean>(isFavorite)
  const [isEnableLoader, setEnableLoader] = useState<boolean>(true)
  const { fetchData, id, load } = useAccessDetail()
  const [isShowBlogInfo, setShowBlogInfo] = useState<boolean>(false)
  useEffect(() => {
    const isFavorite = getItem(`myFavoriteAccess__${currentID}`)
    fetchData(isFavorite, isEnableLoader)
  }, [favorite, isFavorite])
  const editLink = `https://krit.studio/bitrix/admin/iblock_element_edit.php?IBLOCK_ID=75&type=crm&ID=${id}`
  const isMineRequest = async (isMine: symbol) => {
    await accessesAPI.isMineAccess(id, { isMine })
  }
  const eventFavoriteReq = () => {
    setFavorite((prev) => !prev)
  }
  if (load) {
    return <FallBack />
  }
  return (
    <AccessDetailContext.Provider value={{ ...events, data, favoritesAccesses, items, favorite: isFavorite }}>
      <div>
        <div className="whiteBack">
          <div className="wrapper">
            <div className="d-sm-flex">
              <BreadCrumbs lastItem={data.name} />
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="accessDetail">
            <div className="d-sm-flex" style={{ maxWidth: "1280px" }}>
              <div className="accessCardInfo">
                <h2 className="title accessDetail__title">{data.name}</h2>{" "}
                <InfoButton onClick={() => setShowBlogInfo((prev) => !prev)} />
                <EditIcon href={editLink} />
              </div>
              <div className="accessCheckbox">
                <Checkbox
                  defaultValue={isFavorite}
                  label="Избранные доступы"
                  name={`myFavoriteAccess__${data.id}`}
                  event={eventFavoriteReq}
                  onClick={() => setEnableLoader(false)}
                />
                <Checkbox
                  defaultValue={Boolean(data.isMine)}
                  label="Мой проект"
                  name="myProject"
                  event={isMineRequest}
                />
              </div>
            </div>
            <div className="accessDetail__top">
              {isShowBlogInfo && (
                <div className="accessDetail__topRow">
                  <AccessDetailCard />
                </div>
              )}
              <div className="accessDetail__topRow accessDetail__topRow--flex">
                <AccessDetailFormAdd />
                <AccessDetailForm />
              </div>
            </div>
            <AccessDetailList />
          </div>
          <AccessDetailFormDelete />
          <AccessDetailFormEdit />
        </div>
      </div>
    </AccessDetailContext.Provider>
  )
}

export default AccessDetail
