import React from "react"

const RequestAccessIcon = () => {
  return (
    <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4527_212)">
        <path
          d="M20.0185 8C19.3549 8 19.1337 8.11111 19.1337 8.88889V9.44444C19.1337 9.66666 19.1337 9.88889 19.1337 10.2222C19.1337 11 18.4701 11.6667 17.6959 11.6667C16.9217 11.6667 16.2582 11 16.2582 10.2222C16.2582 9.55555 16.2582 8.77777 16.2582 8.66666C16.2582 8.11111 15.9264 8 15.5946 8C15.3734 8 15.1522 8 15.0416 8C15.0416 8 12.3872 8 12.2766 8C11.8342 8 11.5024 8.11111 11.2812 8.77777C10.507 10.8889 8.29502 12.3333 6.08304 12.2222C3.64986 12 1.76968 10.4444 1.10608 8.11111C0.221291 5.33333 1.65908 2.22222 4.42406 1.22222C5.08765 0.999997 5.64064 0.888885 6.30424 0.888885C8.51622 0.888885 10.507 2.22222 11.2812 4.22222C11.613 4.88889 11.8342 5.11111 12.6084 5.11111C13.825 5.11111 15.0416 5.11111 16.2582 5.11111H21.4563C21.5669 5.11111 21.7881 5.11111 21.8987 5.11111C22.7835 5.22222 23.2259 5.77777 23.2259 6.55555C23.2259 7.33333 22.7835 7.88889 22.0093 8C21.6775 8 20.0185 8 20.0185 8Z"
          stroke="#B6C2E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.09212 6.44445C4.09212 5.22223 5.08751 4.22223 6.4147 4.22223H6.5253C7.74189 4.33334 8.62668 5.22223 8.62668 6.44445C8.62668 7.66667 7.63129 8.55556 6.4147 8.55556C4.97691 8.66667 3.98152 7.55556 4.09212 6.44445Z"
          stroke="#B6C2E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_4527_212">
          <rect width="24" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RequestAccessIcon
