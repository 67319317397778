import React, { FC } from "react"
import { TaskFactUsersProjectsType } from "../../_types"
import { timeFromMins } from "../../helpers/timeFromMins"

type FactUserTaskType = {
  project: TaskFactUsersProjectsType
}

const FactUserTask: FC<FactUserTaskType> = ({ project }) => {
  const formatedTime = project.time.map((time) => timeFromMins(time)).join(", ")

  return (
    <div className="factLoadCosts__card-task">
      {project.name} (
      <a href={project.link} target="_blank" rel="noreferrer">
        {project.id}
      </a>
      ) — {formatedTime}
    </div>
  )
}

export default FactUserTask
