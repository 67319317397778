import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { useEffect } from "react"
import { AppStateType } from "../../../redux/store"
import { SectionMenuType } from "../../../_types"
import { getSelectedItemThunk } from "../../../redux/reducers/wikiReducer"
import { wikiAPI } from "../../../api/api"
import { toastPromise } from "../../../helpers/toastPromise"

export const useArticlesDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const { sectionMenu } = useSelector((state: AppStateType) => state.wiki)
  const { breadCrumbs } = useSelector((state: AppStateType) => state.wiki.selectedSection)

  const changeHandler = (e: SectionMenuType) => {
    history.push(e.href)
  }

  const detailFavoriteClick = async (fav: boolean, id: number) => {
    await toastPromise(wikiAPI.addFavorite(fav, id), dispatch, "Сохранено")
    await dispatch(getSelectedItemThunk(id.toString()))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return { changeHandler, detailFavoriteClick, sectionMenu, breadCrumbs }
}
