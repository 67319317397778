import { useState } from "react"
import { useHistory } from "react-router-dom"
import { SearchResultType } from "../_types"

export const useKeyboardNavigation = (searchResult: SearchResultType[], handleBlur: (isLink?: boolean) => void) => {
  const [activeEl, setActiveEl] = useState<number>(0)
  const history = useHistory()
  const UpActive = () => {
    setActiveEl(activeEl - 1)
  }
  const DownActive = () => {
    setActiveEl(activeEl + 1)
  }
  const submit = () => {
    const item = searchResult[activeEl]
    handleBlur(true)
    history.push(item.href)
  }

  const keyDownHandler = (event: KeyboardEvent) => {
    switch (event.code) {
      case "ArrowDown":
        return DownActive()
      case "ArrowUp":
        return UpActive()
      case "Enter":
        return submit()
      default:
        return false
    }
  }
  return { activeEl, setActiveEl, keyDownHandler }
}
