import React, { FC, useEffect, useState } from "react"
import { useTitle } from "../../hooks/useTitle"
import FactLoadNav from "./FactLoadNav"
import FactLoadMain from "./FactLoadMain"
import FactLoadTabsContainer from "./FactLoadTabsContainer"
import { useWindowWidth } from "../../hooks/Accesses/useWindowWidth"
import { useSelector } from "react-redux"
import { AppStateType } from "../../redux/store"
import { useGetUsersFact } from "./useGetUsersFact"
import FallBack from "../../components/other/FallBack"
import { useGetTasks } from "./useGetTasks"

const FactLoadCosts: FC = ({ children }) => {
  const { width } = useWindowWidth()
  const [showMobileView, setShowMobileView] = useState(width > 1023)
  const [isMobile, setIsMobile] = useState(width > 1023)
  const { users, tasks } = useSelector((state: AppStateType) => state.factCoasts)
  const { load, fetchUsers } = useGetUsersFact()
  const openMobileViewTabs = () => setShowMobileView(!showMobileView)
  const { setTitle } = useTitle()
  const [checkedUsers, setCheckedUsers] = useState<number[]>([])
  const { getTasks } = useGetTasks()

  useEffect(() => {
    fetchUsers()
  }, [])
  useEffect(() => {
    if (width > 1023) setShowMobileView(true)
    setIsMobile(width < 1023)
  }, [width])

  setTitle("Фактические трудозатраты")
  if (load) return <FallBack />

  return (
    <div className="factLoadCosts">
      <div className="wrapper">
        <div className="factLoadCosts__title">Фактические трудозатраты</div>
        <FactLoadNav
          checkedUsers={checkedUsers}
          openMobileViewTabs={openMobileViewTabs}
          showMobileView={showMobileView}
        />
        {showMobileView && (
          <FactLoadTabsContainer
            isMobile={isMobile}
            setShowMobileView={setShowMobileView}
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            getTasks={getTasks}
            users={users}
            showMobileView={showMobileView}
          />
        )}
        {<FactLoadMain tasks={tasks} users={users} checkedUsers={checkedUsers} setCheckedUsers={setCheckedUsers} />}
      </div>
    </div>
  )
}

export default FactLoadCosts
