import React from "react"

import { useDispatch } from "react-redux"
import { logoutThunk } from "../../redux/reducers/authReducer"
import { useHeader } from "../../hooks/useHeader"
/**
 * Отключаю линт, так как требуется href для ссылки
 * */
/*eslint-disable*/
const User = () => {
  const dispatch = useDispatch()
  const { name, lastName, userProfileLink } = useHeader()
  const logout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    dispatch(logoutThunk())
  }
  return (
    <div className="header__user">
      <a target="_blank" href={userProfileLink} className="header__login">{`${name} ${lastName}`}</a>
      <div className="header__exit">
        <a onClick={(event) => logout(event)} className="header__exitLink">
          <span className="header__exitText">Выйти</span>
          <span className="header__exitIcon">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.872412 12.8142L19.682 12.8142L16.7491 15.7796C16.435 16.0972 16.435 16.6196 16.7491 16.9371C17.0632 17.2547 17.5798 17.2547 17.8939 16.9371L22.2096 12.5786C22.2451 12.5428 22.2704 12.5069 22.3008 12.4711C22.3059 12.4659 22.3109 12.4608 22.3109 12.4557C22.3211 12.4403 22.3261 12.425 22.3363 12.4147C22.3413 12.4045 22.3464 12.3994 22.3514 12.3891C22.3565 12.3789 22.3616 12.3635 22.3666 12.3533C22.3717 12.343 22.3768 12.3277 22.3818 12.3174C22.3869 12.3072 22.3869 12.2969 22.392 12.2867C22.397 12.2713 22.4021 12.256 22.4072 12.2406C22.4072 12.2355 22.4122 12.2252 22.4122 12.2201C22.4173 12.1996 22.4224 12.1791 22.4274 12.1586L22.4274 12.1535C22.4376 12.1023 22.4426 12.0511 22.4426 11.9999C22.4426 11.8923 22.4224 11.7899 22.3818 11.6926C22.3717 11.667 22.3616 11.6465 22.3514 11.6209C22.3109 11.5441 22.2603 11.4775 22.1995 11.416L17.8888 7.0575C17.7318 6.89873 17.5241 6.81678 17.3164 6.81678C17.1087 6.81678 16.9011 6.89873 16.744 7.0575C16.43 7.37504 16.43 7.89745 16.744 8.21499L19.6769 11.1804L0.872412 11.1804C0.426655 11.1804 0.0619474 11.5492 0.0619473 11.9999C0.0619473 12.4506 0.426655 12.8142 0.872412 12.8142Z"
                fill="#E7E7F4"
              />
              <path
                d="M7.53489 8.66581C7.95025 8.8297 8.42133 8.62484 8.58343 8.20486C10.1385 4.20999 13.8819 1.6338 18.1267 1.6338C23.7797 1.6338 28.3791 6.28425 28.3791 12C28.3791 17.7157 23.7797 22.3611 18.1317 22.3611C13.892 22.3611 10.1436 19.7798 8.58849 15.7849C8.42133 15.3649 7.95532 15.16 7.53995 15.3239C7.12459 15.493 6.92197 15.9641 7.08407 16.3841C8.88229 21.0141 13.2183 24 18.1317 24C24.6762 24 30 18.6172 30 12C30 5.38284 24.6763 -4.49961e-07 18.1317 -1.0031e-06C13.2183 -1.41838e-06 8.88229 2.98591 7.08407 7.61075C6.91691 8.02561 7.11952 8.50192 7.53489 8.66581Z"
                fill="#E7E7F4"
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
  )
}

export default User
