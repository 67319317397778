import React, { FC } from "react"

type PropsType = {
  clickEvent: (e: React.MouseEvent) => void
}

const Icon: FC<PropsType> = ({ clickEvent }) => (
  <div className="header__icon">
    <span className="header__iconBtn js-open-side" onClick={clickEvent} title="Открыть меню" role="button">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2.8125" cy="2.8125" r="2.8125" fill="#E7E7F4" />
        <circle cx="15.1875" cy="2.8125" r="2.8125" fill="#E7E7F4" />
        <circle cx="15.1875" cy="15.1875" r="2.8125" fill="#7C89AB" />
        <circle cx="2.8125" cy="15.1875" r="2.8125" fill="#E7E7F4" />
      </svg>
    </span>
  </div>
)

export default Icon
