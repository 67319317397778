import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeUserThemeThunk } from "../redux/reducers/authReducer"
import { AppStateType } from "../redux/store"

export const useFooter = () => {
  const DARKTHEME = useSelector((state: AppStateType) => state.authPage.DARKTHEME)
  const [inputState, setInputState] = useState<boolean>(false)
  const dispatch = useDispatch()

  const changeInputState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    dispatch(changeUserThemeThunk(checked))
  }

  const changeTheme = (status: any) => {
    const body = document.querySelector("body")
    body!.classList[status ? "add" : "remove"]("dark-theme")
  }
  return { changeInputState, changeTheme, DARKTHEME, inputState, setInputState }
}
