import { useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { AppStateType } from "../redux/store"

export const useHeader = () => {
  const { name, lastName, userProfileLink } = useSelector((state: AppStateType) => state.authPage.user)
  const menu = useSelector((state: AppStateType) => state.data.menu)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true)
  const { pathname } = useLocation()
  const onClickHandler = () => {
    setMenuIsOpen(menuIsOpen)
    menuActions()
  }
  const onLogoClickHandler = () => {
    const container = document.querySelector("body")
    container!.classList.remove("menu-is-open")
  }
  const menuActions = () => {
    const container = document.querySelector("body")
    if (menuIsOpen) {
      container!.classList[!container!.classList.contains("menu-is-open") ? "add" : "remove"]("menu-is-open")
    } else {
      container!.classList.remove("menu-is-open")
    }
  }
  const onLinkClickHandler = () => {
    setMenuIsOpen(true)
    menuActions()
  }
  return {
    onClickHandler,
    onLinkClickHandler,
    onLogoClickHandler,
    pathname,
    name,
    lastName,
    userProfileLink,
    menu
  }
}
