import React from "react"
import { useWindowWidth } from "../../../hooks/Accesses/useWindowWidth"
import classNames from "classnames"
export const AccessesHeader = () => {
  const { width } = useWindowWidth()
  const headerTitles = [
    { text: "Наименование", shortTitle: null, isGray: false, type: "name" },
    { text: "Группы", shortTitle: null, isGray: true, type: "kritlabGroup" },
    { text: "Компании/Контакты", shortTitle: null, isGray: true, type: "kritlabCompany" },
    { text: "Вики", shortTitle: null, isGray: true, type: "wiki" },
    { text: "Репозитории", shortTitle: null, isGray: true, type: "gitRepository" },
    { text: "Чаты", shortTitle: null, isGray: true, type: "telegram" },
    { text: "Сайты", shortTitle: null, isGray: true, type: "site" },
    { text: "", shortTitle: null, isGray: true, isIcon: true }
  ]
  return (
    <div className="accesses__item accesses__item--head">
      {headerTitles.map((title, key) => {
        const column = title.type
        return (
          <div
            className={classNames(
              "accesses__itemCol",
              "accesses__itemCol--head",
              title.isGray && "accesses__itemCol--gray",
              title.isIcon && "accesses__editIcon",
              `accesses__itemCol__${column}`
            )}
            data-title={title.shortTitle}
            data-title-sm={title.text}
            key={key}
          >
            {width < 600 && title.shortTitle ? title.shortTitle : title.text}
          </div>
        )
      })}
    </div>
  )
}
