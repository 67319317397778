export const MIGRATION = "migration"
export const EMPTYPLAN = "emptyplan"
export const EMPTYDEADLINE = "emptydeadline"
export const EMPTYTAG = "emptytag"
export const AUTOCLOSE = "autoclose"
export type TabTitleType = {
  label: string
  code: number
}
export const useTabTitle = (tab: string) => {
  switch (tab) {
    case MIGRATION:
      return { label: "Задачи для переноса", code: 1 }
    case EMPTYPLAN:
      return { label: "Задачи без оценки", code: 2 }
    case EMPTYDEADLINE:
      return { label: "Задачи без крайнего срока", code: 3 }
    case EMPTYTAG:
      return { label: "Задачи без тега", code: 4 }
    case AUTOCLOSE:
      return { label: "Задачи, закрытые автоматически", code: 5 }
    default:
      return { label: "Задачи для переноса", code: 6 }
  }
}
