import { useState } from "react"
import { TaskType } from "../../_types"

export const useTasks = (tasks: TaskType[]) => {
  const [selectedTasks, setSelectedTasks] = useState(tasks)
  const setTasks = (selTasks?: TaskType[]) => {
    if (!selTasks) {
      setSelectedTasks(tasks)
      return
    }
    setSelectedTasks(selTasks)
  }
  return { selectedTasks, setSelectedTasks, setTasks }
}
