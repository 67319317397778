import React, { Fragment } from "react"

const PaginationArrowNext = ({ event, mouseEvent }) => (
  <li className="pagination__item pagination__item--arr">
    <button type="button" className="pagination__link pagination__link--next" onClick={event} onMouseEnter={mouseEvent}>
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.999999L7 7L0.999999 13" strokeWidth="1.5" />
      </svg>
    </button>
  </li>
)
const PaginationArrowPrev = ({ event, mouseEvent }) => (
  <li className="pagination__item pagination__item--arr">
    <button type="button" className="pagination__link pagination__link--prev" onClick={event} onMouseEnter={mouseEvent}>
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 13L2 7L8 1" strokeWidth="1.5" />
      </svg>
    </button>
  </li>
)
const PaginationArrowDotts = ({ event, mouseEvent }) => (
  <li className="pagination__item pagination__item--dots">
    <button type="button" className="pagination__link" onClick={event} onMouseEnter={mouseEvent}>
      ...
    </button>
  </li>
)
const PaginationLink = ({ event, mouseEvent, number }) => (
  <li className="pagination__item">
    <button type="button" className="pagination__link" onClick={event} onMouseEnter={mouseEvent}>
      {number}
    </button>
  </li>
)
const PaginationCurrent = ({ mouseEvent, number }) => (
  <li className="pagination__item pagination__item--current">
    <span className="pagination__link" onMouseEnter={mouseEvent}>
      {number}
    </span>
  </li>
)
const handleHover = (e) => {
  document.querySelector(".js-animate-hover-container").classList.add("hovered")
  const pagHover = document.querySelector(".js-animate-hover")
  const links = document.querySelectorAll(".pagination__item")

  if (!e.currentTarget.parentNode.classList.contains("active")) {
    for (let i = 0; i < links.length; i += 1) {
      if (links[i].classList.contains("active")) {
        links[i].classList.remove("active")
      }
    }

    e.currentTarget.parentNode.classList.add("active")
    const left = e.currentTarget.parentNode.offsetLeft - 10

    pagHover.style.left = `${left}px`
  }
}

const handleHoverOut = () => {
  document.querySelector(".js-animate-hover-container").classList.remove("hovered")
  const links = document.querySelectorAll(".pagination__item")

  for (let i = 0; i < links.length; i += 1) {
    if (links[i].classList.contains("active")) {
      links[i].classList.remove("active")
    }
  }
}

const Pagination = ({ items, currentPage, event }) => {
  const xs = items < 9
  const lg = items >= 9
  let renderPaginationXS
  let renderPaginationLG

  const pageNumbers = []
  if (items !== null) {
    for (let i = 1; i <= items; i += 1) {
      pageNumbers.push(i)
    }
    // если страниц меньше 9, пагинация выводятся без стрелок
    renderPaginationXS = pageNumbers.map((number, index) => {
      if (currentPage === number) {
        return <PaginationCurrent number={number} mouseEvent={(e) => handleHover(e)} key={index} />
      }
      return (
        <PaginationLink
          number={number}
          event={(e) => event(number, e)}
          mouseEvent={(e) => handleHover(e)}
          key={index}
        />
      )
    })

    // если страниц от 9, пагинация выводятся со стрелками
    renderPaginationLG = pageNumbers.map((number, index) => {
      // если currentPage в начале пагинации, до 5 страницы
      if (currentPage <= 4) {
        if (number <= 4) {
          if (currentPage === number) {
            return <PaginationCurrent number={number} mouseEvent={(e) => handleHover(e)} key={number + Math.random()} />
          }
          return (
            <PaginationLink
              number={number}
              event={(e) => event(number, e)}
              mouseEvent={(e) => handleHover(e)}
              key={number + Math.random()}
            />
          )
        }
        if (number === items) {
          return (
            <Fragment key={number + Math.random()}>
              <PaginationArrowNext event={(e) => event(currentPage + 1, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationArrowDotts event={(e) => event(currentPage + 4, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationLink number={number} event={(e) => event(number, e)} mouseEvent={(e) => handleHover(e)} />
            </Fragment>
          )
        }
        // если currentPage в конце пагинации, от 4 с конца
      } else if (currentPage > items - 4) {
        if (number > items - 4) {
          if (currentPage === number) {
            return <PaginationCurrent number={number} mouseEvent={(e) => handleHover(e)} key={number + Math.random()} />
          }
          return (
            <PaginationLink
              number={number}
              event={(e) => event(number, e)}
              mouseEvent={(e) => handleHover(e)}
              key={number + Math.random()}
            />
          )
        }
        if (number === 1) {
          return (
            <Fragment key={number + Math.random()}>
              <PaginationLink number={number} event={(e) => event(number, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationArrowDotts event={(e) => event(currentPage - 4, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationArrowPrev event={(e) => event(currentPage - 1, e)} mouseEvent={(e) => handleHover(e)} />
            </Fragment>
          )
        }
        // если currentPage в середине пагинации, от 4 с начала до 4 с конца
      } else {
        // первые 2 и последние 2 цифры
        if (number <= 2 || number > items - 2) {
          return (
            <PaginationLink
              number={number}
              event={(e) => event(number, e)}
              mouseEvent={(e) => handleHover(e)}
              key={number + Math.random()}
            />
          )
          // кусман середины
        }
        if (currentPage === number) {
          return (
            <Fragment key={number + Math.random()}>
              <PaginationArrowDotts event={(e) => event(number - 4, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationArrowPrev event={(e) => event(number - 1, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationLink
                number={number - 1}
                event={(e) => event(number - 1, e)}
                mouseEvent={(e) => handleHover(e)}
              />
              <PaginationCurrent number={number} mouseEvent={(e) => handleHover(e)} />
              <PaginationLink
                number={number + 1}
                event={(e) => event(number + 1, e)}
                mouseEvent={(e) => handleHover(e)}
              />
              <PaginationArrowNext event={(e) => event(number + 1, e)} mouseEvent={(e) => handleHover(e)} />
              <PaginationArrowDotts event={(e) => event(number + 4, e)} mouseEvent={(e) => handleHover(e)} />
            </Fragment>
          )
        }
      }
      return false
    })
  }
  return (
    <div className="pagination">
      <div className="pagination__wrap js-animate-hover-container">
        <span className="pagination__hover js-animate-hover" />
        <ul className="pagination__list" onMouseLeave={() => handleHoverOut()}>
          {xs && renderPaginationXS}
          {lg && renderPaginationLG}
        </ul>
      </div>
    </div>
  )
}

export default Pagination
