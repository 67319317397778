import React, { FC } from "react"
import { SupportInfoBlock } from "../../_types"

export type Types = {
  infoBlock: SupportInfoBlock
}

export const SupportTasksInfo: FC<Types> = ({ infoBlock }) => {
  return (
    <div className="wrapper">
      <h2 className="title tasksContent__title support-info--title">{infoBlock.title}</h2>
      {infoBlock.description && (
        <>
          <div className="support-info">
            <div className="support-info__icon">
              <svg width="68" height="55" fill="none" viewBox="0 0 68 55" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.595 26.762h-3.562v-1.357a1.406 1.406 0 00-2.81 0v1.357H7.767v-1.357a1.406 1.406 0 00-2.81 0v1.357H1.405c-.77 0-1.405.625-1.405 1.406v25.426C0 54.366.624 55 1.405 55h28.19c.77 0 1.405-.625 1.405-1.406V28.168a1.4 1.4 0 00-1.405-1.406zM8.792 49.153H6.82a1.689 1.689 0 010-3.377h1.97c.928 0 1.689.752 1.689 1.689a1.702 1.702 0 01-1.688 1.688zm0-6.578H6.82a1.689 1.689 0 010-3.378h1.97c.928 0 1.689.752 1.689 1.689a1.702 1.702 0 01-1.688 1.689zm0-6.58H6.82a1.689 1.689 0 010-3.376h1.97c.928 0 1.689.751 1.689 1.688a1.695 1.695 0 01-1.688 1.689zm7.864 13.1h-1.97a1.689 1.689 0 010-3.377h1.97c.927 0 1.688.751 1.688 1.688 0 .927-.76 1.689-1.688 1.689zm0-6.579h-1.97a1.689 1.689 0 010-3.377h1.97a1.689 1.689 0 010 3.377zm0-6.579h-1.97a1.689 1.689 0 010-3.377h1.97a1.689 1.689 0 010 3.377zm4.157 11.41c0-.927.751-1.688 1.688-1.688h1.99c.918 0 1.67.742 1.689 1.66a1.683 1.683 0 01-1.688 1.717h-1.99a1.683 1.683 0 01-1.689-1.688zm0-6.568c0-.928.751-1.689 1.688-1.689h1.99c.918 0 1.67.742 1.689 1.66a1.683 1.683 0 01-1.688 1.717h-1.99a1.695 1.695 0 01-1.689-1.688zm0-6.58c0-.926.751-1.688 1.688-1.688h1.99c.918 0 1.67.742 1.689 1.66a1.683 1.683 0 01-1.688 1.718h-1.99a1.695 1.695 0 01-1.689-1.69z"
                  fill="#C4A2FC"
                />
                <path
                  d="M65.043 4.96H54.696l-2.07-2.778a2.952 2.952 0 00-2.365-1.19H39.888L39.79.88A3.007 3.007 0 0037.683 0H21.681a2.959 2.959 0 00-2.956 2.975v31.739a2.959 2.959 0 002.956 2.975h43.362A2.959 2.959 0 0068 34.714V7.934a2.959 2.959 0 00-2.957-2.975zM50.261 2.974a.99.99 0 01.788.397l1.183 1.587h-8.044c-.259 0-.518-.112-.703-.297l-1.65-1.687h8.426zm15.768 31.739a.992.992 0 01-.986.991H21.681a.992.992 0 01-.985-.991V2.975c0-.545.443-.991.985-.991h16.002c.259 0 .518.111.703.297l3.695 3.781c.554.57 1.318.88 2.107.88h20.855c.542 0 .986.447.986.992v26.78z"
                  fill="#B6C2E2"
                />
                <path
                  d="M62.087 23.805H45.333a.992.992 0 00-.985.992v5.95c0 .546.443.992.985.992h16.754a.992.992 0 00.985-.992v-5.95a.992.992 0 00-.985-.992zm-.986 5.95H46.32v-3.967H61.1v3.968z"
                  fill="#B6C2E2"
                />
              </svg>
            </div>
            <div className="support-info__title">Важно!</div>
            <div className="support-info__text">{infoBlock.description.substr(6)}</div>
          </div>
        </>
      )}
    </div>
  )
}
