import FileUploader, { FileItem } from "@muzikanto/file-uploader"
import { useEffect, FC, useState } from "react"
import CrossIcon from "../../Pages/MyKritLabAccessesPage/AccessDetail/components/Icons/CrossIcon"

export type AddFileArgs = {
  setFiles: any
  serverFiles?: any
  delServerfiles?: number[]
  setDelServerFiles?: any
}

export const FileAdd: FC<AddFileArgs> = ({
  setFiles,
  serverFiles = [],
  delServerfiles = [],
  setDelServerFiles = () => undefined
}) => {
  const [lFiles, setlFiles] = useState<FileItem[]>([])

  useEffect(() => {
    const formData = new FormData()
    lFiles.forEach(({ file }) => formData.append(file.name, file))
    setFiles({ formData, files: lFiles })
  }, [lFiles])

  return (
    <FileUploader
      files={lFiles}
      onChange={(nextFiles) => setlFiles(nextFiles.filter((item) => item.success))}
      multiple
      maxSize={10240000}
    >
      {({ onClick, files, change, dragged, onDragEnter, onDragLeave, onDragOver, onDrop }) => {
        const deleteFile = (name: string, e: any) => {
          e.stopPropagation()
          setlFiles(files.filter((file) => file.file.name !== name))
        }
        return (
          <div
            className="file-add"
            style={dragged ? { border: "dashed 1px black" } : undefined}
            onClick={onClick}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <span className="file-add--info">Кликните или перетащите сюда файлы</span>
            {/* {!lFiles.length && (
                <span className="file-add--limits">(текст с какими нибудь ограничениями по форматам и весу)</span>
              )} */}

            <div className="file-add-files" onClick={(e) => e.stopPropagation()}>
              {serverFiles &&
                serverFiles.map(({ name, path, id }: any, i: number) => {
                  return (
                    <div className="file-add-files__item" key={`file-${i}`}>
                      <a
                        className={`uploaded ${(delServerfiles.includes(id) && "deleting") || ""}`}
                        href={path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {name || id}
                      </a>
                      <span
                        onClick={(e) =>
                          setDelServerFiles(
                            delServerfiles.includes(id)
                              ? delServerfiles.filter((elem) => elem !== id)
                              : [id, ...delServerfiles]
                          )
                        }
                      >
                        <CrossIcon title={delServerfiles.includes(id) ? "Вернуть" : "Удалить файл"} />
                      </span>
                    </div>
                  )
                })}
              {files &&
                files.map(({ file, error, success }, i) => {
                  return (
                    <div className="file-add-files__item" key={`file-${i}`}>
                      {file.name}
                      <span onClick={(e) => deleteFile(file.name, e)}>
                        <CrossIcon />
                      </span>
                    </div>
                  )
                })}
            </div>
          </div>
        )
      }}
    </FileUploader>
  )
}
