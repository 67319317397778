import React from "react"
import classNames from "classnames"

import AccessDetailListItem from "./AccessDetailListItem"
import { SelectedAccessItemType } from "../../../../_types"
import { isNotEmpty } from "../../../../helpers/isNotEmpty"
import { useAccessDetailContext } from "../context/useAccessDetailContext"
import { AccessDetailListHeader } from "./AccessDetailListHeader"

const AccessDetailList = () => {
  const { items } = useAccessDetailContext()
  const description = items?.filter((item: SelectedAccessItemType) => item.notice?.length > 0)
  return (
    <div
      className={classNames({
        accessDetail__list: true,
        "accessDetail__list--wide": isNotEmpty(description)
      })}
    >
      <div className="accessDetail__listWrap">
        <AccessDetailListHeader />
        {(() => {
          if (items?.length)
            return items.map((item: SelectedAccessItemType) => <AccessDetailListItem item={item} key={item.id} />)
          return <p className="empty">Нет ни одного доступа</p>
        })()}
      </div>
    </div>
  )
}

export default AccessDetailList
