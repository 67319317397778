import React from "react"

const OpenListSvg = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="2" width="40" height="40" rx="7" fill="white" />
      <path
        d="M27.8877 22.6891C27.2316 21.8181 26.3193 21.1558 25.3031 20.7986C26.1593 20.2086 26.6834 19.2211 26.6834 18.1735C26.6834 17.3507 26.3673 16.572 25.7952 15.9739C25.2231 15.3799 24.4589 15.0347 23.6387 15.0026C21.8342 14.9303 20.3458 16.3833 20.3458 18.1775C20.3458 19.2252 20.87 20.2086 21.7262 20.7986C21.154 20.9953 20.6259 21.2843 20.1458 21.6616C19.7657 21.3645 19.3416 21.1358 18.8895 20.9792C19.4616 20.4815 19.7857 19.747 19.7537 18.9803C19.7017 17.6598 18.6294 16.5961 17.3131 16.564C15.9047 16.5319 14.7444 17.6718 14.7444 19.0766C14.7444 19.8634 15.1085 20.5979 15.7287 21.0715C14.0923 21.7619 13 23.3915 13 25.1857V26.5504C13 26.7953 13.2001 26.996 13.4441 26.996C13.6882 26.996 13.8882 26.7953 13.8882 26.5504V25.1857C13.8882 23.223 15.4806 21.6254 17.4371 21.6254C18.1653 21.6254 18.8895 21.8582 19.4856 22.2797C18.5534 23.2952 18.0413 24.6118 18.0413 25.9965V26.5545C18.0413 26.7993 18.2413 27 18.4854 27C18.7294 27 18.9295 26.7993 18.9295 26.5545V26.0367C18.9295 23.528 20.942 21.4448 23.4146 21.3886C25.9952 21.3284 28.1118 23.4196 28.1118 25.9925V26.5504C28.1118 26.7953 28.3118 26.996 28.5559 26.996C28.7999 26.996 29 26.7953 29 26.5504V25.9925C28.988 24.7884 28.6079 23.6484 27.8877 22.6891ZM25.179 19.7429C24.7149 20.2407 24.0468 20.5056 23.3666 20.4614C22.2263 20.3892 21.3101 19.47 21.2381 18.3261C21.198 17.6879 21.4141 17.0777 21.8502 16.6121C22.2823 16.1505 22.8865 15.8896 23.5146 15.8896C23.5626 15.8896 23.6147 15.8896 23.6627 15.8937C24.803 15.9659 25.7192 16.8851 25.7912 18.029C25.8312 18.6672 25.6152 19.2733 25.179 19.7429ZM18.4454 20.1764C18.1053 20.5497 17.6052 20.7424 17.101 20.6982C16.3328 20.63 15.7047 19.9998 15.6367 19.2292C15.5966 18.7676 15.7447 18.3261 16.0528 17.9849C16.3648 17.6477 16.801 17.455 17.2531 17.455C17.3011 17.455 17.3531 17.4591 17.4011 17.4631C18.1693 17.5313 18.7974 18.1615 18.8655 18.9321C18.9055 19.3937 18.7574 19.8353 18.4454 20.1764Z"
        fill="#3262E0"
      />
    </svg>
  )
}

export default OpenListSvg
