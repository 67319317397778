import React from "react"

export const BreadCrumbArrow = () => (
  <li className="breadCrumbs__item">
    <span className="breadCrumbs__link breadCrumbs__link--next">
      <svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1L15 3M15 3L13 5M15 3H0.5" stroke="#8EADFF" />
      </svg>
    </span>
  </li>
)
