import { useDispatch } from "react-redux"
import { useState } from "react"
import { getUsersFact } from "../../redux/reducers/factSpentReducer"

export const useGetUsersFact = () => {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(false)
  const fetchUsers = async () => {
    try {
      await setLoad(true)
      await dispatch(getUsersFact())
      await setLoad(false)
    } finally {
      await setLoad(false)
    }
  }

  return { fetchUsers, load }
}
