import React, { useEffect } from "react"
import { useFooter } from "../../hooks/useFooter"

const SwitchTheme = () => {
  const { changeInputState, changeTheme, DARKTHEME, inputState, setInputState } = useFooter()
  useEffect(() => {
    setInputState(DARKTHEME)
    changeTheme(DARKTHEME)
  }, [changeTheme, setInputState, DARKTHEME])

  return (
    <div className="switcher">
      <button type="button" className="switcherButton switcherButton--light">
        Light
      </button>
      <label htmlFor="switcherInput" className="switcherCheck">
        <input
          id="switcherInput"
          type="checkbox"
          className="switcherInput"
          onChange={(e) => changeInputState(e)}
          checked={inputState}
        />
        <span className="switcherToggle" />
      </label>
      <button type="button" className="switcherButton switcherButton--dark">
        Dark
      </button>
    </div>
  )
}
export default SwitchTheme
