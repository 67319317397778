import React, { FC } from "react"
import classNames from "classnames"
import { AllUsersLabel } from "../AllUsersLabel"

export const TasksProjects: FC<any> = ({ onProjectClick, totalCount, items }) => (
  <div className="tasksContent__users tasksUsers">
    <div className="tasksUsers__list">
      <div className="tasksUsers__listWrap">
        <AllUsersLabel onItemClick={onProjectClick} totalCount={totalCount} />
        {items.map((item: any) => (
          <TasksProjectsItem key={item.id} item={item} onProjectClick={onProjectClick} count={item.count} />
        ))}
      </div>
    </div>
  </div>
)

const TasksProjectsItem: FC<any> = ({ item, onProjectClick, count }) => (
  <label className="tasksUsers__item" htmlFor={item.id.toString()}>
    <input
      id={item.id.toString()}
      type="radio"
      name="users__tasks"
      value={item.id}
      onClick={() => onProjectClick(item.id)}
    />
    <div
      className={classNames({
        tasksUsers__itemWrap: true,
        "tasksUsers__itemWrap--all": true
      })}
    >
      <div className="tasksUsers__itemName">{item.name}</div>
      {count && <div className="tasksUsers__itemCount">{count}</div>}
    </div>
  </label>
)
