import React from "react"

function Update() {
  return (
    <div className="update text-center">
      <div className="update__inner">
        <span className="update__title">Установка обновлений...</span>
        <div className="update-bar axis-x-center">
          <div className="update-bar__line" />
        </div>
      </div>
    </div>
  )
}

export default Update
