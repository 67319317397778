import React from "react"
import ReactDOM from "react-dom"
import "./local/assets/scss/entries/global.scss"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import App from "./App"
import store from "./redux/store"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import Update from "./components/ServiceWorker/Update"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    ReactDOM.render(<Update />, document.getElementById("root"))

    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
    }

    setTimeout(() => {
      window.location.reload()
    }, 1500)
  }
})
