import React, { FC } from "react"
import classNames from "classnames"

const ChangeTimeBlock: FC<{ hoursMinutesView: boolean; setHoursMinutesView: (arg: boolean) => void }> = ({
  hoursMinutesView,
  setHoursMinutesView
}) => {
  return (
    <button>
      Показать
      <span className="time-clock-wrapper">
        <span
          className={classNames({
            "time-clock": true,
            active: hoursMinutesView
          })}
          onClick={() => setHoursMinutesView(!hoursMinutesView)}
        >
          в часах:минутах
        </span>{" "}
        /{" "}
        <span
          className={classNames({
            "time-clock": true,
            active: !hoursMinutesView
          })}
          onClick={() => setHoursMinutesView(!hoursMinutesView)}
        >
          в часах c долями
        </span>
      </span>
    </button>
  )
}

export default ChangeTimeBlock
