import React, { FC, useState } from "react"
import classNames from "classnames"
import FactLoadTab from "./FactLoadTab"
import ResetSvg from "../../components/svg/ResetSvg"
import { UserFactType } from "../../_types"

type FactLoadTabsContainerType = {
  showMobileView: boolean
  users: UserFactType[]
  getTasks: (numbers: number[]) => void
  setCheckedUsers: (numbers: number[]) => void
  checkedUsers: number[]
  setShowMobileView: (arg: boolean) => void
  isMobile: boolean
}

const FactLoadTabsContainer: FC<FactLoadTabsContainerType> = ({
  showMobileView,
  users,
  getTasks,
  checkedUsers,
  setCheckedUsers,
  setShowMobileView,
  isMobile
}) => {
  const [allReset, setAllReset] = useState<boolean>(false)
  const defStateAllTabs = () => setAllReset(false)
  const resetAllTabs = async (e: any) => {
    e.stopPropagation()
    await setCheckedUsers(checkedUsers.slice(0, 0))
    await defStateAllTabs()
  }
  const submitMobileView = async () => {
    await getTasks(checkedUsers)
    await setShowMobileView(false)
  }

  return (
    <div
      className={classNames({
        "factLoadCosts__nav-tabs": true,
        "-show": showMobileView
      })}
    >
      <div className="factLoadCosts__nav-wrapper scrollElement">
        {users.map((user) => (
          <FactLoadTab
            isMobile={isMobile}
            showMobileView={showMobileView}
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            getTasks={getTasks}
            user={user}
            allReset={allReset}
            key={user.id}
            defStateAllTabs={defStateAllTabs}
          />
        ))}
      </div>
      <div className="factLoadCosts__btns">
        <div
          className={classNames({ "factLoadCosts-reset": true, disabled: !checkedUsers.length })}
          onClick={(e) => resetAllTabs(e)}
        >
          <ResetSvg />
        </div>
        <div className="factLoadCosts-accept" onClick={submitMobileView}>
          Готово
        </div>
      </div>
    </div>
  )
}

export default FactLoadTabsContainer
