import { useDispatch } from "react-redux"
import { getForecastThunk } from "../../redux/reducers/tasksReducer"
import { ReportsRequestType } from "../../_types"

export const useForecast = () => {
  const dispatch = useDispatch()
  const fetchForecast = async (projectObj: ReportsRequestType) => {
    try {
      await dispatch(getForecastThunk(projectObj))
    } finally {
      /* continue regardless of error */
    }
  }
  return { fetchForecast }
}
