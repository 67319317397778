import { useEffect, useState } from "react"

export const useWindowWidth = () => {
  const [width, setWidth] = useState(1000)
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
    }

    window.addEventListener("resize", updateWindowDimensions)
    setWidth(window.innerWidth)
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, [])
  return { width }
}
