import { useParams } from "react-router-dom"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { getSelectedAccess, likeEventThunk, removeAccessThunk } from "../../../redux/reducers/accessesReducer"

export type EventsType = {
  removeItem: (isFavorite: boolean) => void
  setEditModalIsOpen: (value: ((prevState: boolean) => boolean) | boolean) => void
  likeEvent: (accessID: number, itemLike: boolean, isFavorite: boolean) => void
  openEditModal: (id: number) => Promise<void>
  copyAccess: (id: number) => Promise<void>
  setAddModalIsOpen: (value: ((prevState: boolean) => boolean) | boolean) => void
  openDeleteModal: (id: number) => Promise<void>
  setSearchValue: (value: ((prevState: string) => string) | string) => void
  addModalIsOpen: boolean
  closeModals: () => void
  editModalIsOpen: boolean
  searchValue: string
  deleteModalIsOpen: boolean
}
export const useFormEvents = (): EventsType => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false)
  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")

  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const closeModals = () => {
    setDeleteModalIsOpen(false)
    setAddModalIsOpen(false)
    setEditModalIsOpen(false)
  }
  const copyAccess = async (id: number) => {
    await dispatch(getSelectedAccess(id, params.id))
  }
  const openEditModal = async (id: number) => {
    await dispatch(getSelectedAccess(id, params.id))
    setEditModalIsOpen(true)
  }
  const openDeleteModal = async (id: number) => {
    await dispatch(getSelectedAccess(id, params.id))
    setDeleteModalIsOpen(true)
  }

  const removeItem = (isFavorite: boolean) => {
    dispatch(removeAccessThunk(searchValue, isFavorite))
    setDeleteModalIsOpen(false)
  }

  const likeEvent = (accessID: number, itemLike: boolean, isFavorite: boolean) => {
    dispatch(likeEventThunk(accessID, params.id, itemLike, searchValue, isFavorite))
  }

  return {
    closeModals,
    copyAccess,
    openEditModal,
    openDeleteModal,
    removeItem,
    likeEvent,
    editModalIsOpen,
    deleteModalIsOpen,
    addModalIsOpen,
    setEditModalIsOpen,
    setAddModalIsOpen,
    searchValue,
    setSearchValue
  }
}
