import React, { FC } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"

type PropsType = {
  items: any[]
}
const RelatedItems: FC<PropsType> = ({ items }) => (
  <div className="post__bottomCol post__related">
    <h5>Связанные статьи</h5>
    {items.map((item) => (
      <div className="post__relatedItem" key={item.name + Math.random()}>
        <NavLink to={item.href} className="post__relatedLink" title={item.name}>
          {item.name}
        </NavLink>
      </div>
    ))}
  </div>
)

RelatedItems.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired
}

export default RelatedItems
