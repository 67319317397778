import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import { SelectedSectionItemsType } from "../../../_types"

type ListPropsType = {
  item: SelectedSectionItemsType
  event: (e: React.MouseEvent | React.KeyboardEvent) => void
  isOpen: boolean
}
type ItemType = {
  item: { name: string; href: string }
}

const ArticlesItem: FC<ItemType> = ({ item }) => (
  <div className="articles__listItem">
    <NavLink to={item.href} title={item.name} className="articles__listLink">
      {item.name}
    </NavLink>
  </div>
)

const ArticlesList: FC<ListPropsType> = ({ item, event, isOpen }) => {
  return (
    <div
      className={classNames({
        "articles__elem js-article-elem ": true
      })}
    >
      <div className="articles__caption" role="button" tabIndex={0} onKeyPress={(e) => event(e)} onClick={event}>
        {item.name}
      </div>
      <div
        className={classNames({
          "articles__list js-articles-list": true,
          "show-list": isOpen
        })}
      >
        <div className="articles__listWrap js-articles-list-wrap">
          {item.items.map((element, key) => (
            <ArticlesItem item={element} key={key} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ArticlesList
