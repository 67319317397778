import { useState } from "react"
import { useDispatch } from "react-redux"
import { getReportThunk } from "../../redux/reducers/tasksReducer"
import { ReportsRequestType } from "../../_types"

export const useReport = () => {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(false)
  const fetchReport = async (projectObj: ReportsRequestType) => {
    try {
      await setLoad(false)
      await dispatch(getReportThunk(projectObj))
      await setLoad(true)
    } finally {
      setLoad(false)
    }
  }
  return { load, fetchReport }
}
