import React, { useState } from "react"
import TasksItems from "./TasksItems"
import { SupportTasksInfo } from "./SupportTasksInfo"
import { useSelector } from "react-redux"
import { AppStateType } from "../../redux/store"
import { SupportInfo } from "./SupportInfo"
import { SupportTabs } from "./SupportTabs"
import { Calendar } from "./Calendar"
import { SupportGraf } from "./SupportGraf"
import { isNotEmpty } from "../../helpers/isNotEmpty"
import { DateFilterType, SupportBurndownType } from "../../_types"
import SelectRender from "../../components/other/Select"
import { useSupport } from "../../hooks/Support/useSupport"
import FallBack from "../../components/other/FallBack"
import ChangeTimeBlock from "./ChangeTimeBlock"

const CALENDAR = "календарь"
const REFERENCE = "справка"
const CHART = "график"
const SupportCalendar = () => {
  const { info, projects, tasksToControl, dateFilter, tasksEmptyDeadline, supportBurndown } = useSelector(
    (state: AppStateType) => state.tasks.support
  )
  const [tabContentShow, setTabContentShow] = useState("календарь")
  const { fetchData } = useSupport()
  const [loadSupport, setLoadSupport] = useState(false)
  const currentMonthLoad = dateFilter.find((item) => item.current)
  const currentMonth = dateFilter.filter((item) => item.current)
  const [hoursMinutesView, setHoursMinutesView] = useState(false)
  const [month, setMonth] = useState(`${currentMonthLoad?.month} ${currentMonthLoad?.year}`)
  const changeDate = async (date: any) => {
    await setMonth(date.label)
    await setLoadSupport(true)
    await fetchData(date.value)
    await setLoadSupport(false)
  }
  const dateFilterSupport = dateFilter.map((item) => ({
    label: `${item.month} ${item.year}`,
    value: item.value,
    current: item?.current
  }))

  const getSelectValue = (current: DateFilterType[], date: string) => {
    return current.map((item) => ({ value: item.value, label: date }))
  }
  return (
    <>
      <SupportTabs setTabContentShow={setTabContentShow} tabContentShow={tabContentShow} />
      {tabContentShow !== REFERENCE && (
        <div className="wrapper">
          <div className="support-select-wrapper">
            <SelectRender
              onChange={changeDate}
              select={dateFilterSupport}
              value={getSelectValue(currentMonth, month)}
            />

            {tabContentShow === CALENDAR && (
              <ChangeTimeBlock hoursMinutesView={hoursMinutesView} setHoursMinutesView={setHoursMinutesView} />
            )}
          </div>
        </div>
      )}

      {loadSupport ? (
        <FallBack />
      ) : (
        <div>
          {tabContentShow === CALENDAR ? (
            <Calendar
              // @ts-ignore
              currentDate={currentMonthLoad.value}
              hoursMinutesView={hoursMinutesView}
              dateFilter={dateFilter}
              projects={projects}
            />
          ) : tabContentShow === CHART ? (
            supportBurndown.map((item: SupportBurndownType, index: number) => {
              return (
                <div key={index} className="wrapper">
                  <SupportGraf key={index} supportBurndown={item} />
                </div>
              )
            })
          ) : tabContentShow === REFERENCE ? (
            <SupportInfo info={info} />
          ) : null}
        </div>
      )}
      {!loadSupport && (
        <div>
          {isNotEmpty(tasksToControl.tasks) && tasksToControl ? (
            <>
              <SupportTasksInfo infoBlock={tasksToControl} />
              <TasksItems infoBlock={tasksToControl} />
            </>
          ) : null}
          {isNotEmpty(tasksEmptyDeadline.tasks) && tasksEmptyDeadline.tasks ? (
            <>
              <SupportTasksInfo infoBlock={tasksEmptyDeadline} />
              <TasksItems infoBlock={tasksEmptyDeadline} />
            </>
          ) : null}
        </div>
      )}
    </>
  )
}

export default SupportCalendar
