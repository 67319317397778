import { useState } from "react"
import { TaskType, UserType } from "../../_types"
import { filterByUserStatus } from "../../helpers/filterByUserStatus"
import { filterByStatus } from "../../helpers/filterByStatus"
import { DEFAULT_FILTER_VALUE } from "./useContent"

export type SelectedUserType = {
  name: string
  id: number | null
}

export const useUsers = (users: UserType[]) => {
  const [selectedUser, setSelectedUser] = useState<SelectedUserType>({
    name: "",
    id: null
  })
  const setUserOnUserClick = ({
    id,
    tasks,
    isAutoClosePage,
    selectedFilter,
    setTasks
  }: {
    id: number
    tasks: TaskType[]
    isAutoClosePage: boolean
    selectedFilter: number
    setTasks: any
  }) => {
    const selUser = users.find((user) => user.id === id)
    let selUserTasks = tasks.filter((task) => filterByUserStatus(task, id, { isAutoClosePage }))

    if (selectedFilter !== DEFAULT_FILTER_VALUE) {
      selUserTasks = selUserTasks.filter((task) => filterByStatus(task, selectedFilter))
    }
    if (selUser) {
      setUser({ name: ` ${selUser.lastName} ${selUser.name}`, id: selUser.id })
    }
    if (selUserTasks) {
      setTasks(selUserTasks)
    }
  }
  const setUser = (selUser?: SelectedUserType) => {
    if (!selUser) {
      setSelectedUser({ name: "", id: null })
      return
    }
    setSelectedUser(selUser)
  }
  const getTotalCount = (usersList: UserType[]) => {
    let totalCount = 0
    /* eslint-disable */
    usersList
      .filter((user) => parseInt(user.taskCount, 10) > 0)
      .forEach((item) => (totalCount += parseInt(item.taskCount, 10)))
    return totalCount
  }
  return { getTotalCount, selectedUser, setUser, setUserOnUserClick }
}
