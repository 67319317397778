import React, { FC } from "react"
import { SupportProjectType } from "../../_types"
import classNames from "classnames"
import { useReport } from "../../hooks/Support/useReport"
import { useForecast } from "../../hooks/Support/useForecast"
import { timeFromMins } from "../../helpers/timeFromMins"

type SupportProjectTypeItem = {
  project: SupportProjectType
  hoursMinutesView?: boolean
  currentDate: string
}

export const SupportCalendarMainItem: FC<SupportProjectTypeItem> = ({ project, hoursMinutesView, currentDate }) => {
  const { fetchReport } = useReport()
  const { fetchForecast } = useForecast()
  return (
    <tr>
      <td
        className={classNames({
          checkTD: project.timeClosed >= project.tariff && project.id !== 0
        })}
      >
        <div className="itemDetail">
          <div className="itemDetail-wrapper">
            <div className="itemDetail__name">{project.name}</div>
            <div className="itemDetail__task">
              <ins className="desktop"> Сделано задач:&nbsp;</ins>
              <span>{project.tasksCountClose}&nbsp;</span>
              из
              <span>&nbsp;{project.tasksCountAll}</span>
              <ins className="mobile">&nbsp;задач</ins>
            </div>
          </div>
          {project.id !== 0 && (
            <div className="itemDetail-group">
              <div className="itemDetail__icon">
                <svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.589 4.132a1.084 1.084 0 01-1.082-1.083V0H1.742C.782 0 0 .782 0 1.742v12.453c0 .961.782 1.742 1.742 1.742h10.212c.96 0 1.742-.781 1.742-1.742V4.132H10.59zm-1.067 7.946H3.508a.467.467 0 110-.934h6.014a.467.467 0 110 .934zM3.04 9.743c0-.258.209-.467.467-.467h5.366a.467.467 0 110 .934H3.508a.467.467 0 01-.467-.467zm7.048-1.4h-6.58a.467.467 0 110-.935h6.581a.467.467 0 110 .934z"
                    fill="#B6C2E2"
                  />
                </svg>
              </div>
              <div className="itemDetail-group-inner">
                <div
                  className="itemDetail-group-inner__item"
                  onClick={() => fetchReport({ value: currentDate, projectId: project.id })}
                >
                  <svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.589 4.132a1.084 1.084 0 01-1.082-1.083V0H1.742C.782 0 0 .782 0 1.742v12.453c0 .961.782 1.742 1.742 1.742h10.212c.96 0 1.742-.781 1.742-1.742V4.132H10.59zm-1.067 7.946H3.508a.467.467 0 110-.934h6.014a.467.467 0 110 .934zM3.04 9.743c0-.258.209-.467.467-.467h5.366a.467.467 0 110 .934H3.508a.467.467 0 01-.467-.467zm7.048-1.4h-6.58a.467.467 0 110-.935h6.581a.467.467 0 110 .934z"
                      fill="white"
                    />
                  </svg>
                  <div> Отчёт</div>
                </div>
                <div
                  className="itemDetail-group-inner__item"
                  onClick={() => fetchForecast({ value: currentDate, projectId: project.id })}
                >
                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9 2.9H13.7L10.7 0V2.8C10.7 2.9 10.8 2.9 10.9 2.9Z" fill="white" />
                    <path
                      d="M10.6 4.1C10 4.1 9.5 3.6 9.5 3V0H1.7C0.8 0 0 0.8 0 1.7V14.2C0 15.2 0.8 15.9 1.7 15.9H12C13 15.9 13.7 15.1 13.7 14.2V4.1H10.6ZM10 8.1L6 12.1C5.9 12.2 5.8 12.2 5.6 12.2C5.4 12.2 5.3 12.1 5.2 12.1L3.3 10C3.1 9.8 3.1 9.5 3.3 9.3C3.5 9.1 3.8 9.1 4 9.3L5.7 11L9.3 7.4C9.5 7.2 9.8 7.2 10 7.4C10.2 7.6 10.2 7.9 10 8.1Z"
                      fill="white"
                    />
                  </svg>
                  <div>Прогноз</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
      <td>{hoursMinutesView ? timeFromMins(project.tariff * 60) : project.tariff}</td>
      <td>{hoursMinutesView ? timeFromMins(project.timeClosed * 60) : project.timeClosed}</td>
      <td className="bg-gray">{hoursMinutesView ? timeFromMins(project.timeEstimate * 60) : project.timeEstimate}</td>
      <td className="bg-gray">{hoursMinutesView ? timeFromMins(project.timeLeft * 60) : project.timeLeft}</td>
      <td className="bg-gray">{hoursMinutesView ? timeFromMins(project.timeExceeded * 60) : project.timeExceeded}</td>
      <td className="bg-gray">{hoursMinutesView ? timeFromMins(project.timeToControl * 60) : project.timeToControl}</td>
    </tr>
  )
}
