import classNames from "classnames"
import React, { FC } from "react"

type PropsType = {
  totalCount: number
  onItemClick: (id: number) => void
}
/*eslint-disable*/
export const AllUsersLabel: FC<PropsType> = ({ totalCount, onItemClick }) => (
  <label className="tasksUsers__item">
    <input type="radio" name="users__tasks" defaultChecked={true} onClick={() => onItemClick(-1)} />
    <div
      className={classNames({
        tasksUsers__itemWrap: true,
        "tasksUsers__itemWrap--all": true
      })}
    >
      <div className="tasksUsers__itemName">Все</div>
      <div className="tasksUsers__itemCount">{totalCount}</div>
    </div>
  </label>
)
