import React, { FC } from "react"
import { NavLink } from "react-router-dom"
// @ts-ignore
import InlineSVG from "svg-inline-react"
import { MenuType } from "../../../redux/reducers/menuRudecer"

type PropsType = {
  item: MenuType
}

const MykritlabMenuReactItem: FC<PropsType> = ({ item }) => (
  <div className="menuSectionList__item">
    <NavLink to={item.href} className="menuSectionList__itemWrap">
      <div className="menuSectionList__itemContent">
        <div className="menuSectionList__itemIcon">
          <InlineSVG src={item.image} />
        </div>
        <div className="menuSectionList__itemCaption">{item.name}</div>
      </div>
    </NavLink>
  </div>
)

export default MykritlabMenuReactItem
