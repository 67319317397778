import { toast } from "react-hot-toast"

export const hotToast = (text: string, icon = "🦄") => {
  const Toast = () => {
    toast(text, {
      icon,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff"
      }
    })
  }
  Toast()
}
