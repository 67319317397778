import React, { FC, useEffect, useRef, useState } from "react"
import DatePicker, { registerLocale } from "react-datepicker/"
import "react-datepicker/dist/react-datepicker.css"
import OpenListSvg from "../../components/svg/OpenListSvg"
import { ru } from "date-fns/esm/locale"
import classNames from "classnames"
import ResetSvg from "../../components/svg/ResetSvg"
import { useGetTasks } from "./useGetTasks"
import { useSetDate } from "./useSetDate"
import { isNotEmpty } from "../../helpers/isNotEmpty"

type FactLoadNavType = {
  openMobileViewTabs: () => void
  showMobileView: boolean
  checkedUsers: number[]
}

const FactLoadNav: FC<FactLoadNavType> = ({ openMobileViewTabs, showMobileView, checkedUsers }) => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [resetDate, setResetDate] = useState(false)
  const { getTasks } = useGetTasks()
  const { setDate } = useSetDate()
  const ref = useRef(null)
  const submitDatePicker = () => {
    // @ts-ignore
    ref.current.setOpen(false)
    setDate(startDate)
    if (isNotEmpty(checkedUsers)) getTasks(checkedUsers, startDate)
  }
  const clearDatePicker = (e: any) => {
    e.stopPropagation()
    setResetDate(true)
  }
  useEffect(() => {
    if (resetDate) setStartDate(new Date())
  }, [resetDate])

  registerLocale("ru", ru)

  return (
    <div className="factLoadCosts__nav">
      <div className="df-wrapper">
        <DatePicker
          ref={ref}
          onKeyDown={submitDatePicker}
          onChange={(date: Date) => {
            setStartDate(date)
            setResetDate(false)
          }}
          dateFormat="dd.MM.yyyy"
          shouldCloseOnSelect={false}
          locale={"ru"}
          selected={startDate}
        >
          <div className="react-datepicker__bottom">
            <div className="react-datepicker__bottom-day">
              <div>Выбрано</div>
              <div className="selected">{startDate.toLocaleDateString()}</div>
            </div>
            <div className="react-datepicker__bottom-reset" onClick={(e) => clearDatePicker(e)}>
              <ResetSvg />
            </div>
          </div>
          <div className="react-datepicker-accept" onClick={submitDatePicker}>
            Готово
          </div>
        </DatePicker>

        <div
          className={classNames({
            "factLoadCosts__nav-mobileBtn": true,
            "-show": showMobileView
          })}
          onClick={openMobileViewTabs}
        >
          <OpenListSvg />
        </div>
      </div>
    </div>
  )
}

export default FactLoadNav
