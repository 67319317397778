import React, { FC } from "react"
import BurgerIcon from "./Icons/BurgerIcon"
import { CopyIcon } from "./Icons/CopyIcon"
import { DeleteIcon } from "./Icons/DeleteIcon"
import LikeIconNew from "./Icons/LikeIconNew"
import AccessIcon from "./Icons/AccessIcon"
import ViewIcon from "./Icons/ViewIcon"
import classNames from "classnames"
import { useCopyRowClick } from "../../../../hooks/useCopyRowClick"
import { useAccessDetailContext } from "../context/useAccessDetailContext"
import { SelectedAccessItemType } from "../../../../_types"

import store from "../../../../redux/store"

type BurgerType = {
  item: SelectedAccessItemType
  liked?: boolean
}

const AccessDetailItemBurger: FC<BurgerType> = ({ item, liked }) => {
  const { openDeleteModal, openEditModal, copyAccess } = useAccessDetailContext()
  const { likeEvent, favorite } = useAccessDetailContext()

  const copyClick = async (e: React.MouseEvent) => {
    await copyAccess(item.id)
    const selectedAccess = store.getState().accesses.selectedAccess
    const { copyRowClick } = useCopyRowClick(selectedAccess)
    return copyRowClick(e)
  }

  return (
    <div className="burger">
      <div className="burger-head">
        <div className="burger__icon">
          <BurgerIcon />
        </div>
      </div>
      <div className="burger__content">
        {/* <div className="burger__content-item" onClick={(e) => copyRowClick(e)}>
          <div className="burger__content-text nopointer" data-value={item.type} />
        </div> */}

        {item.options && item.options.copy.availability && (
          <div className="burger__content-item" onClick={(e) => copyClick(e)}>
            <button className="accessDetail__icon copyIcon" type="button">
              <CopyIcon />
            </button>
            <div className="burger__content-text" data-value={"Копировать"} />
          </div>
        )}

        <div className="burger__content-item" onClick={() => openEditModal(item.id)}>
          <button
            className={classNames({
              "accessDetail__icon editIcon": true
            })}
            type="button"
          >
            <ViewIcon />
          </button>
          <div className="burger__content-text" data-value={"Посмотреть"} />
        </div>
        {item.options && item.options.access.availability && (
          <a className="burger__content-item" href={item.options?.access?.link} target="_blank" rel="noreferrer">
            <button className="accessDetail__icon" type="button">
              <AccessIcon />
            </button>
            <div className="burger__content-text" data-value={"Дать доступ"} />
          </a>
        )}

        {!liked && (
          <div className="burger__content-item" onClick={() => likeEvent(item.id, item.like, favorite)}>
            <button
              className={classNames({
                accessDetail__icon: true,
                likeIcon: true,
                "likeIcon--liked": item.like
              })}
              type="button"
            >
              <LikeIconNew fill={item.like ? "#ff4f8f" : "none"} />
            </button>
            <div className="burger__content-text" data-value={item.like ? "Из избранного" : "В избранное"} />
          </div>
        )}
        {item.options && item.options.delete.availability && (
          <div className="burger__content-item burger__content-item--delete" onClick={() => openDeleteModal(item.id)}>
            <button
              className={classNames({
                "accessDetail__icon deleteIcon": true
              })}
              type="button"
            >
              <DeleteIcon />
            </button>
            <div className="burger__content-text" data-value={"Удалить"} />
          </div>
        )}
      </div>
    </div>
  )
}

export default AccessDetailItemBurger
