import { useEffect, useState } from "react"
import { TaskType } from "../../_types"
import { DEFAULT_FILTER_VALUE } from "./useContent"
import { filterByStatus } from "../../helpers/filterByStatus"
import { filterByUserStatus } from "../../helpers/filterByUserStatus"

export type TaskSelectType = {
  value: number
  label: string
}
export type FilterObjType = {
  onFilterChange: (value: number, id?: any) => void
  selectValues: TaskSelectType[]
  selectedFilter: number
}
export const useSelectFilter = (
  tasks: TaskType[],
  setTasks: (selTasks?: TaskType[] | undefined) => void,
  isAutoClosePage: boolean,
  isMigrationPage: boolean
) => {
  const [selectValues, setSelectValues] = useState<TaskSelectType[]>([])
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTER_VALUE)

  const buildSelectValues = (arr: number[]) =>
    arr.map((value) => {
      switch (value) {
        case 2:
          return { value, label: "Ждет выполнения" }
        case 3:
          return { value, label: "Выполняется" }
        case 4:
          return { value, label: "Завершена" }
        case 5:
          return { value, label: "Принята" }
        default:
          return { value: -1, label: "Все" }
      }
    })
  const onFilterChange = (value: number, id?: number) => {
    if (value === DEFAULT_FILTER_VALUE) {
      if (!id) {
        setTasks(tasks)
      } else {
        setTasks(tasks.filter((task) => filterByUserStatus(task, id, { isAutoClosePage, isMigrationPage })))
      }
      setSelectedFilter(value)
      return
    }

    let result = tasks.filter((task) => filterByStatus(task, value))
    if (id) {
      result = result.filter((task) => filterByUserStatus(task, id, { isAutoClosePage, isMigrationPage }))
    }
    setTasks(result)
    setSelectedFilter(value)
  }
  useEffect(() => {
    const values = tasks.map((task) => task.statusId)
    // @ts-ignore
    const removeDuplicates = () => [...new Set(values)]
    setSelectValues([{ value: -1, label: "Все" }, ...buildSelectValues(removeDuplicates())])
  }, [])

  const filter = {
    selectValues,
    onFilterChange,
    selectedFilter
  }

  return { filter }
}
