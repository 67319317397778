import { useSelector } from "react-redux"
import { AppStateType } from "../../../redux/store"

export const useFormValues = () => {
  const { host, login, password, port, database, notice, type, id, options, files } = useSelector(
    (state: AppStateType) => state.accesses.selectedAccess
  )
  return {
    host,
    login,
    password,
    port,
    database,
    notice,
    type,
    id,
    options,
    files
  }
}
