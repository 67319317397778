import React, { FC, useEffect } from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import { useGlobalSearch } from "../../hooks/useGlobalSearch"
import { isNotEmpty } from "../../helpers/isNotEmpty"
import { SearchResultType } from "../../_types"

type PropsType = {
  item: SearchResultType
  blur: (event?: any, isLink?: boolean) => void
  result: SearchResultType[]
  activeEl: number
  setActiveEl: any
  clickEvent: () => void
}
const SearchResult: FC<PropsType> = ({ item, blur, result, activeEl, setActiveEl, clickEvent }) => {
  const getActiveClass = () => {
    if (activeEl < 0) {
      return setActiveEl(result.length - 1)
    }
    if (activeEl > result.length - 1) {
      return setActiveEl(0)
    }
    return result[activeEl].id === item.id
  }

  useEffect(() => {
    getActiveClass()
  }, [result, activeEl, item, blur])
  const handlerSearch = (event: any) => {
    blur(event, true)
    clickEvent()
  }
  return (
    <NavLink
      to={item.href}
      className={classNames({
        "search__suggestsItemLink search__suggestsItem": true,
        keyboard_focus: getActiveClass()
      })}
      title={item.name}
      onClick={(event) => handlerSearch(event)}
    >
      <div>{item.name}</div>
    </NavLink>
  )
}
const Search: FC<Props> = ({ clickEvent }) => {
  const { handleFocus, handleBlur, handleChange, searchResult, inputValue, setActiveEl, activeEl, keyDownHandler } =
    useGlobalSearch()

  const renderItems = () => (
    <div className="search__suggests">
      <div className="search__suggestsList">
        {searchResult.map((item) => (
          <SearchResult
            setActiveEl={setActiveEl}
            item={item}
            result={searchResult}
            activeEl={activeEl}
            blur={handleBlur}
            clickEvent={clickEvent}
            key={item.id}
          />
        ))}
      </div>
    </div>
  )
  return (
    <div
      className={classNames({
        "header__search search": true,
        "-active": isNotEmpty(searchResult)
      })}
      onBlur={(event) => handleBlur(event)}
    >
      <div className="search__form search">
        <input
          type="text"
          placeholder="Поиск..."
          value={inputValue}
          onChange={(e) => handleChange(e)}
          className={classNames({
            search__input: true,
            "border-active": isNotEmpty(searchResult)
          })}
          onFocus={handleFocus}
          onKeyDown={(event: any) => keyDownHandler(event)}
        />
        <div className="search__btn search">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M18.7886 17.8006L14.25 13.2383C15.4494 11.8038 16.131 9.97025 16.131 8.0655C16.131 3.62188 12.5091 0 8.0655 0C3.62188 0 0 3.62188 0 8.0655C0 12.5091 3.62188 16.131 8.0655 16.131C9.97025 16.131 11.8275 15.4494 13.2383 14.25C13.243 14.2476 13.2478 14.2429 13.2525 14.2405L17.8006 18.7886C17.9408 18.9287 18.1307 19 18.2946 19C18.4585 19 18.6485 18.9287 18.7886 18.7886C19.0712 18.506 19.0712 18.0595 18.7886 17.8006ZM8.0655 14.7202C4.39613 14.7202 1.41075 11.7349 1.41075 8.0655C1.41075 4.39613 4.39613 1.41075 8.0655 1.41075C11.7349 1.41075 14.7202 4.39613 14.7202 8.0655C14.7202 9.83012 14.0149 11.5449 12.768 12.768C11.5449 14.0149 9.83012 14.7202 8.0655 14.7202Z" />
              <path d="M5.26742 4.35111C4.9848 4.09223 4.56205 4.06848 4.27942 4.35111C3.26767 5.36286 2.74992 6.79736 2.89242 8.25561C2.93992 8.60948 3.2463 8.88973 3.5978 8.88973C3.62155 8.88973 3.6453 8.88973 3.66905 8.88973C4.0443 8.84223 4.35067 8.51448 4.30317 8.11311C4.20817 7.07761 4.56205 6.06823 5.26742 5.33911C5.55005 5.05648 5.55005 4.60998 5.26742 4.35111Z" />
            </g>
          </svg>
        </div>
      </div>
      {isNotEmpty(searchResult) && renderItems()}
    </div>
  )
}

export default Search

type Props = {
  clickEvent: () => void
}
