import { useState } from "react"
import { SelectedUserType } from "./useUsers"
import { ProjectsType, TaskType } from "../../_types"
import { filterByUserStatus } from "../../helpers/filterByUserStatus"
import { DEFAULT_FILTER_VALUE } from "./useContent"
import { filterByStatus } from "../../helpers/filterByStatus"

export const useProjects = (projects: ProjectsType[]) => {
  const [selectedProject, setSelectedProject] = useState<SelectedUserType>({
    name: "",
    id: null
  })

  const setProjectOnProjectClick = ({
    id,
    tasks,
    isMigrationPage,
    selectedFilter,
    setTasks
  }: {
    id: number
    tasks: TaskType[]
    isMigrationPage: boolean
    selectedFilter: number
    setTasks: any
  }) => {
    const selProject = projects.find((project) => project.id === id)
    let selProjectTasks = tasks.filter((task) =>
      filterByUserStatus(task, id, {
        isMigrationPage: true,
        isAutoClosePage: false
      })
    )

    if (selectedFilter !== DEFAULT_FILTER_VALUE) {
      selProjectTasks = selProjectTasks.filter((task) => filterByStatus(task, selectedFilter))
    }
    if (selProject) {
      setProject({
        name: ` ${selProject.name}`,
        id: selProject.id
      })
    }
    if (selProjectTasks) {
      setTasks(selProjectTasks)
    }
  }

  const setProject = (selProject?: SelectedUserType) => {
    if (!selProject) {
      setSelectedProject({ name: "", id: null })
      return
    }
    setSelectedProject(selProject)
  }
  const getProjectsTotalCount = (projectsList: ProjectsType[] | TaskType[]) => projectsList.length

  return {
    getProjectsTotalCount,
    setProject,
    selectedProject,
    setProjectOnProjectClick
  }
}
