import React, { FC } from "react"
import { SupportCalendarMain } from "./SupportCalendarMain"
import { DateFilterType, SupportProjectType } from "../../_types"

export type SupportType = {
  projects: SupportProjectType[]
  dateFilter: DateFilterType[]
  hoursMinutesView: boolean
  currentDate: string
}

export const Calendar: FC<SupportType> = ({ projects, dateFilter, hoursMinutesView, currentDate }) => {
  return (
    <>
      <div className="supportCalendar">
        <div className="wrapper"></div>
        <SupportCalendarMain currentDate={currentDate} hoursMinutesView={hoursMinutesView} projects={projects} />
      </div>
    </>
  )
}
