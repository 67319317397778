import { useEffect, useState } from "react"

export const useScrollHeight = (ref?: any) => {
  const [scrollHeight, setScrollHeight] = useState(0)
  const [down, setDown] = useState(1000)
  const [isHead, setIsHead] = useState(false)
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    setDown(1000)
  }
  const scrollDown = () => {
    window.scrollTo({ top: down, behavior: "smooth" })
    setDown(down + 1000)
  }
  useEffect(() => {
    const updateScrollHeight = () => {
      const newHeight = window.pageYOffset
      setScrollHeight(newHeight)
      const refCoordinates = ref?.current.getBoundingClientRect()
      refCoordinates?.y === 0 ? setIsHead(true) : setIsHead(false)
    }

    window.addEventListener("scroll", updateScrollHeight)
    return () => window.removeEventListener("scroll", updateScrollHeight)
  }, [])
  return { scrollHeight, scrollDown, scrollToTop, isHead }
}
