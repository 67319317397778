import React, { useEffect } from "react"
import BreadCrumbs from "../../../components/other/BreadCrumbs/BreadCrumbs"
import Detail from "./Detail"
import FallBack from "../../../components/other/FallBack"
import { useArticlesDetail } from "../../../hooks/Wiki/wikiDetail/useArticlesDetail"
import { useWikiDetail } from "../../../hooks/Wiki/wikiDetail/useWikiDetail"
import { EmptyRequest } from "../../../components/Empty/EmptyRequest"

const MykritlabDetail = () => {
  const { breadCrumbs, detailFavoriteClick } = useArticlesDetail()
  const { fetchData, load, selectedArticle, id } = useWikiDetail()

  useEffect(() => {
    fetchData()
  }, [id])

  if (load) {
    return <FallBack />
  }
  return (
    <div>
      {selectedArticle.name ? (
        <>
          <div className="whiteBack">
            <div className="wrapper">
              <div className="d-sm-flex">
                <BreadCrumbs breadCrumbs={breadCrumbs} lastItem={selectedArticle.name} />
              </div>
            </div>
          </div>

          <div className="wrapper">
            <Detail selectedArticle={selectedArticle} detailFavoriteClick={detailFavoriteClick} />
          </div>
        </>
      ) : (
        <div className="mt30">
          <EmptyRequest className="center" text="Ничего не найдено" title="404" icon={null} />
        </div>
      )}
    </div>
  )
}

export default MykritlabDetail
