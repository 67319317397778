import React, { FC, Fragment } from "react"
import { NavLink } from "react-router-dom"
import { BreadCrumbsType } from "../../../_types"
import { BreadCrumbArrow } from "./BreadCrumbArrow"

type PropsType = {
  breadCrumbs: BreadCrumbsType[]
}
const BreadCrumbsWithData: FC<PropsType> = ({ breadCrumbs }) => (
  <>
    {breadCrumbs.map((item, key) => (
      <Fragment key={key}>
        <li className="breadCrumbs__item">
          <NavLink to={item.href} title={item.label} className="breadCrumbs__link">
            {item.label}
          </NavLink>
        </li>
        <BreadCrumbArrow />
      </Fragment>
    ))}
  </>
)
export default BreadCrumbsWithData
