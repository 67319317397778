import React, { FC, useState } from "react"

export interface ICheckox {
  label: string
  name: string
  event: (a?: any) => void
  defaultValue?: boolean | null
  onClick?: () => void
}

const Checkbox: FC<ICheckox> = ({ label, name, event, defaultValue, onClick }) => {
  const initialState = localStorage.getItem(name)?.includes("true")
  const [value, setValue] = useState(Boolean(defaultValue) || initialState)
  const requestCheckbox = async (e: any) => {
    await setValue(!value)
    await event(!value)
    localStorage.setItem(name, `${Number(!value)}`)
  }
  return (
    <label htmlFor={name} className="checkbox" onClick={onClick}>
      <input defaultChecked={value} id={name} name={name} type="checkbox" onChange={(e) => requestCheckbox(e)} />
      <span />
      <ins>{label}</ins>
    </label>
  )
}

export default Checkbox
