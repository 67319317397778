import React, { FC } from "react"
import classNames from "classnames"
// @ts-ignore, нет либы с типами для svg-inline-react
import InlineSVG from "svg-inline-react"
import PerfectScrollbar from "react-perfect-scrollbar"

import { useSelector } from "react-redux"
import { getSuffix } from "../../../../components/modules/util"
import { VotingItemsType } from "./Voiting"
import { AppStateType } from "../../../../redux/store"

type ItemPropsType = {
  item: VotingItemsType
  setVote: (id: number) => void
  articleID: number
}
const VoitingItemUsers: FC<{ item: string }> = ({ item }) => <div className="post__ratingItemUsersItem">{item}</div>

const VoitingItem: FC<ItemPropsType> = ({ item, setVote, articleID }) => {
  const { name } = useSelector((state: AppStateType) => state.authPage.user)
  let likeCount = 0
  let likeText = "голосов"
  const titles = ["голос", "голоса", "голосов"]

  if (item.users) {
    likeCount = item.users.length
    likeText = getSuffix(titles, likeCount)
  }
  const isItMyVote = item.users.length === 1 && item.users[0].includes(name)

  return (
    <div
      className={classNames({
        post__ratingItem: true,
        "post__ratingItem--dislike": item.voteID !== 1,
        "post__ratingItem--like": item.voteID === 1
      })}
    >
      <div
        className={classNames({
          post__ratingItemWrap: true,
          "post__ratingItem--voted": item.isVoted
        })}
        data-id={item.voteID}
        onClick={() => setVote(articleID)}
      >
        <div className="post__ratingItemIcon">
          <InlineSVG src={item.icon} />
        </div>
        <div className="post__ratingItemText">{item.text}</div>
        <div className="post__ratingItemLike">
          <span className="js-count-rating">{likeCount} </span>
          <span className="js-count-text">{likeText}</span>
        </div>
      </div>
      {item.users.length > 0 && !isItMyVote && (
        <div className="post__ratingItemUsers">
          <div className="post__ratingItemUsersCaption">Проголосовали так же:</div>
          <PerfectScrollbar>
            <div className="post__ratingItemUsersWrap">
              {item.users.map((elem) => (
                <VoitingItemUsers item={elem} key={elem + Math.random()} />
              ))}
            </div>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  )
}

export default VoitingItem
