import React, { FC } from "react"
import classNames from "classnames"
import { TaskType } from "../../../_types"
import { useWindowWidth } from "../../../hooks/Accesses/useWindowWidth"
import SelectRender from "../../../components/other/Select"
import { FilterObjType } from "../../../hooks/Tasks/useSelectFilter"
import { useTasksItems } from "../../../hooks/Tasks/useTasksItems"
import { TabTitleType } from "../../../hooks/Tasks/useTabTitle"
import { isNotEmpty } from "../../../helpers/isNotEmpty"
import { EmptyRequest } from "../../../components/Empty/EmptyRequest"
import { EmptyTaskListIcon } from "./EmptyTaskListIcon"
import { SelectedUserType } from "../../../hooks/Tasks/useUsers"

export const TasksItem: FC<{ item: TaskType }> = ({ item }) => {
  const { width } = useWindowWidth()
  return (
    <div
      className={classNames({
        tasksItems__item: true,
        "tasksItems__item--active": item.statusId === 2 || item.statusId === 3
      })}
    >
      <a href={item.link} target="_blank" className="tasksItems__itemWrap" rel="noreferrer">
        <div className="tasksItems__itemRow">
          <div className="tasksItems__itemTitle">{item.name}</div>
          <div className="tasksItems__itemCode">{item.id}</div>
        </div>
        <div className="tasksItems__itemRow">
          <div className="tasksItems__itemData tasksData">
            <div className="tasksData__item">
              <div className="tasksData__itemCaption">Ответственный:</div>
              <div className="tasksData__itemName">{item.responsible}</div>
            </div>
            <div className="tasksData__item tasksData__item--mobile">
              {width < 1024 ? (
                <div className="tasksData__itemCaption tasksData__itemCaption--mobile">Пост.:</div>
              ) : (
                <div className="tasksData__itemCaption">Постановщик:</div>
              )}
              <div className="tasksData__itemName">{item.creator}</div>
            </div>
          </div>
          <div className="tasksItems__itemStatus tasksStatus">
            <div
              className={classNames({
                tasksStatus__icon: true,
                "tasksStatus__icon--ready": item.statusId === 5,
                "tasksStatus__icon--control": item.statusId === 0,
                "tasksStatus__icon--postponed": item.statusId === 4
              })}
            >
              {item.status}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
type TasksItemPropsType = {
  items: TaskType[]
  responsible: string
  filter: FilterObjType
  hasUsers: boolean
  title: TabTitleType
  selectedUserId: number | null
  selectedProject: SelectedUserType
}
export const TasksItems: FC<TasksItemPropsType> = ({
  items,
  responsible,
  filter,
  hasUsers,
  title,
  selectedUserId,
  selectedProject
}) => {
  const { noUsersResponsible, onFilterChange, getValue, selectValues, isAutoClosePage, isMigrationPage } =
    useTasksItems(filter, title)
  return (
    <div className="tasksContent__items tasksItems">
      {isMigrationPage ? (
        <div className="tasksItems__title title">Проект: {selectedProject.name ? selectedProject.name : "Все"}</div>
      ) : (
        <div className="tasksItems__title title">
          {isAutoClosePage ? "Постановщик" : "Ответственный"}:&nbsp;
          <span>{hasUsers ? responsible : noUsersResponsible}</span>
        </div>
      )}
      <div className="tasksItems__sort">
        <span className="tasksItems__sortTitle">Статус задачи:</span>
        <SelectRender
          select={selectValues}
          value={getValue(selectValues)}
          onChange={({ value }) => onFilterChange(value, selectedUserId || selectedProject.id)}
        />
      </div>
      <div className={`tasksItems__list ${!isNotEmpty(items) && "jcc"}`}>
        {isNotEmpty(items) ? (
          items.map((elem: any) => <TasksItem item={elem} key={elem.id} />)
        ) : (
          <EmptyRequest
            title="Задач не найдено"
            icon={<EmptyTaskListIcon />}
            text="Здесь скоро что-нибудь появится"
            className="fullWidth"
          />
        )}
      </div>
    </div>
  )
}
