import React, { FC } from "react"
import FactUserTask from "./FactUserTask"
import ChristSvg from "../../components/svg/ChristSvg"
import { TaskFactUsersType } from "../../_types"
import { useGetTasks } from "./useGetTasks"
import { timeFromMins } from "../../helpers/timeFromMins"
import { isNotEmpty } from "../../helpers/isNotEmpty"

type FactUserCardType = {
  user: TaskFactUsersType
  setCheckedUsers: (numbers: number[]) => void
  checkedUsers: number[]
  date: Date
}

const FactUserCard: FC<FactUserCardType> = ({ user, setCheckedUsers, checkedUsers, date }) => {
  const { getTasks } = useGetTasks()

  const removeItemHandler = (e: any) => {
    setCheckedUsers([...checkedUsers.filter((item) => item !== Number(user.id))])
    getTasks([...checkedUsers.filter((item) => item !== Number(user.id))])
  }
  return (
    <div className="factLoadCosts__card">
      <div className="factLoadCosts__card-title">{user.name}</div>
      <div className="factLoadCosts__card-tasks">
        {!isNotEmpty(user.tasks) && <div>В указанный период затраченного времени не найдено!</div>}
        {user.tasks.map((project) => (
          <FactUserTask project={project} key={project.id} />
        ))}
      </div>
      <div className="factLoadCosts__card-all">
        Итого за {date.toLocaleDateString()}: {timeFromMins(user.totalTime)} ч.
      </div>
      <div className="factLoadCosts__card-remove" onClick={(e) => removeItemHandler(e)}>
        <ChristSvg />
      </div>
    </div>
  )
}

export default FactUserCard
