import React from "react"
import { NavLink } from "react-router-dom"
import InlineSVG from "svg-inline-react"
import Parser from "html-react-parser"
import classNames from "classnames"

const MenuItem = ({ item, tab }) => (
  <div
    className={classNames({
      tasksMenu__item: true,
      "tasksMenu__item--active": item.href.includes(tab)
    })}
  >
    <NavLink to={item.href} className="tasksMenu__itemLink">
      <div className="tasksMenu__itemImg">
        <InlineSVG src={item.img} />
      </div>
      <div className="tasksMenu__itemText">{Parser(item.title)}</div>
    </NavLink>
  </div>
)

const Menu = ({ tab, items }) => {
  return (
    <div className="tasksMenu">
      <div className="wrapper">
        <div className="tasksMenu__wrap js-scrolled">
          <div>
            <div className="tasksMenu__list">
              {items.map((item) => (
                <MenuItem item={item} tab={tab} key={item.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
