import React, { FC } from "react"

const CrossIcon: FC<{ title?: string }> = ({ title }) => {
  return (
    <span title={title}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="13.0534"
          height="1.08778"
          transform="matrix(0.707147 0.707066 -0.707147 0.707066 1.7688 1.00163)"
          fill="#3262E0"
          stroke="#3262E0"
          strokeWidth="0.5"
        />
        <rect
          width="13.0534"
          height="1.08778"
          transform="matrix(0.707147 -0.707067 0.707147 0.707067 0.999756 10.2312)"
          fill="#3262E0"
          stroke="#3262E0"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
export default CrossIcon
