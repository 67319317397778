import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { AppStateType } from "../../redux/store"
import { TopMenuType } from "../../_types"
import { addFavoriteThunk } from "../../redux/reducers/wikiReducer"
import { useSliderSettings } from "../../helpers/useSliderSettings"

export const useWikiTop = (setShowCategory: (event: React.MouseEvent, name?: string) => void) => {
  const dispatch = useDispatch()
  /* eslint-disable-next-line */
  const [_slider, set_Slider] = useState(false)
  const [listName, setListName] = useState("")
  const { topMenuItems } = useSelector((state: AppStateType) => state.wiki)

  const selectedItem: TopMenuType[] = useSelector((state: AppStateType) => state.wiki.selectedItem) || []
  const settings = useSliderSettings()

  const handleClick = (e: React.MouseEvent, name: string) => {
    e.preventDefault()
    setShowCategory(e, name)
  }

  const handleFavoriteClick = (fav: boolean, id: number, parentName: string | undefined = "defaultvalue") => {
    dispatch(addFavoriteThunk(fav, id, parentName))
  }
  const setSlider = () => {
    if (window.innerWidth < 768 && !_slider) {
      set_Slider(true)
    }
    if (window.innerWidth >= 768 && _slider) {
      set_Slider(false)
    }
  }

  useEffect(() => window.removeEventListener("resize", setSlider))

  useEffect(() => {
    setSlider()
    window.addEventListener("resize", setSlider)
  }, [setSlider])
  useEffect(() => {
    if (selectedItem.length) {
      setListName(selectedItem[0].name)
    }
  }, [selectedItem])

  return {
    handleClick,
    handleFavoriteClick,
    settings,
    topMenuItems,
    selectedItem,
    _slider,
    listName
  }
}
