import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { AppStateType } from "../../redux/store"
import { getUsersAndTasks } from "../../redux/reducers/tasksReducer"
import { useSliderSettings } from "../../helpers/useSliderSettings"

// eslint-disable-next-line no-shadow
enum TABS {
  MIGRATION = "migration",
  EMPTYTAG = "emptytag",
  EMPTYPLAN = "emptyplan",
  EMPTYDEADLINE = "emptydeadline",
  AUTOCLOSE = "autoclose",
  SUPPORT = "support",
  FACT = "fact"
}

export const useTasksPage = () => {
  const { tab } = useParams<{ tab: TABS }>()
  const isSupportPage = tab === "support"
  const isFactPage = tab === "fact"
  const settings = useSliderSettings()
  // @ts-ignore
  const { users, tasks, projects, ...support } = useSelector((state: AppStateType) => state.tasks[tab])
  const [load, setLoad] = useState(true)
  const dispatch = useDispatch()
  const fetchData = async () => {
    try {
      await setLoad(true)
      !isSupportPage && !isFactPage && (await dispatch(getUsersAndTasks(tab)))
    } finally {
      await setLoad(false)
    }
  }
  return { fetchData, support, isSupportPage, load, users, tasks, projects, tab, settings, isFactPage }
}
