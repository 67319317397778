import React, { useEffect } from "react"
//import { useParams } from 'react-router-dom'
// import { B24Container } from '../../components/B24Container/B24Container'
import { useSupport } from "../../hooks/Support/useSupport"
import SupportCalendar from "./SupportCalendar"
import FallBack from "../../components/other/FallBack"
import { useTitle } from "../../hooks/useTitle"

export const SupportPage = () => {
  // const { tab } = useParams<{ tab: 'support' }>()
  const { fetchData, load } = useSupport()
  const { setTitle } = useTitle()
  setTitle("Поддержка клиентов")
  useEffect(() => {
    fetchData()
  }, [])

  if (load) return <FallBack />
  return <SupportCalendar />
}
