import React, { useEffect, useState } from "react"
import Pagination from "../../components/other/Pagination"
import AccessesForm from "./components/AccessesForm"
import AccessesItem from "./components/AccessesItem"
import { useTitle } from "../../hooks/useTitle"
import { useAccessesData } from "../../hooks/Accesses/useAccessesData"
import { useAccesses } from "../../hooks/Accesses/useAccesses"
import FallBack from "../../components/other/FallBack"
import { AccessesHeader } from "./components/AccessesHeader"
import Checkbox from "../../components/other/Checkbox"
import classNames from "classnames"
import { useWindowWidth } from "../../hooks/Accesses/useWindowWidth"
import { getAccessesItemsThunk } from "../../redux/reducers/accessesReducer"
import { useDispatch } from "react-redux"
import { getItem } from "../../helpers/localStorageBool"

const Accesses = () => {
  const { handleChange, handleClick, totalPages, currentPage, searchValue, items, isEmpty } = useAccessesData()
  const { fetchData, load } = useAccesses()
  const { setTitle } = useTitle()
  const { width } = useWindowWidth()
  const dispatch = useDispatch()
  const [showCheckbox, setShowCheckbox] = useState(width >= 768)
  const myProject = getItem("myProjects")
  const access = getItem("accessesContragents")
  setTitle("Доступы krit.studio")
  const renderAccessItems = () => {
    if (items) {
      return items.map((el, index) => {
        const editLink = `https://krit.studio/bitrix/admin/iblock_element_edit.php?IBLOCK_ID=75&type=crm&ID=${el.id}`
        return <AccessesItem item={el} key={index} editLink={editLink} />
      })
    }
    return false
  }
  useEffect(() => {
    const page = new URL(String(window.location)).searchParams.get("page") ?? 1
    fetchData(myProject, access, Number(page))
  }, [])
  const renderItems = () => {
    return (
      <div className="accesses__list">
        <div className="accesses__listWrap">
          <AccessesHeader />
          {!!items.length && renderAccessItems()}
          {isEmpty && <p className={classNames("empty", "accessEmpty")}>Ничего не найдено</p>}
        </div>
      </div>
    )
  }
  const getAccessWithParams = async (myProjectParam?: boolean, accessesContragents?: boolean) => {
    dispatch(getAccessesItemsThunk(myProjectParam, accessesContragents))
  }
  const renderPagination = () => {
    if (totalPages > 1) {
      return (
        <Pagination
          items={totalPages}
          event={(number: number, e: React.MouseEvent) => handleClick(number, e)}
          currentPage={currentPage}
        />
      )
    }
    return false
  }
  if (load) {
    return <FallBack />
  }
  return (
    <div className="wrapper">
      <div className="accesses">
        <h2 className="title accesses__title">Менеджер доступов</h2>
        <div className="accesses__container">
          <div className="accesses__header">
            <div style={{ display: "flex" }}>
              <AccessesForm
                searchValue={searchValue}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
              />
              <div
                className={classNames({
                  "accesses-showCheckbox": true,
                  "-open": showCheckbox
                })}
                onClick={() => setShowCheckbox(!showCheckbox)}
              >
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.38625 1.40875V3.54029C4.64735 3.83984 5.54167 5.0055 5.54167 6.34966C5.54167 7.69383 4.64735 8.85948 3.38625 9.15904V19.3606C3.38625 19.7138 3.11072 20.0001 2.77083 20.0001C2.43095 20.0001 2.15541 19.7138 2.15541 19.3606V9.15904C0.894315 8.85948 0 7.69383 0 6.34966C0 5.0055 0.894315 3.83984 2.15541 3.54029V1.40875C2.15541 1.05558 2.43095 0.769287 2.77083 0.769287C3.11072 0.769287 3.38625 1.05558 3.38625 1.40875ZM1.33977 6.96901C1.58113 7.56769 2.14615 7.95604 2.77083 7.95258C3.61868 7.94558 4.30306 7.23065 4.30528 6.34966C4.30527 5.70057 3.92852 5.11555 3.35107 4.86796C2.77361 4.62037 2.10945 4.75908 1.6689 5.21928C1.22836 5.67948 1.09841 6.37032 1.33977 6.96901Z"
                    fill="#3262E0"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5113 1.40875V11.6103C11.7724 11.9099 12.6667 13.0755 12.6667 14.4197C12.6667 15.7638 11.7724 16.9295 10.5113 17.2291V19.3606C10.5113 19.5891 10.394 19.8002 10.2035 19.9144C10.0131 20.0286 9.77854 20.0286 9.58812 19.9144C9.39771 19.8002 9.28041 19.5891 9.28041 19.3606V17.2291C8.01932 16.9295 7.125 15.7638 7.125 14.4197C7.125 13.0755 8.01932 11.9099 9.28041 11.6103V1.40875C9.28041 1.05558 9.55595 0.769287 9.89583 0.769287C10.2357 0.769287 10.5113 1.05558 10.5113 1.40875ZM8.4706 15.0374C8.70938 15.6363 9.27189 16.0269 9.89583 16.0269C10.7469 16.0245 11.4362 15.3082 11.4385 14.4239C11.4385 13.7756 11.0626 13.1911 10.4862 12.943C9.90973 12.6949 9.24621 12.8321 8.80501 13.2905C8.36381 13.7489 8.23183 14.4384 8.4706 15.0374Z"
                    fill="#3262E0"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.8429 6.45324C18.1035 6.75317 18.9981 7.9185 18.9999 9.26323C19.0058 10.6143 18.1095 11.7889 16.8429 12.0903V19.3605C16.8429 19.7137 16.5675 20.0001 16.2277 20.0001C15.888 20.0001 15.6126 19.7137 15.6126 19.3605V12.0732C14.3521 11.7736 13.4583 10.6077 13.4583 9.26323C13.4583 7.91877 14.3521 6.75286 15.6126 6.45324V1.40889C15.6126 1.05565 15.888 0.769287 16.2277 0.769287C16.5675 0.769287 16.8429 1.05565 16.8429 1.40889V6.45324ZM14.6858 9.26323C14.6858 10.1487 15.3762 10.8665 16.2277 10.8665C17.0784 10.8642 17.7674 10.1477 17.7697 9.26323C17.7697 8.37777 17.0793 7.65996 16.2277 7.65996C15.3762 7.65996 14.6858 8.37777 14.6858 9.26323Z"
                    fill="#3262E0"
                  />
                </svg>
              </div>
            </div>
            {showCheckbox && (
              <div className="accesses__checkboxGroup">
                <Checkbox
                  label={"Проекты с доступами"}
                  name={"accessesContragents"}
                  event={(val) => getAccessWithParams(myProject, val)}
                  defaultValue={access}
                />
                <Checkbox
                  label={"Мои проекты"}
                  name={"myProjects"}
                  event={(val) => getAccessWithParams(val, access)}
                  defaultValue={myProject}
                />
              </div>
            )}
          </div>

          {renderItems()}
          {renderPagination()}
        </div>
      </div>
    </div>
  )
}

export default Accesses
